import useSWR, { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'
import { notification } from 'antd'
import type { AxiosError } from 'axios'

import { api } from 'utils/axios'

import { getErrorMsg } from 'utils/geterror'
import { IDefaultRule } from '../types/default-rules.types'

export function useDefaultRules(entity?: number | null) {
  const { mutate: revalidate } = useSWRConfig()

  const url = entity ? `default-rules?entity=${entity}` : 'default-rules'

  const { data, error, isLoading, isValidating, mutate } = useSWR<IDefaultRule[]>(url)
  const createMutation = useSWRMutation(url, createFetcher)
  const deleteMutation = useSWRMutation(url, deleteFetcher)
  const updateMutation = useSWRMutation(url, updateFetcher)

  const create = (data: Partial<IDefaultRule>) => {
    return createMutation.trigger(data, {
      onSuccess: () => revalidate(`default-rules?entity=${entity}`),
    })
  }

  const update = (data: Partial<IDefaultRule>) => {
    return updateMutation.trigger(data, {
      onSuccess: () => revalidate(`default-rules?entity=${entity}`),
    })
  }

  const remove = (id: number) => {
    return deleteMutation.trigger(
      { id },
      {
        onSuccess: () => revalidate(`default-rules`),
      },
    )
  }

  return {
    create,
    update,
    remove,
    data: data?.sort((a, b) => a.position - b.position),
    isLoading: isLoading || isValidating || createMutation.isMutating || deleteMutation.isMutating,
    error: error || createMutation.error || deleteMutation.error,
    revalidate: () => mutate(data),
    refetch: () => mutate(data),
  }
}

interface IUpdateData {
  arg: Partial<IDefaultRule>
}

const updateFetcher = (url: string, { arg }: IUpdateData) => {
  return api
    .put(url + '/' + arg.id, arg)
    .then((res) => res.data)
    .catch((err: AxiosError<any>) => notification.error(getErrorMsg(err)))
}

const deleteFetcher = (url: string, { arg }: IUpdateData) => {
  console.log('arg', arg.id) // Log the id to confirm it's being passed
  return api
    .delete(`${url}/${arg.id}`) // Append the id to the URL
    .then((res) => res.data)
    .catch((err: AxiosError<any>) => notification.error(getErrorMsg(err)))
}

const createFetcher = (url: string, { arg }: IUpdateData) => {
  console.log('arg', arg)
  return api
    .post(url, arg)

    .then((res) => res.data)
    .catch((err: AxiosError<any>) => notification.error(getErrorMsg(err)))
}
