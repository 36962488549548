import React, { useEffect, useState } from 'react'
import { Button, Typography, Space } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { Progress } from './components/Progress'

const { Title, Text, Link } = Typography

export function GrantAccess() {
  const [accessGranted, setAccessGranted] = useState(() => {
    const saved = localStorage.getItem('accessGranted')
    return saved === 'true'
  })
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()

  const handleAccessGranted = () => {
    // Toggle between granted and not granted
    const newValue = !accessGranted
    setAccessGranted(newValue)
    localStorage.setItem('accessGranted', newValue.toString())
  }

  const handleNext = () => {
    if (accessGranted) {
      navigate(`/company/${params.id}/services/document-access`)
    }
  }

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('accessGranted')
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', padding: '32px', marginBottom: '32px' }}>
      <Title level={2}>QuickBooks Access</Title>

      <div style={{ marginBottom: '64px' }}>
        <Progress currentStep={2} />
      </div>

      <div style={{ marginBottom: '32px' }}>
        <Title level={3}>Grant QuickBooks Online Access</Title>
        <Text>
          Please add <Link href="mailto:hello@equilityhq.com">hello@equilityhq.com</Link> as a firm teammate within
          QuickBooks Online or as a company user. Instructional videos for both options can be accessed below:
        </Text>
      </div>

      <div style={{ marginBottom: '32px' }}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Button
            onClick={() => window.open('https://www.loom.com/share/08380b6f372a4bdda28c9d318bc21eb3', '_blank')}
            style={{
              width: '200px',
              borderRadius: '8px',
              border: '2px solid #d9d9d9',
            }}
          >
            Firm Teammate
          </Button>
          <Button
            onClick={() => window.open('https://www.loom.com/share/eca9f9ad760a498a8d127a852a132957', '_blank')}
            style={{
              width: '200px',
              borderRadius: '8px',
              border: '2px solid #d9d9d9',
            }}
          >
            Company User
          </Button>
        </Space>
      </div>

      <div style={{ marginBottom: '32px' }}>
        <Text style={{ display: 'block', marginBottom: '16px' }}>
          After you've added us to QuickBooks Online, simply click the button below to confirm that this step has been
          completed:
        </Text>
        <Button
          onClick={handleAccessGranted}
          style={{
            width: '200px',
            marginBottom: '32px',
            borderRadius: '8px',
            backgroundColor: accessGranted ? '#0F9648' : '#f5f5f5',
            color: accessGranted ? 'white' : 'rgba(0, 0, 0, 0.85)',
            border: 'none',
          }}
        >
          Access Granted
        </Button>
        <Text style={{ display: 'block' }}>Then click 'Next':</Text>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
        <Button onClick={() => navigate(-1)} size="large" style={{ padding: '0 32px', borderRadius: '8px' }}>
          Back
        </Button>
        <Button
          onClick={handleNext}
          disabled={!accessGranted}
          type="primary"
          size="large"
          style={{ padding: '0 32px', borderRadius: '8px' }}
        >
          Next
        </Button>
      </div>
    </div>
  )
}