import React from 'react'
import { Flex, Typography } from 'antd'
import { useCompany } from 'hooks/useCompany'
import { toCurrency } from 'utils/numbers'

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    name: string;
    color: string;
    payload: {
      meta?: {
        [key: string]: string;
      };
    };
  }>;
  currency?: string;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  const { company } = useCompany()
  const currency =  company?.settings?.currency || ''
  const getName = (data: any, key: string) => {
    const meta = data?.payload?.meta || {};
    const type = meta[key];
    return key + (type ? ` (${type})` : '');
  };

  if (!active || !payload || !payload.length) return null;

  return (
    <Flex vertical align="start" style={{
      backgroundColor: '#fff',
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '4px',
    }}>
      {payload.map((entry) => (
        <Typography.Text key={entry.name} style={{ color: entry.color }}>
          {`${getName(entry, entry.name)}: ${toCurrency(entry.value, currency)}`}
        </Typography.Text>
      ))}
    </Flex>
  );
};
