import React, { useEffect, useState } from 'react'
import {Button, Divider, Flex, Spin, Typography, App} from 'antd'
import { PlaidEmbeddedLink } from 'react-plaid-link'
 
import { api } from 'utils/axios'
import { useNewCompany } from '.'
import { usePlaidLinkToken } from 'hooks/usePlaid'
import { getErrorMsg } from 'utils/geterror'
import { ConnectionType } from 'types/company.types'
import { FullLoader } from 'components/FullLoader'
import {BankFeedResponse} from "../../types/bank-feed.types";
import {AccountMatching} from "../../components/AccountMatching";
import {PlaidConnectionResponse} from "../../types/plaid.type";

const { Text } = Typography

interface IProps {
  onNext: () => void
  onBack: () => void
}

export function BanksStep(props: IProps) {
    const {notification } = App.useApp();
  const { token: tokenData } = usePlaidLinkToken()


    const token = tokenData?.linkToken

  const company = useNewCompany(s => s.company)
  const setCompany = useNewCompany(s => s.setCompany)

  const [loading, setLoading] = useState(false)
    const [connections, setConnections] = useState<PlaidConnectionResponse[]>([])


    useEffect(() => {
        const plaidItems = (company?.connections || [])
            .filter((i) => i.type.id === ConnectionType.Plaid) as PlaidConnectionResponse[]
        setConnections(plaidItems)
    }, [company?.connections])

    const onConnect = async (token: string) => {
        if (!company?.id) return;
        setLoading(true);

        try {
            // First register the token with plaid and get bank details
            const bankFeedResponse = await api.post<BankFeedResponse>('connection/bankfeed/token', {
                companyId: company.id,
                publicToken: token,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            });

            // Then create the connection with the bankfeed response data
            await api.post(`companies/${company.id}/connection/`, {
                token,
                provider: 'Plaid',
                realmId: bankFeedResponse.data.id,
            });

            notification.success({ message: 'Connected to Plaid' });
            const companyResponse = await api.get(`companies/${company.id}`);
            setCompany(companyResponse.data);
        } catch (err) {
            notification.error(getErrorMsg(err));
        } finally {
            setLoading(false);
        }
    }

    const onDelete = (connection: PlaidConnectionResponse) => {
        if (!company?.id) return
        setLoading(true)
        api.delete(`companies/${company.id}/connection/${connection.keyId}`)
            .then(() => {
                notification.warning({ message: 'Deleted' })
                return api.get(`companies/${company?.id}`)
                    .then(r => setCompany(r.data))
            })
            .catch(err => notification.error(getErrorMsg(err)))
            .finally(() => setLoading(false))
    }


    const handleComplete = () => {
        props.onNext()
    }


    if (!token) return <FullLoader />


  return (
    <Flex vertical>
      <Divider orientation="left">Connect your banks and cards</Divider>
      <PlaidEmbeddedLink token={token} style={plaidStyle} onSuccess={onConnect} />
      <Text><small>* Please remember that we cannot and will not make any modifications to your data. Our integrations are needed to pull financial data and our access can be revoked at any time.</small>​</Text>
      {!!connections.length && <Divider orientation="left">Configuration</Divider>}
      <Spin spinning={loading}>
          <AccountMatching
              connections={connections}
              onDelete={onDelete}
              loading={loading}
              companyId={company?.id}
              isSetup={true}
              onComplete={handleComplete}
          />
      </Spin>
      <Divider />
      <Flex gap={10}>
        <Button onClick={props.onBack}>Back</Button>
        {!connections.length ? (
          <Button type="primary" onClick={handleComplete}>Skip</Button>
        ) : (
          <Button type="primary" onClick={handleComplete}>Next</Button>
        )}
      </Flex>
    </Flex>
  )
}

const plaidStyle: React.CSSProperties = {
  height: 350
}
