import React from 'react'
import { Flex, Radio, Typography, Tooltip, Spin } from 'antd'
import { usePlaidBankFeeds } from 'hooks/usePlaid'
import { useCompany } from 'hooks/useCompany'
import { StatusTag } from '../../../../utils/bank-feed-states'

const { Text } = Typography

interface Props {
  onSelect: (id: string) => void
  isDateNotSelected: boolean
}

const DATE_SELECTION_MESSAGE = 'Please select the date range, General Ledger account, and corresponding balances before proceeding.'

export function PlaidList(props: Props) {
  const { onSelect, isDateNotSelected } = props
  const { company } = useCompany()
  const { bankFeeds, isLoading } = usePlaidBankFeeds(company?.id)

  const handleAccountSelect = (accountId: string) => {
    onSelect(accountId)
  }

  if (isLoading) {
    return (
      <Flex justify="center" align="center" style={{ padding: '20px' }}>
        <Spin />
      </Flex>
    )
  }

  if (!bankFeeds?.length) {
    return <Text type="secondary">No Plaid accounts connected</Text>
  }

  const content = (
    <Radio.Group
      style={{ width: '100%' }}
      onChange={(e) => handleAccountSelect(e.target.value)}
      disabled={isDateNotSelected}
    >
      <Flex vertical gap={10} style={listStyle}>
        {bankFeeds.map((feed) => {
          const isDisabled = feed.state !== 1 // Disable if not BANK_FEED_STATE_GOOD

          return (
            <Flex vertical gap={10} key={feed.id}>
              <Flex align="center" gap={8}>
                <Text strong>{feed.name}</Text>
                <StatusTag state={feed.state} />
              </Flex>
              {feed.accounts.map((account) => (
                <Radio value={account.id} key={account.id} disabled={isDisabled || isDateNotSelected}>
                  <Flex vertical>
                    <Text>{account.name}</Text>
                    <Text type="secondary" style={{ fontSize: '12px' }}>
                      ****{account.number.slice(-4)}
                    </Text>
                    <Text type="secondary" style={{ fontSize: '12px' }}>
                      Balance: ${(account.balance / 100).toFixed(2)}
                    </Text>
                  </Flex>
                </Radio>
              ))}
            </Flex>
          )
        })}
      </Flex>
    </Radio.Group>
  )

  return isDateNotSelected ? (
    <Tooltip title={DATE_SELECTION_MESSAGE}>
      <div>{content}</div>
    </Tooltip>
  ) : (
    content
  )
}

const listStyle: React.CSSProperties = {
  maxHeight: 240,
  overflowY: 'auto',
  padding: '4px',
}
