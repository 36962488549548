import { useEffect, useState } from 'react'
import { Button, Flex, Modal, Segmented, Table, Typography } from 'antd'
import { ColorNumber } from 'components/ColorNumber'
import { formatDate, sortByDate } from 'utils/dates'
import type { ITx } from 'types/tx.types'
import type { IMatcher } from 'types/matcher'

const { Title } = Typography;

interface Props {
  list: ITx[]
  type: 'bank' | 'qbo'
  matcher: IMatcher
  open: boolean
  onClose: () => void
}

export function MatchModal(props: Props) {
  const { open, onClose, list, type, matcher } = props
  const [filter, setFilter] = useState<Filter>('Suggested Matches')
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])

  useEffect(() => {
    setSelectedKeys([])
  }, [open, filter])

  if (!open) return null

  const viewOnly = false
  const matchList = type === 'bank' ? matcher.qbo : matcher.bank
  const isMatched = !!list.filter((v) => v.matchedTo.length || v.forceMatch).length
  const okBtnTitle = isMatched ? 'Unmatch' : 'Match'
  const okBtnType = isMatched ? { danger: true } : ({ type: 'primary' } as any)

  // Process source transactions with unique keys
  const sourceData = list.map((tx, index) => ({
    ...tx,
    _uniqueKey: `source-${tx._id}-${index}`,
  }))

  const unmatchedList: ITx[] = list
      .flatMap((v) => v.potentialMatches || [])
      .filter((v) => {
        if (!v) return false
        const tx = matcher.qbo.find((q) => q._id === v._id) || matcher.bank.find((b) => b._id === v._id)
        if (!tx) return false
        if (tx.forceMatch) return false
        return !tx.matchedTo.length
      })

  const match = isMatched
      ? list.flatMap((v) => v.matchedTo)
      : filter === 'All Unmatched Transactions'
          ? matchList.filter((v) => !v.matchedTo.length)
          : unmatchedList

  // Process match transactions with unique keys
  const matchData = match.map((tx, index) => ({
    ...tx,
    _uniqueKey: `match-${tx._id}-${index}`,
  }))

  // Get selected transactions using the matchData mapping
  const selectedTxs = matchData
      .filter(tx => selectedKeys.includes(tx._uniqueKey))
      .map(tx => ({ ...tx, _id: tx._id })) // Ensure we pass the correct _id

  const listResult = list.reduce((a, v) => a + v.amount, 0)
  const overalAmount = selectedTxs.reduce((sum, v) => v.amount + sum, 0)
  const enabled = isMatched || roundTo(overalAmount) === roundTo(listResult)

  const handleSubmit = () => {
    if (isMatched) {
      list.forEach((v) => matcher.unmatch(v))
    } else {
      matcher.match(list, selectedTxs)
    }
    onClose()
  }

  // Determine the source and target labels based on the type
  const sourceLabel = type === 'bank' ? 'Source Data Transaction(s)' : 'QuickBooks Online Transaction(s)'
  const targetLabel = type === 'bank' ? 'QuickBooks Online Transaction(s)' : 'Source Data Transaction(s)'

  return (
      <Modal
          width="100%"
          title={<Title level={3} style={{ margin: 0, fontWeight: 600, paddingTop: '8px', paddingBottom: '8px' }}>Match Transactions</Title>}
          open={open}
          onCancel={onClose}
          bodyStyle={{
            padding: '0 24px 24px 24px',
            backgroundColor: '#f8f9fa'
          }}
          style={{
            boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)'
          }}
          footer={
            viewOnly
                ? null
                : [
                  <Button key="cancel" onClick={onClose} size="large">
                    Cancel
                  </Button>,
                  <Button
                      key="ok"
                      onClick={handleSubmit}
                      {...okBtnType}
                      disabled={!enabled}
                      size="large"
                  >
                    {okBtnTitle}
                  </Button>,
                ]
          }
      >
        <Flex vertical gap={10}>
          <Title level={4} style={{
            marginBottom: '16px',
            marginTop: '16px',
            padding: '8px 16px',
            borderLeft: '4px solid #52c41a',
            borderRadius: '4px'
          }}>{sourceLabel}</Title>
          <Table
              size="small"
              className="Table TxTable"
              rowKey="_uniqueKey"
              dataSource={sourceData}
              columns={columns}
              pagination={false}
              scroll={{ y: 400 }}
          />

          <Flex align="center" justify="space-between" style={{ marginTop: '32px', marginBottom: '16px' }}>
            <Title level={4} style={{
              margin: 0,
              padding: '8px 16px',
              borderLeft: '4px solid #52c41a',
              borderRadius: '4px'
            }}>{targetLabel}</Title>

            {!isMatched && (
                <Segmented
                    size="large"
                    options={[
                      { label: 'Suggested Matches', value: 'Suggested Matches' },
                      { label: 'All Unmatched Transactions', value: 'All Unmatched Transactions' }
                    ]}
                    value={filter}
                    onChange={(v) => setFilter(v as Filter)}
                    style={{
                      backgroundColor: '#fff',
                      padding: '4px',
                      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                      borderRadius: '4px',
                      fontWeight: 'bold'
                    }}
                />
            )}
          </Flex>

          <Table
              virtual
              size="small"
              className="Table"
              rowKey="_uniqueKey"
              dataSource={matchData}
              columns={columns}
              pagination={false}
              scroll={{ y: 400 }}
              rowSelection={
                !isMatched
                    ? {
                      columnWidth: 50,
                      hideSelectAll: false,
                      selectedRowKeys: selectedKeys,
                      onChange: (selectedRowKeys) => {
                        setSelectedKeys(selectedRowKeys as string[])
                      },
                    }
                    : undefined
              }
          />
        </Flex>
      </Modal>
  )
}

type Filter = 'Suggested Matches' | 'All Unmatched Transactions'

const columns = [
  {
    title: 'Date',
    key: 'date',
    dataIndex: 'date',
    width: 140,
    render: (value: string) => formatDate(value, 'MM-DD-YYYY'),
    sorter: (a: ITx, b: ITx) => sortByDate(a.date, b.date),
  },
  {
    title: 'Account',
    key: 'account',
    dataIndex: 'account',
    ellipsis: true,
    width: 200,
    sorter: (a: ITx, b: ITx) => (a?.account || '').localeCompare(b?.account || ''),
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    width: 200,
    ellipsis: true,
    sorter: (a: ITx, b: ITx) => (a?.name || '').localeCompare(b?.name || ''),
  },
  {
    title: 'Memo',
    key: 'memo',
    dataIndex: 'memo',
    width: 342,
    ellipsis: true,
    sorter: (a: ITx, b: ITx) => (a?.memo || '').localeCompare(b?.memo || ''),
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
    width: 140,
    sorter: (a: ITx, b: ITx) => (a?.type || '').localeCompare(b?.type || ''),
  },
  {
    title: 'Num',
    key: 'num',
    dataIndex: 'num',
    width: 140,
    sorter: (a: ITx, b: ITx) => a?.type?.localeCompare(b?.type || '') || 0,
  },
  {
    title: 'Amount',
    key: 'amount',
    dataIndex: 'amount',
    width: 140,
    sorter: (a: ITx, b: ITx) => a.amount - b.amount,
    render: (v: number) => <ColorNumber amount={v} />,
  },
]

function roundTo(amount: number) {
  return parseFloat(amount.toFixed(2))
}