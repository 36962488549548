import useSWR from 'swr'

export function useCompanyMembers(companyId?: number, onSuccess?: () => void) {
  // Ensure the key is null when companyId is undefined/null/falsy
  const key = companyId ? `companies/${companyId}/members` : null

  // Pass the onSuccess callback in the SWR options
  const { data, error, isLoading } = useSWR(key, {
    onSuccess: (data) => {
      // Only call onSuccess if we have data and companyId exists
      if (data && companyId && onSuccess) {
        onSuccess();
      }
    },
    // Add a revalidation interval to refresh data periodically
    revalidateOnFocus: false,
    dedupingInterval: 10000,
  })

  return {
    companyMembers: data,
    isLoading,
    error
  }
}