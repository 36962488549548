import { useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {App, Checkbox} from 'antd'
import { Button, Card, Col, Divider, Form, Input, Row, Typography } from 'antd'

import { AuthPage } from './components/AuthPage'
import { AuthWelcome } from './components/Welcome'
import { AppFooter } from 'app/AppFooter'

import { rules } from 'utils/form'
import { useAuthUser } from 'hooks/useAuth'
import { useAuthStore } from 'store/auth.store'
import { getErrorMsg } from 'utils/geterror'
import { trackEvents } from 'utils/analytics'
import { signUpWithPassword, logout } from 'utils/firebase'

const { Text } = Typography

export function AppSumo() {
    const {notification } = App.useApp();
  const { authUser } = useAuthUser()

  const isAuth = useAuthStore((state) => !!state.uid)
  const [loading, setLoading] = useState(false)

  const [searchParams] = useSearchParams()
  const invitationTokenParam = searchParams.get('token')
  const emailParam = searchParams.get('email') || ''

  const signUp = async (form: any) => {
    setLoading(true)
    try {
      const { user } = await signUpWithPassword(form.email, form.password)
      if (!user?.email) throw new Error('Email is required')
      trackEvents.user.signUp()
      const body = {
        email: user.email,
        fullName: form.fullName as string,
        token: await user.getIdToken(),
        googleSignIn: false,
        invitationToken: invitationTokenParam,
        code: form.code,
      }
      await authUser(body)
      notification.success({
        message: 'Welcome to Equility!',
        description: `You have successfully signed up with ${user.email}`,
      })
    } catch (error) {
      notification.error(getErrorMsg(error))
      await logout()
    } finally {
      setTimeout(() => setLoading(false), 1000)
    }
  }

  if (isAuth && !loading) return <Navigate to="/" />

  return (
    <AuthPage>
      <Helmet>
        <title>Equility - Hey Sumo-lings!</title>
      </Helmet>
      <Row style={contentStyle}>
        <Col span={12}>
          <AuthWelcome />
        </Col>
        <Col span={12}>
          <Card title="Hey Sumo-lings!">
            <Text>Please share your Name, AppSumo email and AppSumo code to get enrolled in Equility.</Text>
            <Divider />
            <Form onFinish={signUp} labelCol={{ span: 8 }} initialValues={{ email: emailParam }}>
              <Form.Item name="fullName" rules={rules.fullName}>
                <Input placeholder="Full Name" autoComplete="off" />
              </Form.Item>
              <Form.Item name="email" rules={rules.email}>
                <Input placeholder="AppSumo Email" />
              </Form.Item>
              <Form.Item name="password" rules={rules.password}>
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item name="code" rules={rules.appsumo}>
                <Input placeholder="AppSumo Code" autoComplete="off" />
              </Form.Item>
              <Form.Item name="terms" valuePropName="checked" rules={rules.terms}>
                <Typography.Text type="secondary">
                  <Checkbox>
                    <span>I agree to the</span>
                    &nbsp;
                    <a href="https://www.equilityhq.com/privacy-policy" target="_blank" rel="noreferrer">
                      Privacy Policy
                    </a>
                    &nbsp;
                    <span>and</span>
                    &nbsp;
                    <a href="https://www.equilityhq.com/terms-of-use" target="_blank" rel="noreferrer">
                      Terms of Use
                    </a>
                  </Checkbox>
                </Typography.Text>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block loading={loading}>
                  Enroll now
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={24}>
          <AppFooter />
        </Col>
      </Row>
    </AuthPage>
  )
}

const contentStyle = {
  width: 800,
}
