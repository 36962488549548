import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { Button, Col, DatePicker, Dropdown, Flex, Form, Input, Modal, Row, Select, Upload, message } from 'antd'
import { EllipsisOutlined, UploadOutlined } from '@ant-design/icons'
import { useForm, useWatch } from 'antd/lib/form/Form'
import { ProjectStatus } from 'types/project.types'
import { PROJECT_STATUS_OPTIONS } from 'constants/options'
import { useProjectList } from 'hooks/useProjects'
import { useCompanyMembers } from 'hooks/useCompanyMembers'
import { useTask } from 'hooks/useTask'
import { ITask } from 'types/task.types'
import { useFileManager } from 'hooks/useFileManager'
import { getFileNameFromUrlWithQuery } from 'utils/form'
import { TaskComments } from 'pages/workfow/components/TaskComments'
import DocumentEditor from './DocumentEditor'

interface Props {
  open: boolean
  onClose: () => void
  initialValues?: ITask
  onSuccess?: () => void
  projectId?: string
}

export function AddTaskModal(props: Props) {
  const [form] = useForm()
  const { open, onClose, initialValues, onSuccess, projectId } = props
  const isEditMode = !!initialValues
  const defaultValues = useMemo(() => ({ status: ProjectStatus.New }), [])
  const [defaultFileList, setDefaultFileList] = useState<any[]>([])
  const [defaultHiddenFileId, setDefaultHiddenFileId] = useState<any>(null)
  const showProjectField = !projectId && !isEditMode

  // Add state to directly control the assignee field
  const [currentAssigneeId, setCurrentAssigneeId] = useState<number | null>(null)
  const [currentTaskId, setCurrentTaskId] = useState<number | null>(null)

  // Track when we've switched tasks to force re-initialization
  useEffect(() => {
    if (initialValues?.id !== currentTaskId) {
      setCurrentTaskId(initialValues?.id || null);

      // When task changes, update the assignee ID directly from the task data
      if (initialValues?.user?.id) {
        setCurrentAssigneeId(initialValues.user.id);
        console.log('Task changed, setting assignee ID to:', initialValues.user.id);
      } else {
        setCurrentAssigneeId(null);
      }
    }
  }, [initialValues?.id, initialValues?.user?.id]);

  // Use the project from the form or from the selected task
  const projectField = useWatch('project', form)

  const { projectList, isLoading: projectListLoading } = useProjectList({ pagination: { current: 1, pageSize: 1000 } })
  const { create, createLoading, update, updateLoading, remove } = useTask()
  const { uploadSingle, uploadSingleLoading, deleteSingle } = useFileManager()

  // Get the company ID from the selected project
  const selectedProject = useMemo(() => {
    // First priority: If we have a task selected, use its project
    if (isEditMode && initialValues?.project?.id) {
      return projectList?.find(project => project.id === initialValues.project.id);
    }
    // Second priority: Use the project selected in the form
    else if (projectField && projectList) {
      return projectList.find(project => project.id === projectField);
    }
    // Third priority: Use the projectId prop
    else if (projectId && projectList) {
      return projectList.find(project => String(project.id) === projectId);
    }
    return undefined;
  }, [projectList, projectField, projectId, initialValues?.project?.id, isEditMode]);

  const companyId = selectedProject?.company?.id || initialValues?.company?.id;

  const onCompanyMembersSuccess = () => {
    console.log('Company members loaded for company:', companyId);

    // After company members load, ensure we have the correct assignee set
    if (currentAssigneeId) {
      console.log('Setting user field after company members loaded:', currentAssigneeId);
      // This updates the form value but doesn't affect our controlled state
      form.setFieldValue('user', currentAssigneeId);
    }
  }

  const { companyMembers, isLoading: companyMembersLoading } = useCompanyMembers(companyId, onCompanyMembersSuccess)

  const projectOptions = useMemo(
      () => projectList?.map((project) => ({ label: project.name, value: project.id })),
      [projectList],
  )

  const asigneeOptions = useMemo(
      () => companyMembers?.map((member: any) => ({ label: `${member.firstName} ${member.lastName}`, value: member.id })),
      [companyMembers],
  )

  // handle dropdown selection change
  const handleAssigneeChange = (value: number) => {
    console.log('Assignee changed to:', value);
    setCurrentAssigneeId(value);
    form.setFieldValue('user', value);
  };

  const menuProps = {
    items: [
      {
        label: 'Delete',
        key: '1',
        danger: true,
        onClick: () => {
          const onRemoveSuccess = () => {
            onModalClose()
            onSuccess?.()
          }
          remove({ id: initialValues?.id }, onRemoveSuccess)
        },
      },
    ],
  }

  // set initial values when edit mode
  useEffect(() => {
    const shouldExit = !open || !isEditMode || !initialValues

    if (shouldExit) {
      return
    }

    const initialAttachments = initialValues.files?.map((item: string, index: number) => ({
      url: item,
      uid: index,
      name: getFileNameFromUrlWithQuery(item),
      status: 'done',
      response: item,
    }))

    // Reset form completely before setting new values to avoid any stale data
    form.resetFields();

    // Set all fields EXCEPT user (we control that directly)
    form.setFieldsValue({
      title: initialValues.title,
      dueDate: initialValues.dueDate ? dayjs(initialValues.dueDate) : null,
      status: initialValues.status,
      project: initialValues.project?.id,
      company: initialValues.company?.id,
      description: initialValues.description,
      attachments: { fileList: initialAttachments },
    })

    // We set the form's user field here, but we primarily rely on our controlled state
    if (initialValues.user?.id) {
      form.setFieldValue('user', initialValues.user.id);
    }

    console.log('Initial form values set, user ID:', initialValues.user?.id);

    if (initialAttachments?.length) {
      setDefaultFileList(initialAttachments)
      setDefaultHiddenFileId(null)
    }
  }, [initialValues, open, form, isEditMode])

  // Reset user field when project changes (which changes the company)
  useEffect(() => {
    if (projectField) {
      form.setFieldValue('user', undefined)
      setCurrentAssigneeId(null);
    }
  }, [projectField, form])

  const onModalClose = (e?: any) => {
    // remove uploaded files on create mode modal close
    const triggeredByUser = !!e

    if (!isEditMode && triggeredByUser) {
      const uploads = form.getFieldValue('attachments')
      const fileList = uploads?.fileList || []
      fileList.forEach((file: any) => onCustomDelete(file))
    }

    // Only reset the form when in edit mode
    if (isEditMode) {
      form.resetFields();
    }

    setDefaultFileList([])
    setDefaultHiddenFileId(null)
    setCurrentAssigneeId(null);
    onClose()
  }

  const onProjectAdd = async (values: any) => {
    const { project: projectField, ...rest } = values
    const hiddenFileId = defaultHiddenFileId
    // Use the project from the task if in edit mode, otherwise from form or prop
    const project = isEditMode && initialValues?.project?.id
        ? initialValues.project.id
        : (projectField || projectId)

    // Ensure we use our controlled state for the user field
    const user = currentAssigneeId || values.user;

    const onActionSuccess = () => {
      onModalClose()
      onSuccess?.()
    }

    if (isEditMode) {
      // Use the current defaultFileList which has been properly updated when files are deleted
      const fileUrls = defaultFileList.map(file => file.url)
      const updatePayload = { ...rest, id: initialValues?.id, project, files: fileUrls, hiddenFileId, user }
      console.log('Updating task with user:', user);
      update(updatePayload, onActionSuccess)
    } else {
      // Extract just the URLs from the defaultFileList for create mode as well
      const fileUrls = defaultFileList.map(file => file.url)
      const createPayload = { ...rest, project, files: fileUrls, hiddenFileId, user }
      console.log('Creating task with user:', user);
      create(createPayload, onActionSuccess)
    }
  }

  const onCustomUpload = async (props: any) => {
    const res = await uploadSingle(props.file)
    props.onSuccess(res, props.file)
    setDefaultFileList([{
      uid: res.fid,
      name: props.file.name,
      status: 'done',
      url: res.url,
      response: res
    }])
    setDefaultHiddenFileId(res.fid)
  }

  const onCustomDelete = (e: any) => {
    // Extract file ID from URL or use uid as fallback
    const url = e.url
    const fid = e.uid

    deleteSingle({
      file: {
        url,
        fid
      }
    }, () => {
      // Remove the deleted file from defaultFileList
      setDefaultFileList(prevList => prevList.filter(item => item.url !== e.url))

      // If this was the hidden file, clear the hidden file ID
      if (defaultHiddenFileId === e.uid) {
        setDefaultHiddenFileId(null)
      }
    })
    return true
  }

  /** Validate Task Name and Project before allowing file upload */
  const validateBeforeUpload = async () => {
    try {
      await form.validateFields(["title", "project"]);
      return true; // Allow file upload
    } catch (error: any) {
      if (error?.errorFields && Array.isArray(error.errorFields)) {
        const errorMessages = error.errorFields
            .map((field: { errors: string[] }) => field.errors?.[0])
            .filter(Boolean);

        if (errorMessages.length > 0) {
          message.error(errorMessages.join(" "));
        }
      } else {
        message.error("Validation failed. Please check your inputs.");
      }
      return false; // Block file upload
    }
  };

  return (
      <Modal
          width={1080}
          title={
            <Flex justify="space-between">
              {isEditMode ? 'Edit Task' : 'Add Task'}
              {isEditMode && (
                  <Flex style={{ marginRight: '24px', marginBottom: '14px' }}>
                    <Dropdown menu={menuProps} trigger={['click']} placement="bottomRight">
                      <EllipsisOutlined style={{ fontSize: '16px', transform: 'rotate(90deg)' }} />
                    </Dropdown>
                  </Flex>
              )}
            </Flex>
          }
          open={open}
          onCancel={onModalClose}
          cancelText="Cancel"
          okText={isEditMode ? 'Update' : 'Create'}
          okButtonProps={{ form: 'task-form', htmlType: 'submit', disabled: uploadSingleLoading }}
          confirmLoading={createLoading || updateLoading}
      >
        <Form form={form} onFinish={onProjectAdd} layout="vertical" id="task-form" initialValues={defaultValues}>
          <Row>
            <Col span={24}>
              <Form.Item
                  label="Enter Task Name"
                  name="title"
                  rules={[{ required: true, message: 'Please enter the task name!' }]}
              >
                <Input placeholder="Enter Task Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {showProjectField ? (
                <Col span={12}>
                  <Form.Item label="Project" name="project" rules={[{ required: true, message: 'Please select project!' }]}>
                    <Select
                        loading={projectListLoading}
                        disabled={projectListLoading}
                        placeholder="Select an option"
                        options={projectOptions}
                        showSearch={true}
                        optionFilterProp="label"
                    />
                  </Form.Item>
                </Col>
            ) : null}

            <Col span={12}>
              {/* Direct control of the assignee field */}
              <Form.Item label="Assignee" name="user">
                <Select
                    value={currentAssigneeId}
                    onChange={handleAssigneeChange}
                    placeholder={companyMembersLoading ? "Loading members..." : (companyId ? "Select assignee" : "Select a project first")}
                    loading={companyMembersLoading}
                    disabled={companyMembersLoading || !asigneeOptions?.length || !companyId}
                    options={asigneeOptions}
                    notFoundContent={companyId && !companyMembersLoading && (!asigneeOptions || asigneeOptions.length === 0) ?
                        "No members found for this company" :
                        "Loading members..."}
                    showSearch
                    optionFilterProp="label"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Due Date" name="dueDate" normalize={(value) => dayjs(value)}>
                <DatePicker placeholder="Optional" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Status" name="status">
                <Select placeholder="Select an option" options={PROJECT_STATUS_OPTIONS} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Description" name="description">
                <DocumentEditor />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="" name="attachments">
                <Upload
                    key={defaultFileList.length}
                    customRequest={onCustomUpload}
                    onRemove={onCustomDelete}
                    listType="text"
                    multiple
                    fileList={defaultFileList}
                    beforeUpload={async () => {
                      const isValid = await validateBeforeUpload();
                      return isValid;
                    }}
                    accept=".pdf,.mp4,.csv,image/*"
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>{isEditMode ? <TaskComments entityId={initialValues.id} entityType="task" /> : null}</Col>
          </Row>
        </Form>
      </Modal>
  )
}