import useSWR from 'swr'

export function useAllMembers() {
  const { data: teams, error, isLoading, mutate } = useSWR('team')

  // Extract all unique members from all teams
  const data = teams?.flatMap((team: { members: any[] }) =>
      team.members?.map(member => member.user)
  ).filter(Boolean) || []

  return {
    data,
    error,
    isLoading,
    mutate
  }
}