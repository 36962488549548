import useSWR from 'swr'

import { api } from 'utils/axios'
import { periodEnd } from 'utils/dates'
import type { IReportParams, IBaseReportRow } from 'types/report.type'
import type { IBaseReportRowSection } from 'types/report.type'
import type { IBaseReport, ITableRow } from 'types/report.type'

const types = {
  balance: 'balance-sheet',
  income: 'profit-loss',
  cashflow: 'cash-flow',
  charts: 'charts',
  accounts: 'accounts',
}

export function useComparativeReport(params: IReportParams) {
  const { from, to, date_macro } = params

  const params1 = { ...params, from: from, to: periodEnd(from, date_macro) }
  const params2 = { ...params, from: to, to: periodEnd(to, date_macro) }

  const report1 = useAccountingReport(params1, undefined, true)
  const report2 = useAccountingReport(params2, undefined, true)

  const data = mergeData(report1.data?.report || [], report2.data?.report || [])
  return {
    data,
    isLoading: report1.isLoading || report2.isLoading,
    error: report1.error || report2.error,
  }
}

export function useAccountingReport<T = IBaseReport>(params: IReportParams, baseUrl?: string, enabled?: boolean) {
  const url = '/accounting/report/' + types[params.type]
  let key = params.companyId ? [url, params] : null
  if (!params.from || !params.to) key = null

  return useSWR<T>(key, enabled ? () => fetch(params, baseUrl) : null)
}

const fetch = (params: IReportParams, baseUrl?: string) => {
  const url = baseUrl || '/accounting/report/' + types[params.type]
  return api.get(url, { params }).then((res) => res.data)
}

const mergeData = (data1: IBaseReportRow[], data2: IBaseReportRow[]): ITableRow[] => {
  const arr: ITableRow[] = []
  let sum: ITableRow | undefined

  const set1 = new Set(data1.map((item) => getName(item)))
  const set2 = new Set(data2.map((item) => getName(item)))
  const mergedSet = new Set([...Array.from(set1), ...Array.from(set2)])

  Array.from(mergedSet).forEach((name, i) => {
    const row1 = data1.find((item) => getName(item) === name)
    const row2 = data2.find((item) => getName(item) === name)
    const row = row1 || row2
    if (!row) return

    const _pos = row?.key.split('-')
    const pos = parseInt(_pos[_pos.length - 1])
    const key = row.key + '.' + i

    if (row.type === 'Data') {
      arr.push({ ...row, key, ...getValues(row1?.value, row2?.value), pos })
    } else if (row.type === 'Summary') {
      // console.log('Summary', pos, row.name)
      if (pos) {
        arr.push({ ...row, key, ...getValues(row1?.value, row2?.value), pos })
      } else {
        sum = { ...row, key, ...getValues(row1?.value, row2?.value), pos }
      }
    } else {
      const _row1 = row1 as IBaseReportRowSection
      const _row2 = row2 as IBaseReportRowSection

      const _rows1 = _row1?.rows || []
      const rows1 = [..._rows1, getSummary(_row1)].filter(Boolean) as IBaseReportRow[]

      const _rows2 = _row2?.rows || []
      const rows2 = [..._rows2, getSummary(_row2)].filter(Boolean) as IBaseReportRow[]

      const rows = mergeData(rows1, rows2)

      arr.push({ ...row, key, type: 'Section', rows, ...getValues(row1?.value, row2?.value), pos })
    }
  })

  const i1 = arr.findIndex((v) => v.name === 'Income')
  const i2 = arr.findIndex((v) => v.name === 'Total Income')
  if (i1 > -1 && i2 > -1) {
    arr.splice(i2, 1)
  }

  const i3 = arr.findIndex((v) => v.name === 'Expenses')
  const i4 = arr.findIndex((v) => v.name === 'Total Expenses')
  if (i3 > -1 && i4 > -1) {
    arr.splice(i4, 1)
  }

  const r = arr.sort((a, b) => (a.pos || 0) - (b.pos || 0))
  if (sum) r.push(sum)

  return r
}

function getName(item: IBaseReportRow) {
  if (!item) return undefined
  return item.type === 'Data' ? item.name || item.id : item.name || item.summary?.name
}

function getValues(value1?: string, value2?: string) {
  const baseValue = parseFloat(value1 || '0')
  const comparativeValue = parseFloat(value2 || '0')
  const varianceAmount = baseValue - comparativeValue
  const variancePercentage = ((baseValue - comparativeValue) / Math.abs(comparativeValue)) * 100 || 0
  return { baseValue, comparativeValue, varianceAmount, variancePercentage }
}

function getSummary(row: IBaseReportRowSection) {
  if (!row?.summary) return undefined
  return {
    type: 'Summary',
    key: row.key + '-summary',
    name: row.summary.name,
    value: row.summary.value,
  } as IBaseReportRowSection
}
