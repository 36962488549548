import React from 'react'
import { Button, Typography, Card, Space } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { Progress } from './components/Progress'

const { Title, Text } = Typography

export function ServiceCompletionPage() {
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()

  const handleAnotherService = () => {
    localStorage.removeItem('selectedService')
    localStorage.removeItem('timeline')
    localStorage.removeItem('accessGranted')
    localStorage.removeItem('documentsFormData')
    localStorage.removeItem('selectedCompanyId')
    localStorage.removeItem('selectedCompanyName')

    navigate(`/company/${params.id}/services`)
  }

  const handleComplete = () => {
    localStorage.removeItem('selectedService')
    localStorage.removeItem('timeline')
    localStorage.removeItem('accessGranted')
    localStorage.removeItem('documentsFormData')
    localStorage.removeItem('selectedCompanyId')
    localStorage.removeItem('selectedCompanyName')

    navigate(`/company/${params.id}/dashboard`)
  }

  return (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', padding: '32px', marginBottom: '32px' }}>
      <Title level={2}>Complete Your Request</Title>

      <div style={{ marginBottom: '48px' }}>
        <Progress currentStep={5} />
      </div>

      <div style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
        <Text style={{ fontSize: '18px', display: 'block', marginBottom: '24px' }}>
          Would you like to create another service request?
        </Text>

        <Space size="middle" direction="vertical" style={{ width: '100%' }}>
          <Card
            hoverable
            style={{
              marginBottom: '16px',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            }}
            bodyStyle={{ padding: '16px' }}
            onClick={handleAnotherService}
          >
            <Space direction="vertical" style={{ width: '100%', textAlign: 'center' }}>
              <Title level={5} style={{ margin: '0 0 8px 0' }}>
                Yes, Create Another Service Request
              </Title>
              <Button type="primary" size="middle" style={{ borderRadius: '6px' }}>
                Create Another Service
              </Button>
            </Space>
          </Card>

          <Card
            hoverable
            style={{
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            }}
            bodyStyle={{ padding: '16px' }}
            onClick={handleComplete}
          >
            <Space direction="vertical" style={{ width: '100%', textAlign: 'center' }}>
              <Title level={5} style={{ margin: '0 0 8px 0' }}>
                No, I'm Done
              </Title>
              <Button type="primary" size="middle" style={{ borderRadius: '6px' }}>
                Complete
              </Button>
            </Space>
          </Card>
        </Space>

        <div
          style={{
            marginTop: '64px',
            paddingTop: '24px',
            paddingBottom: '16px',
            borderTop: '1px solid #f0f0f0',
          }}
        >
          <Text
            style={{
              fontSize: '16px',
              display: 'block',
            }}
          >
            That's it! We'll review your information shortly and follow-up with any questions that we might have.
          </Text>
        </div>
      </div>
    </div>
  )
}
