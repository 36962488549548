import useSWR, { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'

import { api } from 'utils/axios'
import { useCompanyStore } from 'store/company.store'
import type { IReview } from 'types/review.types'

interface IParams {
  id: number | null
}

interface IUpdateData {
  arg: Partial<IReview>
}


export function useReviewsAll() {
  const companyId = useCompanyStore((state) => state.id)
  const key = '/review?companyId=' + companyId
  const { data, isLoading, error } = useSWR<IReview[]>(key)

  return { data, isLoading, error }
}

export function useReview(params: IParams) {
  const { mutate: revalidate } = useSWRConfig()

  const companyId = useCompanyStore((state) => state.id)
  const id = params.id
  const key = id ? `/review/${id}?companyId=${companyId}` : null

  const allKey = '/review?companyId=' + companyId

  // Add SWR configuration to prevent rapid refetches
  const review = useSWR<IReview>(key,{
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 10000, // Prevent duplicate requests within 10 seconds
  })

  // Add configuration for mutations to prevent rapid retries
  const mutationConfig = {
    onError: (err: any) => {
      console.error('Review mutation error:', err)
      // You could add additional error handling here
    },
    // Limit retries on failure
    retryCount: 1,
    retryDelay: 3000, // Wait 3 seconds between retries
  }
  const createMutation = useSWRMutation(allKey, createFetcher, mutationConfig)
  const deleteMutation = useSWRMutation(allKey, deleteFetcher,mutationConfig)
  const updateMutation = useSWRMutation(key, updateFetcher,mutationConfig)

  const options = {  onSuccess: () => {
      // Add delay before revalidation to prevent rate limiting
      setTimeout(() => revalidate(allKey), 3000)
    },
  ...mutationConfig}

  return {
    data: review.data,
    create: (data: Partial<IReview>) => createMutation.trigger(data, options),
    update: (data: Partial<IReview>) => updateMutation.trigger(data, options),
    delete: (data: Partial<IReview>) => deleteMutation.trigger(data, options),
    isLoading: review.isValidating || createMutation.isMutating || deleteMutation.isMutating,
    error: review.error || createMutation.error || deleteMutation.error,
  }
}

// API

const createFetcher = (url: string, { arg }: IUpdateData) => {
  return api.post<IReview>(url, arg).then((res) => res.data)
}

const updateFetcher = (url: string, { arg }: IUpdateData) => {
  return api.put<IReview>(url, arg).then((res) => res.data)
}

const deleteFetcher = (url: string, { arg }: IUpdateData) => {
  const _url = url.split('?')
  return api.delete(_url[0] + '/' + arg.id + '?' + url.split('?')[1]).then((res) => res.data)
}
