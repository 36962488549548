import { Typography } from 'antd'
import { useCompany } from 'hooks/useCompany'
import { toCurrency, toNumber, toPercentage } from 'utils/numbers'

type Type = 'currency' | 'number' | 'percent'

interface Props {
  amount: number
  type?: Type
  bold?: boolean
  min?: number
  size?: number
  currency?: string
}

export function ColorNumber(props: Props) {
  const { company } = useCompany()
  const { amount, bold, size, type = 'currency', min = 0, currency =  company?.settings?.currency || ''} = props

  const style = {
    color: amount < min ? 'darkred' : 'darkgreen',
    fontSize: size,
  }

  return (
    <Typography.Text style={style} strong={bold}>
      {format(amount, type, currency)}
    </Typography.Text>
  )
}

function format(value: number, type: Type, currency: string) {
  switch (type) {
    case 'currency':
      return toCurrency(value, currency)
    case 'percent':
      return value ? toPercentage(value) : ''
    default:
      return toNumber(value)
  }
}
