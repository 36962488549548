import React, { useState, useEffect } from 'react'
import { Button, Tooltip } from 'antd'
import type { ButtonProps } from 'antd'

const RATE_LIMIT_MS = 10000 // 10 seconds in milliseconds

interface RateLimitedButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick?: (...args: any[]) => void | Promise<void>;
}

const RateLimitedButton: React.FC<RateLimitedButtonProps> = ({
                                                               onClick,
                                                               disabled = false,
                                                               loading = false,
                                                               children,
                                                               htmlType,
                                                               ...buttonProps
                                                             }) => {
  const [lastRequestTime, setLastRequestTime] = useState(0)
  const [timeRemaining, setTimeRemaining] = useState(0)

  useEffect(() => {
    let intervalId: NodeJS.Timeout

    if (timeRemaining > 0) {
      intervalId = setInterval(() => {
        const newTimeRemaining = Math.max(0, RATE_LIMIT_MS - (Date.now() - lastRequestTime))
        setTimeRemaining(newTimeRemaining)
      }, 1000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [timeRemaining, lastRequestTime])

  const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
    const now = Date.now()
    const timeSinceLastRequest = now - lastRequestTime

    if (timeSinceLastRequest < RATE_LIMIT_MS) {
      setTimeRemaining(RATE_LIMIT_MS - timeSinceLastRequest)
      e.preventDefault()
      e.stopPropagation()
      return
    }

    setLastRequestTime(now)
    setTimeRemaining(RATE_LIMIT_MS)

    if (onClick) {
      await onClick(e)
    }

    // Manually submit the form once
    const form = (e.target as HTMLElement).closest('form')
    form?.requestSubmit()
  }

  const isRateLimited = timeRemaining > 0
  const tooltipTitle = isRateLimited
    ? `Please wait ${Math.ceil(timeRemaining / 1000)} seconds before making another request`
    : ''

  return (
    <Tooltip
      title={tooltipTitle}
      open={isRateLimited}
    >
      <Button
        {...buttonProps}
        onClick={handleClick}
        disabled={disabled || isRateLimited}
        loading={loading}
        htmlType={htmlType}
      >
        {children}
      </Button>
    </Tooltip>
  )
}

export default RateLimitedButton