import useSWR from 'swr'
import {IService, IServiceFile} from "../types/service.types";
export function useAdminServices() {
    return useSWR<IService[]>('admin/services', {
        refreshInterval: 0, // Only fetch when needed
        revalidateOnFocus: false // Don't refetch when window regains focus
    })
}

// Hook to fetch files for a specific service
export function useServiceFiles(serviceId: string | null) {
    return useSWR<IServiceFile[]>(
        serviceId ? `admin/services/${serviceId}/files` : null,
        {
            revalidateOnFocus: false
        }
    )
}