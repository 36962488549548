import { DefaultEventsMap } from '@socket.io/component-emitter';
import io, { Socket } from 'socket.io-client';
import { getCurrentUser, getIdToken } from 'utils/firebase';

// Connection state tracking
const CONNECTION_STATES = {
  DISCONNECTED: 'disconnected',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  ERROR: 'error'
};

// Enhanced Socket Manager with improved reliability
class SocketManager {
  private static instance: SocketManager;
  private socket: Socket<DefaultEventsMap, DefaultEventsMap> | null = null;
  private connectionState: string = CONNECTION_STATES.DISCONNECTED;
  private connectPromise: Promise<Socket | null> | null = null;
  private connectedUserId: string | null = null;
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 10;
  private lastConnectionError: Error | null = null;
  private reconnectTimer: NodeJS.Timeout | null = null;
  private heartbeatTimer: NodeJS.Timeout | null = null;
  private pingTimeoutTimer: NodeJS.Timeout | null = null;
  private connectionId: string = this.generateUniqueId();

  // Track operations in progress
  private activeOperations = new Map<string, {
    id: string,
    type: string,
    startTime: number,
    lastUpdate: number
  }>();

  // Use Map for efficient handler tracking with unique IDs
  private eventHandlers = new Map<string, Map<string, Function>>();

  // Use WeakMap to track handlers by function reference for cleanup
  private handlerWeakMap = new WeakMap<Function, string>();

  // Keep track of which events are registered on the current socket
  private registeredSocketEvents = new Set<string>();

  // Track ready state
  private readySent = false;

  // Track socket connection health metrics
  private metrics = {
    totalConnections: 0,
    successfulConnections: 0,
    failedConnections: 0,
    disconnections: 0,
    messagesSent: 0,
    messagesReceived: 0,
    averageLatency: 0,
    latencySamples: 0
  };

  private constructor() {
    console.log(`Creating SocketManager instance (ID: ${this.connectionId})`);
    this.startHeartbeat();

    // Add periodic cleanup of stale operations
    setInterval(() => this.cleanupStaleOperations(), 1800000); // every 30 minutes


    // Listen for online/offline events to proactively handle connection changes
    if (typeof window !== 'undefined') {
      window.addEventListener('online', this.handleOnline);
      window.addEventListener('offline', this.handleOffline);

      // Also handle visibility changes to detect when the user returns to the app
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }
  }

  static getInstance(): SocketManager {
    if (!SocketManager.instance) {
      SocketManager.instance = new SocketManager();
    }
    return SocketManager.instance;
  }

  // Generate a unique ID for various tracking purposes
  private generateUniqueId(): string {
    return Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
  }

  // Handle browser online event
  private handleOnline = (): void => {
    console.log('Network connection restored, reconnecting WebSocket...');
    if (!this.socket?.connected && this.connectionState !== CONNECTION_STATES.CONNECTING) {
      this.attemptReconnect(1000); // Small delay to ensure network is stable
    }
  };

  // Handle browser offline event
  private handleOffline = (): void => {
    console.log('Network connection lost, WebSocket may disconnect');
    // We don't need to explicitly disconnect - the socket will detect this itself
    // Just update our internal state awareness
    if (this.socket?.connected) {
      this.connectionState = CONNECTION_STATES.ERROR;
    }
  };

  // Handle tab visibility changes
  private handleVisibilityChange = (): void => {
    if (document.visibilityState === 'visible') {
      console.log('Tab became visible, checking WebSocket connection');
      // If we think we're connected but the socket is actually disconnected
      if (this.connectionState === CONNECTION_STATES.CONNECTED && !this.socket?.connected) {
        console.log('WebSocket disconnected while tab was hidden, reconnecting');
        this.attemptReconnect(500);
      }
    }
  };

  // Start a heartbeat to check connection health periodically
  private startHeartbeat(): void {
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }

    this.heartbeatTimer = setInterval(() => {
      if (this.socket?.connected) {
        const startTime = Date.now();

        // Clear any existing ping timeout
        if (this.pingTimeoutTimer) {
          clearTimeout(this.pingTimeoutTimer);
          this.pingTimeoutTimer = null;
        }

        // Send ping with timestamp for latency calculation
        this.socket.emit('ping', {
          timestamp: startTime,
          connectionId: this.connectionId,
          reconnectAttempts: this.reconnectAttempts
        });

        // Set up response timeout
        this.pingTimeoutTimer = setTimeout(() => {
          console.warn('Socket ping timeout - connection may be broken');

          // Force disconnect and reconnect if ping fails
          if (this.socket?.connected) {
            this.metrics.disconnections++;
            this.socket.disconnect();
            this.connectionState = CONNECTION_STATES.ERROR;
            this.attemptReconnect();
          }
        }, 5000);

        // Listen for pong response
        const pongHandler = (data: any) => {
          if (this.pingTimeoutTimer) {
            clearTimeout(this.pingTimeoutTimer);
            this.pingTimeoutTimer = null;
          }

          const endTime = Date.now();
          const latency = endTime - (data.timestamp || startTime);

          // Update latency metrics
          this.metrics.latencySamples++;
          this.metrics.averageLatency =
              (this.metrics.averageLatency * (this.metrics.latencySamples - 1) + latency) /
              this.metrics.latencySamples;

          // Reset reconnect attempts on successful ping
          if (this.reconnectAttempts > 0) {
            this.reconnectAttempts = 0;
          }

          this.socket?.off('pong', pongHandler);
        };

        this.socket.once('pong', pongHandler);
      } else if (this.connectionState === CONNECTION_STATES.CONNECTED) {
        // Socket thinks it's connected but the actual socket is disconnected
        console.warn('Socket heartbeat detected connection mismatch, reconnecting');
        this.connectionState = CONNECTION_STATES.ERROR;
        this.attemptReconnect();
      }
    }, 15000); // Check every 15 seconds
  }

  async connect(): Promise<Socket | null> {
    // If already connected, return the socket
    if (this.socket?.connected && this.connectionState === CONNECTION_STATES.CONNECTED) {
      return this.socket;
    }

    // If there's already a connection attempt in progress, return that promise
    if (this.connectPromise) {
      return this.connectPromise;
    }

    // Start a new connection attempt
    this.metrics.totalConnections++;
    this.connectionState = CONNECTION_STATES.CONNECTING;
    this.connectPromise = this._connect();

    try {
      const result = await this.connectPromise;
      if (result) {
        this.metrics.successfulConnections++;
      } else {
        this.metrics.failedConnections++;
      }
      return result;
    } finally {
      // Clear the promise when done
      this.connectPromise = null;
    }
  }

  private async _connect(): Promise<Socket | null> {
    try {
      // Get the Firebase UID
      const firebaseUser = await getCurrentUser();
      const firebaseUid = firebaseUser?.uid;

      if (!firebaseUid) {
        console.error('Missing Firebase user ID');
        this.lastConnectionError = new Error('Authentication required');
        this.connectionState = CONNECTION_STATES.ERROR;
        return null;
      }

      // If already connected with this user ID, return existing socket
      if (this.socket?.connected && this.connectedUserId === firebaseUid) {
        console.log('Already connected with user ID:', firebaseUid);
        this.connectionState = CONNECTION_STATES.CONNECTED;
        return this.socket;
      }

      // Reset connection tracking
      this.lastConnectionError = null;
      this.registeredSocketEvents.clear();
      this.readySent = false;

      // Disconnect any existing socket before creating a new one
      if (this.socket) {
        console.log('Disconnecting existing socket before reconnection');
        this.metrics.disconnections++;
        this.socket.disconnect();
        this.socket = null;
      }

      // Get authentication token
      const token = await getIdToken();
      if (!token) {
        console.error('Missing authentication token');
        this.lastConnectionError = new Error('Authentication failed');
        this.connectionState = CONNECTION_STATES.ERROR;
        return null;
      }

      // Determine environment for server URL
      const serverUrl = this.determineServerUrl();

      console.log('Socket.IO Connection Config:', {
        serverUrl,
        firebaseUid,
        connectionId: this.connectionId
      });

      // Create socket
      this.socket = io(serverUrl, {
        auth: {
          token,
          firebaseUid,
          connectionId: this.connectionId,
          clientInfo: this.getClientInfo()
        },
        path: '/socket.io',
        timeout: 20000,
        reconnection: true,
        reconnectionAttempts: 0, // Handle reconnection manually for more control
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        randomizationFactor: 0.5,
        transports: ['websocket', 'polling'],
        extraHeaders: {
          'X-Client-Version': process.env.REACT_APP_VERSION || 'unknown',
          'X-Client-Type': 'web',
          'X-Connection-ID': this.connectionId
        }
      });

      // Set up event listeners
      return new Promise<Socket | null>((resolve, reject) => {
        if (!this.socket) {
          const error = new Error('Failed to create socket instance');
          this.lastConnectionError = error;
          this.connectionState = CONNECTION_STATES.ERROR;
          reject(error);
          return;
        }

        // Add connection timeout
        const connectionTimeout = setTimeout(() => {
          console.error('Socket connection timeout');
          if (this.socket) {
            this.metrics.disconnections++;
            this.socket.disconnect();
          }
          this.lastConnectionError = new Error('Connection timeout');
          this.connectionState = CONNECTION_STATES.ERROR;
          resolve(null);
        }, 15000);

        this.socket.on('connect', () => {
          clearTimeout(connectionTimeout);

          console.log('Socket Connected Successfully:', {
            id: this.socket?.id,
            firebaseUid,
            connectionId: this.connectionId
          });

          this.connectionState = CONNECTION_STATES.CONNECTED;
          this.connectedUserId = firebaseUid;
          this.reconnectAttempts = 0; // Reset counter on successful connection

          // Re-register all event handlers on the new socket
          this.reattachEventHandlers();

          // Restart heartbeat for connection monitoring
          this.startHeartbeat();

          // Send ready signal with slight delay to prevent race conditions
          setTimeout(() => {
            if (this.socket?.connected && !this.readySent) {
              this.socket?.emit('ready', {
                clientTime: new Date().toISOString(),
                firebaseUid,
                connectionId: this.connectionId,
                clientInfo: this.getClientInfo()
              });
              this.readySent = true;
            }
          }, 300);

          resolve(this.socket);
        });

        this.socket.on('connect_error', (error) => {
          clearTimeout(connectionTimeout);
          this.lastConnectionError = error;
          this.reconnectAttempts++;
          this.connectionState = CONNECTION_STATES.ERROR;

          console.error('Socket Connection Error:', {
            message: error.message,
            attempt: this.reconnectAttempts,
            maxAttempts: this.maxReconnectAttempts
          });

          // If max attempts reached, reset the socket
          if (this.reconnectAttempts >= this.maxReconnectAttempts) {
            console.error('Max reconnection attempts reached, giving up');
            this.socket?.disconnect();
            this.socket = null;
            this.connectedUserId = null;
          }

          resolve(null);
        });

        // Listen for reconnect events
        this.socket.on('reconnect', (attemptNumber) => {
          console.log(`Socket reconnected after ${attemptNumber} attempts`);
          this.connectionState = CONNECTION_STATES.CONNECTED;

          // Re-authenticate on reconnect if needed
          if (this.connectedUserId && this.socket) {
            this.readySent = false;

            setTimeout(() => {
              if (this.socket?.connected && !this.readySent) {
                this.socket.emit('ready', {
                  clientTime: new Date().toISOString(),
                  firebaseUid: this.connectedUserId,
                  reconnected: true,
                  connectionId: this.connectionId
                });
                this.readySent = true;
              }
            }, 300);
          }
        });

        // Monitor disconnections
        this.socket.on('disconnect', (reason) => {
          console.warn(`Socket disconnected: ${reason}`);
          this.readySent = false;
          this.connectionState = CONNECTION_STATES.DISCONNECTED;
          this.metrics.disconnections++;

          // Log details about the disconnection
          console.log('Disconnection details:', {
            reason,
            wasConnected: this.connectedUserId !== null,
            firebaseUid: this.connectedUserId,
            socketId: this.socket?.id,
            connectionId: this.connectionId
          });

          if (['io server disconnect', 'transport close', 'ping timeout'].includes(reason)) {
            // Server-initiated disconnection, attempt reconnection
            this.attemptReconnect(2000);
          }
        });

        this.socket.on('error', (error) => {
          console.error('Socket error:', error);
          this.connectionState = CONNECTION_STATES.ERROR;
        });

        // Handle explicit acknowledgments from server
        this.socket.on('connected', (data) => {
          console.log('Server acknowledged connection:', data);
        });

        this.socket.on('ready_ack', (data) => {
          console.log('Server acknowledged ready state:', data);
        });

        // Track message receipts for metrics
        this.socket.onAny(() => {
          this.metrics.messagesReceived++;
        });
      });
    } catch (error) {
      console.error('WebSocket Connection Error:', error);
      this.lastConnectionError = error instanceof Error ? error : new Error(String(error));
      this.connectionState = CONNECTION_STATES.ERROR;
      return null;
    }
  }

  // Get browser/device information for better debugging
  private getClientInfo(): Record<string, string> {
    const info: Record<string, string> = {
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      connectionId: this.connectionId
    };

    // Add connection type if available
    // Use feature detection to avoid TypeScript errors with navigator.connection
    const nav = navigator as any;
    if (nav.connection) {
      const conn = nav.connection;
      if (conn.effectiveType) info.networkType = conn.effectiveType;
      if (conn.type) info.connectionType = conn.type;
    }

    return info;
  }

  // Determine server URL based on environment
  private determineServerUrl(): string {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const isLocalhost = window.location.hostname === 'localhost';
    const isStaging = window.location.hostname.includes('dev.equilityhq.com');
    const isProduction = window.location.hostname.includes('app.equilityhq.com');

    let serverUrl = '';

    if (isDevelopment && isLocalhost) {
      serverUrl = `http://${window.location.hostname}:3000`;
    } else if (isStaging) {
      serverUrl = 'https://api-dev.equilityhq.com';
    } else if (isProduction) {
      serverUrl = 'https://api.equilityhq.com';
    } else {
      serverUrl = window.location.origin;
    }

    return serverUrl;
  }

  getSocket(): Socket | null {
    return this.socket;
  }

  isConnected(): boolean {
    return this.connectionState === CONNECTION_STATES.CONNECTED && !!this.socket?.connected;
  }

  getConnectionState(): string {
    return this.connectionState;
  }

  // Enhanced event handler registration with duplicate prevention
  on(event: string, handler: Function): void {
    if (!event || typeof handler !== 'function') {
      console.warn('Invalid event or handler');
      return;
    }

    // Generate a unique ID for this handler
    const handlerId = this.getHandlerKey(handler);

    // First, make sure we have a Map for this event type
    if (!this.eventHandlers.has(event)) {
      this.eventHandlers.set(event, new Map());
    }

    // Get the handlers map for this event
    const handlers = this.eventHandlers.get(event);
    if (!handlers) return;

    // Check if we already have this exact handler
    if (handlers.has(handlerId)) {
      return;
    }

    // Add handler to our tracking map
    handlers.set(handlerId, handler);

    // Attach to socket if connected
    if (this.socket && this.socket.connected) {
      // Make sure we don't already have this handler on the socket
      this.socket.off(event, handler as any);

      // Then add it
      this.socket.on(event, handler as any);

      // Track this registration
      this.registeredSocketEvents.add(`${event}-${handlerId}`);
    }
  }

  off(event: string, handler: Function): void {
    if (!event || typeof handler !== 'function') return;

    if (this.eventHandlers.has(event)) {
      const handlers = this.eventHandlers.get(event);
      if (handlers) {
        // Find and remove the handler by key
        const handlerKey = this.getHandlerKey(handler);
        handlers.delete(handlerKey);

        if (handlers.size === 0) {
          this.eventHandlers.delete(event);
        }
      }
    }

    if (this.socket) {
      this.socket.off(event, handler as any);

      // Remove from registered events
      const socketEventKey = `${event}-${this.getHandlerKey(handler)}`;
      this.registeredSocketEvents.delete(socketEventKey);
    }
  }

  // Reattach all event handlers to a new socket connection
  private reattachEventHandlers(): void {
    if (!this.socket) return;

    console.log('Reattaching event handlers to new socket connection');
    this.registeredSocketEvents.clear();
    let attachedCount = 0;

    // Reattach all registered handlers
    this.eventHandlers.forEach((handlers, event) => {
      console.debug(`Reattaching ${handlers.size} handlers for event: ${event}`);

      handlers.forEach((handler, key) => {
        // First remove to prevent duplicates
        this.socket?.off(event, handler as any);
        // Then add
        this.socket?.on(event, handler as any);

        // Track that we've registered this handler
        const socketEventKey = `${event}-${key}`;
        this.registeredSocketEvents.add(socketEventKey);
        attachedCount++;
      });
    });

    console.log(`Reattached ${attachedCount} total handlers across ${this.eventHandlers.size} events`);
  }

  // Generate a unique key for a handler function
  private getHandlerKey(handler: Function): string {
    // First check if we've already assigned an ID to this handler
    if (this.handlerWeakMap.has(handler)) {
      return this.handlerWeakMap.get(handler)!;
    }

    // Generate a new unique ID for this handler
    const id = this.generateUniqueId();
    this.handlerWeakMap.set(handler, id);
    return id;
  }

  disconnect(): void {
    // Only disconnect if there are no active operations
    if (this.activeOperations.size > 0) {
      console.log('Cannot disconnect due to active operations:',
          Array.from(this.activeOperations.keys()).join(', '));
      return;
    }

    if (this.socket) {
      console.log('Manually disconnecting socket');
      this.metrics.disconnections++;
      this.socket.disconnect();
      this.socket = null;
      this.connectedUserId = null;
      this.readySent = false;
      this.connectionState = CONNECTION_STATES.DISCONNECTED;
    }

    // Clear any pending reconnect timer
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
      this.reconnectTimer = null;
    }

    // Clear heartbeat timer
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }

    // Clear ping timeout timer
    if (this.pingTimeoutTimer) {
      clearTimeout(this.pingTimeoutTimer);
      this.pingTimeoutTimer = null;
    }
  }

  // Register an active operation to prevent disconnection
  registerActiveOperation(id: string, type: string): void {
    this.activeOperations.set(id, {
      id,
      type,
      startTime: Date.now(),
      lastUpdate: Date.now()
    });
    console.log(`Registered active operation: ${type} (${id})`);

    // Ensure we have an active connection for this operation
    if (!this.isConnected()) {
      console.log(`Ensuring connection for operation: ${type}`);
      this.connect().catch(err => {
        console.error(`Failed to connect for operation ${id}:`, err);
      });
    }
  }

  // Unregister a completed operation
  unregisterActiveOperation(id: string): void {
    if (this.activeOperations.has(id)) {
      this.activeOperations.delete(id);
      console.log(`Unregistered operation: ${id}`);
    }
  }

  // Check if there are any active operations
  hasActiveOperations(): boolean {
    return this.activeOperations.size > 0;
  }

  // Get connection statistics
  getConnectionStats(): object {
    return {
      ...this.metrics,
      connectionState: this.connectionState,
      connectedUserId: this.connectedUserId,
      activeOperations: this.activeOperations.size,
      reconnectAttempts: this.reconnectAttempts,
      registeredEventTypes: this.eventHandlers.size,
      totalRegisteredHandlers: Array.from(this.eventHandlers.values())
          .reduce((total, handlers) => total + handlers.size, 0)
    };
  }

  // Clean up stale operations
  cleanupStaleOperations(): void {
    const now = Date.now();
    const staleThreshold = 3600000; // 1 hour

    for (const [id, operation] of this.activeOperations.entries()) {
      if (now - operation.lastUpdate > staleThreshold) {
        console.warn(`Operation ${id} appears stale, removing from tracking`);
        this.activeOperations.delete(id);
      }
    }
  }

  // Update operation with latest activity
  updateOperationActivity(id: string): void {
    if (this.activeOperations.has(id)) {
      const operation = this.activeOperations.get(id)!;
      operation.lastUpdate = Date.now();
      this.activeOperations.set(id, operation);
    }
  }

  // Implement a controlled reconnect method with exponential backoff
  attemptReconnect(immediateDelay?: number): void {
    this.cleanupStaleOperations();
    // Don't schedule multiple reconnects
    if (this.reconnectTimer || this.connectionState === CONNECTION_STATES.CONNECTING) {
      return;
    }

    // Calculate backoff with jitter based on reconnect attempts
    // Cap at 30 seconds maximum delay
    const baseDelay = immediateDelay !== undefined ?
        immediateDelay :
        Math.min(30000, Math.pow(2, Math.min(4, this.reconnectAttempts)) * 1000);

    // Add jitter to prevent thundering herd problem (±30%)
    const jitter = baseDelay * (0.7 + Math.random() * 0.6);
    const delay = Math.round(baseDelay + jitter);

    console.log(`Scheduling reconnect in ${delay}ms (attempt ${this.reconnectAttempts + 1})`);

    this.reconnectTimer = setTimeout(async () => {
      this.reconnectTimer = null;

      // Only attempt to connect if we're still disconnected
      if (this.connectionState !== CONNECTION_STATES.CONNECTED) {
        console.log('Attempting scheduled reconnection');
        await this.connect();
      }
    }, delay);
  }

  // Clean up all resources
  destroy(): void {
    // Remove event listeners
    if (typeof window !== 'undefined') {
      window.removeEventListener('online', this.handleOnline);
      window.removeEventListener('offline', this.handleOffline);
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }

    // Clear all timers
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }

    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
      this.reconnectTimer = null;
    }

    if (this.pingTimeoutTimer) {
      clearTimeout(this.pingTimeoutTimer);
      this.pingTimeoutTimer = null;
    }

    // Disconnect socket
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }

    // Clear all maps
    this.activeOperations.clear();
    this.eventHandlers.clear();
    this.registeredSocketEvents.clear();

    // Reset state
    this.connectionState = CONNECTION_STATES.DISCONNECTED;
    this.connectedUserId = null;
    this.readySent = false;

    console.log('SocketManager destroyed and all resources cleaned up');
  }
}

// Export simplified methods for components to use
export const connectToWebSocket = async (): Promise<Socket | null> => {
  return await SocketManager.getInstance().connect();
};

export const disconnectFromWebSocket = (): void => {
  SocketManager.getInstance().disconnect();
};

export const isSocketConnected = (): boolean => {
  return SocketManager.getInstance().isConnected();
};

export const getSocketConnectionState = (): string => {
  return SocketManager.getInstance().getConnectionState();
};

export const getSocket = (): Socket | null => {
  return SocketManager.getInstance().getSocket();
};

export const getConnectionStats = (): object => {
  return SocketManager.getInstance().getConnectionStats();
};

// Event handler management
export const onSocketEvent = (event: string, handler: Function): void => {
  if (!event || typeof handler !== 'function') {
    console.warn('Invalid event or handler provided to onSocketEvent', { event });
    return;
  }

  // Optimized approach: just let the manager handle duplicates internally
  const manager = SocketManager.getInstance();
  manager.on(event, handler);
};

export const offSocketEvent = (event: string, handler: Function): void => {
  if (!event || typeof handler !== 'function') return;
  SocketManager.getInstance().off(event, handler);
};

// Add operation tracking for critical processes
export const registerOperation = (id: string, type: string): void => {
  if (!id || !type) {
    console.warn('Invalid operation registration attempt', { id, type });
    return;
  }
  SocketManager.getInstance().registerActiveOperation(id, type);
};

export const unregisterOperation = (id: string): void => {
  if (!id) return;
  SocketManager.getInstance().unregisterActiveOperation(id);
};

export const updateOperation = (id: string): void => {
  if (!id) return;
  SocketManager.getInstance().updateOperationActivity(id);
};

export const hasActiveOperations = (): boolean => {
  return SocketManager.getInstance().hasActiveOperations();
};

// Utility to ensure socket is connected before an operation
export const ensureSocketConnected = async (): Promise<boolean> => {
  try {
    if (isSocketConnected()) {
      return true;
    }

    console.log('Ensuring socket connection...');
    const socket = await connectToWebSocket();
    const connected = !!socket?.connected;

    if (!connected) {
      console.warn('Failed to establish socket connection');
    }

    return connected;
  } catch (error) {
    console.error('Error in ensureSocketConnected:', error);
    return false;
  }
};

// Cleanup utility for component unmounts
export const cleanupWebSocket = (): void => {
  // Only actually destroy the manager if there are no active operations
  if (!hasActiveOperations()) {
    SocketManager.getInstance().destroy();
  }
};