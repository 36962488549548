import {App, Modal, Select} from 'antd'
import { SelectProps } from 'antd/lib'
import { useEffect, useState } from 'react'
import { IAcceptedMember, RoleType } from 'types/user.types'
import { getRoleText } from 'utils/team'

type EditMemberRoleModalProps = {
  open: boolean
  onClose: () => void
  updateRoleLoading: boolean
  updateRole: ({ user, role, teamId }: { user: number; role: RoleType; teamId?: number }) => Promise<void>
  user: IAcceptedMember | null
  teamId?: number
}

function EditMemberRoleModal(props: EditMemberRoleModalProps) {
  const {notification } = App.useApp();
  const { open, onClose, updateRoleLoading, updateRole, user, teamId } = props
  const [role, setRole] = useState(user?.role)

  const roleOptions: SelectProps['options'] = [
    { label: getRoleText(RoleType.Admin), value: RoleType.Admin },
    { label: getRoleText(RoleType.Member), value: RoleType.Member },
    { label: getRoleText(RoleType.ViewOnly), value: RoleType.ViewOnly },
  ]

  const onSubmit = async () => {
    const userId = user?.user.id
    if (!userId) return

    try {
      if (teamId) {
        // If teamId is provided, include it in the payload
        await updateRole({ user: userId, role: role as RoleType, teamId })
      } else {
        // Backward compatibility
        await updateRole({ user: userId, role: role as RoleType })
      }
      onClose()
    } catch (error) {
      notification.error({ message: 'Failed to update role: ' + error })
    }
  }

  useEffect(() => {
    setRole(user?.role)
  }, [user?.role])

  return (
      <Modal
          title="Edit Role"
          open={open}
          onCancel={onClose}
          okText="Submit"
          onOk={onSubmit}
          okButtonProps={{ loading: updateRoleLoading }}
      >
        <Select
            value={role}
            onChange={setRole}
            placeholder="Select an option"
            options={roleOptions}
            optionFilterProp="label"
            style={{ width: '100%' }}
        />
      </Modal>
  )
}

export default EditMemberRoleModal