import { Fragment, useMemo } from 'react'
import { Button, Flex, Table } from 'antd'

import { toCurrency } from 'utils/numbers'
import { ColumnsType } from 'antd/es/table'
import { useCompany } from 'hooks/useCompany'
import { AccountFormattedData } from '../Charts'

type DataTableProps = {
  data: any[]
  csvName: string
  currency?: string
}

function DataTable(props: DataTableProps) {
  const { company } = useCompany()
  const { data, csvName, currency =  company?.settings?.currency || ''} = props
  const columns = useMemo(() => generateColumns(data, currency), [data])

  const exportToCSV = () => {
    const csvHeader = columns.map((column) => column.title).join(',') + '\n'

    const csvRows = data
      .map((row) =>
        columns
          //   TODO fix type
          .map((column: any) => {
            const value = row[column.dataIndex]?.toString().replace(',', '')
            return value && value !== '0' ? value : `0.00`
          })
          .join(','),
      )
      .join('\n')

    const csvContent = csvHeader + csvRows

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = csvName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Fragment>
      {data.length ? (
        <Flex style={{ marginTop: '20px', justifyContent: 'flex-end' }}>
          <Button onClick={exportToCSV} type="primary">
            Export to CSV
          </Button>
        </Flex>
      ) : null}
      <Table columns={columns} dataSource={data} rowKey={'name'} />
    </Fragment>
  )
}


const generateColumns = (data: AccountFormattedData[], currency: string) => {
  if (!data.length) return [];

  // Destructure to remove 'name' and 'meta' fields
  const { name: _name, meta: _meta, ...accountColumns } = data[0];



  // The first column should always be the date (from the 'name' field in your row)
  const columns: ColumnsType<any> = [{ dataIndex: 'name', title: 'Date' }];

  // Now add a column for each remaining key
  Object.keys(accountColumns).forEach((key) => {
    columns.push({
      dataIndex: key,
      title: key,
      render: (value: any) => toCurrency(value, currency),
    });
  });


  return columns;

}

export default DataTable
