import { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { Button, Col, Divider, Flex, Row, Table, Input } from 'antd'
import type { ColumnsType } from 'antd/es/table'

// import { api } from 'utils/axios'
// import { downloadBlob } from 'utils/download'

import { IDefaultRule } from 'types/default-rules.types'
import { useDefaultRules } from '../../hooks/useDefaultRule'

import { AddRuleModal } from '../../components/AddRuleModal'

export function RulesTab() {
  const params = useParams()
  const rules = useDefaultRules()
  const [searchTerm, setSearchTerm] = useState('')
  // const [loading, setLoading] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [activeRule, setActiveRule] = useState<IDefaultRule | undefined>(undefined)

  const {  refetch } = useDefaultRules()

  // const download = async () => {
  //   setLoading(true)
  //   return api
  //     .get('admin/rules/export', { responseType: 'blob' })
  //     .then((r) => downloadBlob(r.data, 'equility-rules.xls'))
  //     .finally(() => setLoading(false))
  // }

  // const handleDelete = async (rule: IDefaultRule) => {
  //   try {
  //     await remove(rule.id)
  //     console.log('Rule deleted', rule.id)
  //     await refetch()
  //   } catch (error) {
  //     console.error('Error deleting the rule:', error)
  //   }
  // }

  const filteredData = rules.data?.filter((rules) => {
    const searchLower = searchTerm.toLowerCase()
    return (
      rules.name.toLowerCase().includes(searchLower) || (rules.description || '').toLowerCase().includes(searchLower)
    )
  })

  const openAddModal = () => {
    setIsModalVisible(true)
  }

  const columns: ColumnsType<IDefaultRule> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 280,
      ellipsis: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Description',
      dataIndex: 'description',

      ellipsis: true,
      render: (description) => description || '-',
    },
    {
      title: 'Rule Entity',
      dataIndex: 'entity',
      width: 120,
      ellipsis: true,
      render: (entity) => entity || '-',
    },
      // unable to delete for now since default rules are hardcoded
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   width: 120,
    //   render: (_: any, record: IDefaultRule) => (
    //     <Popconfirm
    //       title="Delete Rule"
    //       description="Are you sure you want to delete this rule?"
    //       onConfirm={(e) => {
    //         e?.stopPropagation()
    //         handleDelete(record)
    //         console.log('Delete rule:', record.id)
    //       }}
    //       onCancel={(e) => e?.stopPropagation()}
    //       okText="Yes"
    //       cancelText="No"
    //     >
    //       <Button
    //         danger
    //         //  loading={removeLoading}
    //         onClick={(e: React.MouseEvent) => e.stopPropagation()}
    //       >
    //         Delete
    //       </Button>
    //     </Popconfirm>
    //   ),
    // },
  ]

  return (
    <Flex vertical gap={10}>
      <Row gutter={24}>
        <Col span={8}>
          <Input
            placeholder="Search by name or alias..."
            prefix={<SearchOutlined />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            allowClear
          />
        </Col>
        <Col span={12}>
          <Divider orientation="left">{filteredData?.length} total</Divider>
        </Col>
        <Col span={4}>
          <Flex vertical justify="center" style={btnFlexStyle}>
            {/* <Button block onClick={download} loading={loading}>
              Download
            </Button> */}
            <Button type="primary" onClick={openAddModal} style={{ marginBottom: '0px' }}>
              Add Rule
            </Button>
          </Flex>
        </Col>
      </Row>

      <Table
        dataSource={filteredData}
        columns={columns}
        rowKey="id"
        loading={rules.isLoading}
        onRow={(record) => {
          return {
            onClick: () => {
              setIsModalVisible(true)
              setActiveRule(record)
            },
          }
        }}
      />
      <AddRuleModal
        open={isModalVisible}
        onClose={() => {
          setIsModalVisible(false)
          setActiveRule(undefined)
        }}
        onSuccess={refetch}
        initialValues={activeRule}
        ruleId={params?.id}
      />
    </Flex>
  )
}

const btnFlexStyle: React.CSSProperties = {
  height: '100%',
}
