import { useMatch, useNavigate } from 'react-router-dom'
import { Tabs, TabsProps } from 'antd'
import { Page } from 'components/Page'
import { ProjectsTab } from './Projects'
import { TasksPage } from './Tasks'
import { TemplatesPage } from './Templates'
import { analytics } from '../../utils/analytics'
import { useFlags } from 'flagsmith/react'

export function WorkflowPage() {
  const navigate = useNavigate()
  const flags = useFlags(['disable_workflow_templates'])

  const match = useMatch('/workflow/:tab')
  const selected = match?.params['tab'] || 'projects'

  const onChange = (key: string) => navigate(`/workflow/${key}`)

  analytics.page()

  const items: TabsProps['items'] = [
    {
      label: 'Projects',
      key: 'projects',
      children: <ProjectsTab />,
    },
    {
      label: 'Tasks',
      key: 'tasks',
      children: <TasksPage />,
    },
  ]

  if (!flags.disable_workflow_templates.enabled) {
    items.push({
      label: 'Templates',
      key: 'templates',
      children: <TemplatesPage />,
    })
  }

  return (
      <Page title="Workflow">
        <Tabs activeKey={selected} items={items} onChange={onChange} />
      </Page>
  )
}