import useSWRMutation from 'swr/mutation'
import { api } from 'utils/axios'

export function useFileManager() {
  const { trigger: uploadSingleFile, isMutating: uploadSingleLoading } = useSWRMutation(
    `file-manager/upload`,
    uploadSingleFetcher,
  )

  const { trigger: deleteSignleFile, isMutating: deleteSingleLoading } = useSWRMutation(
    `file-manager/delete`,
    deleteSingleFetcher,
  )

  const uploadSingle = (data: File, onSuccess?: (data: any) => void) => {
    return uploadSingleFile(data, { 
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data)
        }
        return data
      } 
    })
  }

  const deleteSingle = (data: { file: { url: string, fid: string } }, onSuccess?: () => void) => {
    return deleteSignleFile(data, { onSuccess })
  }

  return {
    uploadSingle,
    uploadSingleLoading,
    deleteSingle,
    deleteSingleLoading,
  }
}

const uploadSingleFetcher = async (url: string, { arg }: { arg: File }) => {
  const formData = new FormData()
  formData.append('file', arg)

  const res = await api.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return res.data
}

const deleteSingleFetcher = async (url: string, { arg }: { arg: { file: { url: string, fid: string } } }) => {
  const res = await api.post(url, arg)
  return res.data
}
