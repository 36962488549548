import { useCompanyStore } from 'store/company.store'
import useSWR from 'swr'
import {getTransactionsApiUrl} from "./bankTransformUtils";
import {IBankTransaction} from "../types/bank-transaction.types";


interface BankTransactionsResponse {
  data: IBankTransaction[] | null
  error?: any
  isLoading: boolean
}

interface HookParams {
  id: string | null
  to: string
  from: string
}

export function useBankTransactions(params: HookParams): BankTransactionsResponse {
  const companyId = useCompanyStore((state) => state.id)
  const { id, from, to } = params

  // Fixed type issue with companyId
  const key = id ? getTransactionsApiUrl({
    companyId: companyId || 0,
    accountId: id,
    from,
    to
  }) : null

  const { data, error, isLoading } = useSWR<any[]>(key)

  return {
    data: data || null,
    error,
    isLoading
  }
}
