import { Form, Input, Button, message } from 'antd'
import { useState } from 'react'
import { api } from 'utils/axios'

type TeamSettingsProps = {
    team: any
    refetchTeam: () => void
}

export function TeamSettings({ team, refetchTeam }: TeamSettingsProps) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const onFinish = async (values: { name: string }) => {
        setLoading(true)
        try {
            // Call your API endpoint to update the team name.
            await api.patch(`/team/${team.id}`, { name: values.name })
            message.success('Team name updated successfully')
            refetchTeam()
        } catch (error) {
            message.error('Failed to update team name: '+ error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{ name: team?.name }}
        >
            <Form.Item
                label="Team Name"
                name="name"
                rules={[{ required: true, message: 'Please input the team name' }]}
            >
                <Input placeholder="Enter team name" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Update Team Name
                </Button>
            </Form.Item>
        </Form>
    )
}

export default TeamSettings
