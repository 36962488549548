import { useLayoutEffect, useRef, useState, useEffect, useCallback } from 'react'
import { App, Button, Divider, Flex, Segmented, Table, TableColumnsType, Tooltip, Typography, Progress } from 'antd'
import {CheckCircleTwoTone, PlusCircleTwoTone} from '@ant-design/icons'
import { ColorNumber } from 'components/ColorNumber'
import { AddToQBO } from './AddToQBO'
import { MatchModal } from './Match'
import { api } from 'utils/axios'
import { reportToTsx } from 'utils/tx'
import { getErrorMsg } from 'utils/geterror'
import { prevMonthStart } from 'utils/dates'
import { useRole } from 'hooks/useRole'
import { RoleType } from 'types/user.types'
import type { IReconcile } from 'types/reconcile'
import type { IMatcher } from 'types/matcher'
import type { ITx } from 'types/tx.types'
import { useFlags } from 'flagsmith/react'
import {ensureSocketConnected
} from 'utils/websocket'
import {getCurrentUser} from "../../../../utils/firebase";
import {useUser} from "../../../../hooks/useUser";
import {useWebSocket} from "../../../../hooks/useWebsockets";

const { Text } = Typography

interface Props {
  type: 'qbo' | 'bank'
  title: string
  matcher: IMatcher
  rec: IReconcile
  loading: boolean
  onLoading: (loading: boolean) => void
  webSocketHandlersRegistered?: React.MutableRefObject<boolean>;
}

interface ExtendedTx extends ITx {
  _key: string
  failed: boolean
}

// For WebSocket progress updates
interface DeleteProgress {
  transactionId?: string
  status?: 'pending' | 'processing' | 'success' | 'error' | 'warning'
  error?: string
  message?: string
  current?: number
  total?: number
  successCount?: number
  failCount?: number
  done?: boolean
  reconciliationId?: string
}

export function TxList(props: Props) {
  const { notification } = App.useApp()
  const { type, matcher, title, rec } = props
  const { loading, onLoading } = props
  const { isAllowed } = useRole(RoleType.Member)
  const { user } = useUser()
  const flags = useFlags(['disable_chrome_plugin', 'disable_add_to_quickbooks'])
  const [currentUserId, setCurrentUserId] = useState<number | null>(null);


  const data = matcher[type] || []
  const btnRef = useRef<any>()
  const ref = useRef<HTMLDivElement>(null)
  const height = useWindowHeight()
  const top = ref?.current?.getBoundingClientRect().top || 0
  const minHeight = height - top - 215

  const [filter, setFilter] = useState<FilterTx>('Unmatched')
  const [uploadModal, setUploadModal] = useState(false)
  const [matchModal, setMatchModal] = useState(false)
  const [selectedTx, setSetSelectedTx] = useState<ITx[]>([])

  // Track deletion progress
  const [processedMessageIds, setProcessedMessageIds] = useState<Set<string>>(new Set());
  const [deletingTxs, setDeletingTxs] = useState(false)
  const [lastProgressUpdateTime, setLastProgressUpdateTime] = useState(0);
  const fallbackTimerRef = useRef<NodeJS.Timeout | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setDeletionProgress] = useState<DeleteProgress[]>([])
  const [completedCount, setCompletedCount] = useState(0)
  const [totalToDelete, setTotalToDelete] = useState(0)
  const deletionInProgressRef = useRef(false)
  const { registerHandler, unregisterHandler } = useWebSocket();
  const stableHandlerRef = useRef<((data: DeleteProgress) => void) | null>(null);
  const [failedTransactions, setFailedTransactions] = useState<Set<string>>(new Set());

  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);


  // Add a useEffect to get the current user ID
  useEffect(() => {
    const fetchCurrentUserId = async () => {
      try {
        if (user && user.id) {
          setCurrentUserId(user.id);
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    fetchCurrentUserId();
  }, []);
  const isAddToQuickbooksDisabled = useCallback(() => {
    // If the flag doesn't exist, enable the feature
    if (!flags?.disable_add_to_quickbooks) {
      return false;
    }

    // If the flag is not enabled, then don't disable the feature
    if (!flags.disable_add_to_quickbooks.enabled) {
      return false;
    }

    // From this point, we know the flag exists and is enabled

    // If value is null/undefined/empty string, disable for everyone
    if (flags.disable_add_to_quickbooks.value === null ||
        flags.disable_add_to_quickbooks.value === undefined ||
        flags.disable_add_to_quickbooks.value === "") {
      return true;
    }

    // Boolean true value - disable for everyone
    if (flags.disable_add_to_quickbooks.value === true) {
      return true;
    }

    // Boolean false value - enable for everyone
    if (flags.disable_add_to_quickbooks.value === false) {
      return false;
    }

    // If we don't have a user ID yet, disable the feature temporarily
    if (!currentUserId) {
      return true;
    }

    // If the flag value is a number (like a user ID)
    if (typeof flags.disable_add_to_quickbooks.value === 'number') {
      const flagValueStr = flags.disable_add_to_quickbooks.value.toString();
      const userIdStr = currentUserId.toString();
      const isMatch = flagValueStr === userIdStr;

      // If the values match, ALLOW the user (don't disable)
      if (isMatch) {
        return false;
      } else {
        return true;
      }
    }

    // If the flag value is a string, check if it matches the user ID
    if (typeof flags.disable_add_to_quickbooks.value === 'string') {
      const userIdStr = currentUserId.toString();
      const isMatch = flags.disable_add_to_quickbooks.value === userIdStr;

      // If the values match, ALLOW the user (don't disable)
      if (isMatch) {
        return false;
      } else {
        return true;
      }
    }

    // Default case - if we don't recognize the value type, disable the feature for safety
    return true;
  }, [flags, currentUserId]);



  const handleProgressUpdate = useCallback((data: DeleteProgress) => {
    console.log('Progress update received:', data);

    // Update the last progress update time whenever a message is received
    setLastProgressUpdateTime(Date.now());

    // Create a unique ID for this message to detect duplicates
    const messageId = `${data.reconciliationId}-${data.transactionId || 'global'}-${data.current || 0}-${data.status || ''}-${data.total || 0}`;

    // Skip if we've already processed an identical message
    if (processedMessageIds.has(messageId)) {
      console.log('Skipping duplicate progress update:', messageId);
      return;
    }

    // Add to processed messages
    setProcessedMessageIds(prev => {
      const updated = new Set(prev);
      updated.add(messageId);
      // Keep set size manageable by removing old entries if it gets too large
      if (updated.size > 100) {
        const iterator = updated.values();
        updated.delete(iterator.next().value);
      }
      return updated;
    });

    // Update transaction status if transactionId is present
    if (data.transactionId && data.status) {
      setDeletionProgress((prev) =>
          prev.map((p) =>
              p.transactionId === data.transactionId
                  ? { ...p, status: data.status, error: data.error }
                  : p
          )
      );

        if (data.status === 'warning') {
          // Add to failed transactions set
          setFailedTransactions(prev => {
            const updated = new Set(prev);
            if (data.transactionId != null) {
              updated.add(data.transactionId);
            }
            return updated;
          });
        }


      // Handle individual transaction success or failure
      if (data.status === 'success') {
        // Mark successful transactions as deleted in the UI
        matcher.markAsDeleted([data.transactionId]);
      }
      // For failed transactions, we keep them in the UI but add an error state
      // This will be handled in the rendering logic
    }

    // Update completed count
    if (data.current !== undefined) {
      setCompletedCount(data.current);
    }

    // Update notification with progress
    if (data.current !== undefined && data.total !== undefined) {
      const successCount = typeof data.successCount === 'number'
          ? data.successCount
          : (data.successCount ? parseInt(String(data.successCount), 10) || 0 : 0);

      const failCount = typeof data.failCount === 'number'
          ? data.failCount
          : (data.failCount ? parseInt(String(data.failCount), 10) || 0 : 0);

      notification.open({
        key: 'deleting',
        message: 'Deleting transactions',
        description: (
            <div>
              <div>Deleting {data.total} transaction(s)...</div>
              <div style={{ marginTop: 10 }}>
                <Progress
                    percent={Math.round((data.current / data.total) * 100)}
                    status="active"
                />
              </div>
              <div>
                <Text>Completed: </Text>
                <Text strong>{data.current}</Text>
                <Text> of </Text>
                <Text strong>{data.total}</Text>
                {data.successCount !== undefined && (
                    <div style={{ marginTop: 0 }}>
                      {/* Completely separate elements */}
                      <Text style={{ color: '#52c41a', marginRight: 4 }}>
                        {successCount}
                      </Text>
                      <Text style={{ color: '#52c41a' }}> succeeded</Text>

                      {failCount > 0 && (
                          <>
                            <Text> | </Text>
                            <Text style={{ color: '#f5222d', marginRight: 4 }}>
                              {failCount}
                            </Text>
                            <Text style={{ color: '#f5222d' }}> failed</Text>
                          </>
                      )}
                    </div>
                )}
              </div>
            </div>
        ),
        duration: 0,
      });
    }

    // Handle done state
    if (data.done || (data.current !== undefined && data.total !== undefined && data.current >= data.total)) {
      if (fallbackTimerRef.current) {
        clearInterval(fallbackTimerRef.current);
        fallbackTimerRef.current = null;
      }
      deletionInProgressRef.current = false;

      // Final notification showing success/failure details
      if (data.failCount && data.failCount > 0) {
        // Show error notification with details
        notification.error({
          key: 'deleting',
          message: 'Deletion Complete with Errors',
          description: (
              <div>
                <div>Completed deleting {data.total} transaction(s):</div>
                <div style={{ marginTop: 8 }}>
                  <Text type="success">{data.successCount || 0} successful</Text>
                  <Text> | </Text>
                  <Text type="danger">{data.failCount} failed</Text>
                </div>
                {data.message && (
                    <div style={{ marginTop: 8 }}>
                      <Text type="secondary">{data.message}</Text>
                    </div>
                )}
                <div style={{ marginTop: 8 }}>
                  <Text type="warning">
                    Some transactions could not be deleted because they are bank‐matched in QuickBooks.
                    If you receive a ValidationFault error, it means the transaction is locked for deletion.
                    Please unmatch it manually in QuickBooks Online before attempting to delete it.
                  </Text>
                  <br />
                  <Text type="secondary">
                    Failed transactions remain visible in the list with an error indicator.
                  </Text>
                </div>

              </div>
          ),
          duration: 20,
        });
      } else {
        // Show success notification
        notification.success({
          key: 'deleting',
          message: 'Success',
          description: `Successfully deleted ${data.successCount || 0} of ${data.total || totalToDelete} transaction(s)`,
          duration: 5,
        });
      }

      setDeletingTxs(false);
    }

    // Handle error state
    if (data.error && !data.transactionId) {
      deletionInProgressRef.current = false;
      notification.error({
        key: 'deleting',
        message: 'Error',
        description: data.error,
        duration: 4,
      });
      setDeletingTxs(false);
    }
  }, [notification, totalToDelete, processedMessageIds, matcher]);
  useEffect(() => {
    // Update the ref whenever the handler changes
    stableHandlerRef.current = handleProgressUpdate;
  }, [handleProgressUpdate]);
  const stableHandlerWrapper = useCallback((data: any) => {
    // Call the current implementation of handler via the ref
    if (stableHandlerRef.current) {
      stableHandlerRef.current(data);
    }
  }, []);
  useEffect(() => {
    // Only register if we're the QBO component or if no sharing mechanism is provided
    const shouldRegister = type === 'qbo' && (!props.webSocketHandlersRegistered || !props.webSocketHandlersRegistered.current);

    if (shouldRegister) {
      // If using a shared tracking ref, check and set it
      if (props.webSocketHandlersRegistered) {
        // Mark as registered
        props.webSocketHandlersRegistered.current = true;
      }

      console.log(`Registering deleteProgress handler in ${type} component`);
      registerHandler('deleteProgress', stableHandlerWrapper);

      return () => {
        // Clean up
        if (fallbackTimerRef.current) {
          clearInterval(fallbackTimerRef.current);
          fallbackTimerRef.current = null;
        }

        // Only unregister if we were the one who registered
        if (props.webSocketHandlersRegistered && props.webSocketHandlersRegistered.current) {
          console.log(`Unregistering deleteProgress handler in ${type} component`);
          unregisterHandler('deleteProgress', stableHandlerWrapper);

          // Reset registration flag on unmount if we're using the shared ref
          // and we're the component that registered it
          props.webSocketHandlersRegistered.current = false;
        }
      };
    }

    // No cleanup needed if we didn't register
    return undefined;
  }, [type, registerHandler, unregisterHandler, stableHandlerWrapper, props.webSocketHandlersRegistered]);


  // Process data to include unique keys
  const processedData = data
      .map((tx: ITx, index: number) => {
        // Check if this transaction is marked as failed
        const isFailed = failedTransactions.has(tx._id);

        return {
          ...tx,
          _key: `${type}-${tx._id}-${index}`,
          failed: isFailed, // Add a failed flag
        };
      })
      .filter(tx => !tx.deleted || failedTransactions.has(tx._id));


  const cleared = processedData.filter((v) => v.matchedTo?.length || v.forceMatch)
  const selected = processedData.filter((v) => v.selected)

  let dataSource = processedData
  if (filter === 'Matched') {
    dataSource = dataSource.filter((tx) => tx.matchedTo?.length || tx.forceMatch)
  } else if (filter === 'Unmatched') {
    dataSource = dataSource.filter((tx) => !tx.matchedTo?.length && !tx.forceMatch)
  }

  const handleSelectionChange = (selectedKeys: React.Key[], selectedRows: ExtendedTx[]) => {
    const selectedIds = selectedRows.map((tx) => tx._id)

    if (type === 'bank') {
      matcher.selectBank(selectedIds)
    } else {
      matcher.selectQbo(selectedIds)
    }
  }

  const getSelectedKeys = () => {
    return selected
        .map((v) => {
          const matchingProcessedTx = processedData.find((tx) => tx._id === v._id)
          return matchingProcessedTx?._key
        })
        .filter(Boolean) as string[]
  }

  const columns: TableColumnsType<ExtendedTx> = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: 110,
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 130,
      render: (value: number, _) => (
          <>
            <ColorNumber amount={value} />
          </>
      ),
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      ellipsis: true,
      render: (text, _) => (
          <span>
        {text}
      </span>
      ),
      sorter: (a, b) => (a.memo || '').localeCompare(b.memo || ''),
    },
    {
      title: 'Match Action',
      dataIndex: '_id',
      width: 70,
      render: (_, record) => {
        const checked = !!record.forceMatch || !!record.matchedTo.length;

        return (
            <Flex gap={10}>
              <Tooltip placement="top" title="Click to match to a transaction within QuickBooks Online">
                {checked ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <PlusCircleTwoTone twoToneColor={hoveredRowId === record._id ? "#52c41a" : "#999"} />}
              </Tooltip>
              {record.failed && (
                  <Tooltip title="This transaction could not be deleted">
                    <Text type="danger" style={{ marginLeft: 8 }}>(!)</Text>
                  </Tooltip>
              )}
            </Flex>
        );
      },
      onCell: (record) => ({
        onClick: () => {
          if (!isAllowed || record.failed) return;
          setSetSelectedTx([record]);
          setMatchModal(true);
        },
      }),
    },
  ];

  const matchSelected = () => {
    setSetSelectedTx(dataSource.filter((v) => v.selected))
    setMatchModal(true)
  }

  // Updated onDelete method using WebSockets
  // Update the onDelete function to handle failed transactions better

  const onDelete = async () => {
    if (!selected.length) return;

    try {
      // Clear any existing fallback timer
      if (fallbackTimerRef.current) {
        clearInterval(fallbackTimerRef.current);
        fallbackTimerRef.current = null;
      }

      // Get the selected transaction IDs
      const transactions = selected.map(tx => ({
        id: tx._id,
        qboId: tx.id,
        type: tx.type // Include transaction type
      }));
      const totalCount = transactions.length;
      setTotalToDelete(totalCount);
      setDeletingTxs(true);
      setCompletedCount(0);
      deletionInProgressRef.current = true;

      // Set initial update time to now, giving a grace period before checking
      setLastProgressUpdateTime(Date.now());

      // Initialize progress tracking for all transactions
      setDeletionProgress(transactions.map(transaction => ({
        transactionId: transaction.id,
        status: 'pending'
      })));

      // IMPORTANT: Don't mark as deleted in UI until we know the operation succeeded
      // We'll remove them from the UI based on the success/failure information

      // Show initial deletion progress notification
      notification.open({
        key: 'deleting',
        message: 'Deleting transactions',
        description: (
            <div>
              <div>Deleting {totalCount} transaction(s)...</div>
              <div style={{ marginTop: 10 }}>
                <Progress percent={0} status="active" />
              </div>
              <div>
                <Text>Completed: </Text>
                <Text strong>{0}</Text>
                <Text> of </Text>
                <Text strong>{totalCount}</Text>
              </div>
            </div>
        ),
        duration: 0
      });

      // If this is a QBO transaction, delete from QBO and reconciliation records
      if (type === 'qbo' && rec && rec.id) {
        try {
          const companyId = rec.companyId || (rec as any).company.id;

          // Get Firebase user
          const firebaseUser = await getCurrentUser();
          if (!firebaseUser) {
            throw new Error('User not authenticated');
          }

          // Ensure WebSocket connection is active before starting the operation
          const socketConnected = await ensureSocketConnected();

          if (!socketConnected) {
            notification.warning({
              key: 'socket-warning',
              message: 'WebSocket Not Connected',
              description: 'Unable to establish real-time connection. You will not see live progress updates.',
              duration: 5
            });
          }

          // Start the deletion process with the API
          await api.post(`reconciliation/${rec.id}/delete-transactions`, {
            transactions,
            companyId,
            firebaseUid: firebaseUser.uid
          });

          // Wait for first WebSocket updates before starting the fallback timer
          setTimeout(() => {
            // Only set up the fallback timer if operation is still in progress
            if (deletionInProgressRef.current) {
              let fallbackTimerCount = 0;

              // Store the interval in the ref so it can be cleared from anywhere
              fallbackTimerRef.current = setInterval(() => {
                const now = Date.now();
                const timeSinceLastUpdate = now - lastProgressUpdateTime;

                // If operation is no longer in progress, clear the interval
                if (!deletionInProgressRef.current) {
                  if (fallbackTimerRef.current) {
                    clearInterval(fallbackTimerRef.current);
                    fallbackTimerRef.current = null;
                  }
                  return;
                }

                console.log(`Checking progress: ${timeSinceLastUpdate}ms since last update`);

                // Only show notifications if we haven't received updates in a while
                fallbackTimerCount++;

                // After first interval (15s) with no updates, show "in progress" notification
                if (fallbackTimerCount === 1 && timeSinceLastUpdate > 15000) {
                  notification.info({
                    key: 'deleting-background',
                    message: 'Deletion in Progress',
                    description: 'The deletion process is running in the background. This may take a few moments to complete.',
                    duration: 0
                  });
                }

                // After second interval (30s) with no updates, show refresh suggestion
                if (fallbackTimerCount >= 2 && timeSinceLastUpdate > 30000) {
                  notification.info({
                    key: 'refresh-suggestion',
                    message: 'No Recent Updates',
                    description: 'No progress updates received in the last 30 seconds. The process may still be running in the background.',
                    duration: 8,
                    btn: <Button type="primary" size="small" onClick={onRefresh}>Refresh Data</Button>
                  });

                  // Clear interval after showing refresh suggestion
                  if (fallbackTimerRef.current) {
                    clearInterval(fallbackTimerRef.current);
                    fallbackTimerRef.current = null;
                  }
                }
              }, 15000);  // Check every 15 seconds
            }
          }, 5000); // Wait 5 seconds before starting the fallback timer
        } catch (error: any) {
          console.error('Error deleting transactions:', error);
          deletionInProgressRef.current = false;

          // Clean up fallback timer on error
          if (fallbackTimerRef.current) {
            clearInterval(fallbackTimerRef.current);
            fallbackTimerRef.current = null;
          }

          notification.error({
            key: 'deleting',
            message: 'Error',
            description: typeof error === 'string' ? error : error.message || 'An unexpected error occurred',
            duration: 4
          });
          setDeletingTxs(false);
        }
      } else {
        // For non-QBO transactions, just show success
        deletionInProgressRef.current = false;
        notification.success({
          key: 'deleting',
          message: 'Success',
          description: `Successfully removed ${selected.length} transaction(s) from view`,
          duration: 4
        });
        setDeletingTxs(false);
      }

      // Clear selection
      setSetSelectedTx([]);
    } catch (error) {
      console.error('Error in onDelete:', error);
      deletionInProgressRef.current = false;

      // Clean up fallback timer on error
      if (fallbackTimerRef.current) {
        clearInterval(fallbackTimerRef.current);
        fallbackTimerRef.current = null;
      }

      notification.error({
        key: 'deleting',
        message: 'Error',
        description: 'An unexpected error occurred',
        duration: 4
      });
      setDeletingTxs(false);
    }
  };

  const onRematch = () => {
    setTimeout(() => {
      btnRef?.current?.click()
    }, 250)
  }

  const onRefresh = () => {
    const companyId = rec.companyId || (rec as any).company.id
    onLoading(true)
    return refreshTxs(companyId, rec.accountId, rec.from, rec.to)
        .then((txs) => matcher.refreshQbo(txs))
        .catch((e) => notification.error(getErrorMsg(e)))
        .finally(() => onLoading(false))
  }

  // copied over from source
  const onAddTx = () => {
    matcher.selectBank([])
    onRefresh().then(() => onRematch())
  }

  // Check if a transaction is matched
  const isMatchedTx = (tx: ExtendedTx) => {
    // Always disable if transaction is matched
    if (tx.matchedTo?.length > 0 || tx.forceMatch === true) {
      return true
    }
    // Disable unmatched transactions when viewing Matched or All
    return filter === 'Matched' || filter === 'All';

  }

  return (
      <Flex vertical ref={ref}>
        <Divider orientation="left">
          <Text>{title}</Text> <Text type="secondary">{processedData.length} total</Text>
          <Text type="secondary"> / {cleared.length} matched</Text>
          {!!selected.length && <Text type="secondary"> / {selected.length} selected </Text>}
        </Divider>

        {/* Display deletion progress when active */}
        {/*{deletingTxs && (*/}
        {/*    <Flex vertical style={{ marginBottom: 16 }}>*/}
        {/*      <Text strong>*/}
        {/*        Deleting transactions: {completedCount} of {totalToDelete} completed*/}
        {/*        {!socketConnected && deletingTxs && (*/}
        {/*            <Text type="warning"> (WebSocket disconnected - progress updates paused)</Text>*/}
        {/*        )}*/}
        {/*      </Text>*/}
        {/*      <Progress*/}
        {/*          percent={Math.round((completedCount / totalToDelete) * 100)}*/}
        {/*          status={socketConnected ? "active" : "exception"}*/}
        {/*          style={{ marginTop: 8 }}*/}
        {/*      />*/}
        {/*    </Flex>*/}
        {/*)}*/}

        <Flex gap={10} vertical>
          <Flex gap={10} justify="space-between">
            <Segmented
                size="small"
                options={['Matched', 'Unmatched', 'All']}
                value={filter}
                onChange={(v: any) => setFilter(v)}
            />
            {type === 'bank' && !!selected.length && isAllowed && (
                <Flex gap={5}>
                  {!flags?.disable_chrome_plugin?.enabled && (
                      <Button type="link" size="small" onClick={() => setUploadModal(true)}>
                        Add to Quickbooks
                      </Button>
                  )}
                  <Button type="link" size="small" onClick={matchSelected}>
                    Match
                  </Button>
                </Flex>
            )}
            {type === 'bank' && !selected.length && isAllowed && (
                <Flex gap={5}>
                  <Button type="text" size="small" onClick={() => matcher.unmatchAll()}>
                    Unmatch all
                  </Button>
                  <Button type="text" size="small" onClick={() => matcher.matchAll()} ref={btnRef}>
                    Rematch
                  </Button>
                </Flex>
            )}
            {type === 'qbo' && isAllowed && (
                <Flex gap={5}>
                  <Tooltip
                      placement="bottom"
                      title={
                        isAddToQuickbooksDisabled()
                            ? 'This feature is currently disabled'
                            : !selected.length
                                ? 'In order to use this feature, please select at least one transaction below to delete.'
                                : ''
                      }
                  >
                    <Button
                        type="link"
                        size="small"
                        danger
                        onClick={onDelete}
                        disabled={!selected.length || isAddToQuickbooksDisabled() || deletingTxs}
                        loading={deletingTxs}
                    >
                      {deletingTxs ? `Deleting (${completedCount}/${totalToDelete})` : 'Delete'}
                    </Button>
                  </Tooltip>
                  <Button size="small" type="text" onClick={onRefresh}>
                    Refresh
                  </Button>
                </Flex>
            )}
          </Flex>
          <Table<ExtendedTx>
              virtual
              rowKey="_key"
              size="small"
              columns={columns}
              dataSource={dataSource}
              scroll={{ y: minHeight }}
              style={tableStyle}
              pagination={false}
              loading={loading}
              rowSelection={{
                type: 'checkbox',
                columnWidth: 40,
                selectedRowKeys: getSelectedKeys(),
                onChange: handleSelectionChange,
                getCheckboxProps: (record) => ({
                  disabled: isMatchedTx(record) || deletingTxs,
                }),
              }}
              onRow={(record) => ({
                onMouseEnter: () => setHoveredRowId(record._id), 
                onMouseLeave: () => setHoveredRowId(null)
              })}
          />
        </Flex>
        <AddToQBO
            open={uploadModal}
            onClose={(isOk) => {
              if (isOk) onAddTx()
              setUploadModal(false)
            }}
            accountId={rec.accountId}
            list={dataSource.filter((v) => v.selected)}
            disabled={isAddToQuickbooksDisabled()}
        />
        <MatchModal
            type={type}
            open={matchModal}
            list={selectedTx}
            matcher={matcher}
            onClose={() => setMatchModal(false)}
        />
      </Flex>
  )
}

function refreshTxs(companyId: number, accountId: string, from: string, to: string) {
  const before = prevMonthStart(from)
  const params = { accountId, companyId, from: before, to }
  return api
      .get('/accounting/transactions', { params })
      .then((r) => r.data)
      .then((r) => reportToTsx(r, accountId, true))
}

function useWindowHeight() {
  const [size, setSize] = useState(0)
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerHeight)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

type FilterTx = 'Matched' | 'Unmatched' | 'All'

const tableStyle: React.CSSProperties = {
  borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
}