import { useState } from 'react'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {App, Checkbox, Flex} from 'antd'
import { Button, ButtonProps, Card, Col, Divider, Form, Input, Row, Typography } from 'antd'
import { GoogleOutlined } from '@ant-design/icons'

import { AuthPage } from './components/AuthPage'
import { AuthWelcome } from './components/Welcome'
import { AppFooter } from 'app/AppFooter'

import { rules } from 'utils/form'
import { useAuthUser } from 'hooks/useAuth'
import { useAuthStore } from 'store/auth.store'
import { getErrorMsg } from 'utils/geterror'
import { trackEvents } from 'utils/analytics'
import { signInWithGoogle } from 'utils/firebase'
import { signUpWithPassword } from 'utils/firebase'

const { Text } = Typography

export function SignUpPage() {
  const { authUser } = useAuthUser()
  const {notification } = App.useApp();

  const isAuth = useAuthStore((state) => !!state.uid)
  const [loading, setLoading] = useState(false)

  const [searchParams] = useSearchParams()
  const invitationTokenParam = searchParams.get('token')
  const emailParam = searchParams.get('email') || ''

  const signUp = async (form: any) => {
    setLoading(true)
    try {
      const { user } = await signUpWithPassword(form.email, form.password)
      if (!user?.email) throw new Error('Email is required')
      trackEvents.user.signUp()
      const phoneNumber = form.phoneNumber ? String(form.phoneNumber) : null;
      const body = {
        email: user.email,
        fullName: form.fullName as string,
        token: await user.getIdToken(),
        googleSignIn: false,
        invitationToken: invitationTokenParam,
        phoneNumber: phoneNumber,
      }
      await authUser(body)
    } catch (error) {
      notification.error(getErrorMsg(error))
    } finally {
      setLoading(false)
    }
  }

  const googleSignUp = async () => {
    setLoading(true)
    try {
      const { user } = await signInWithGoogle()
      if (!user?.email) throw new Error('Email is required')
      trackEvents.user.signUpGoogle()
      const body = {
        email: user.email,
        fullName: user.displayName,
        token: await user.getIdToken(),
        googleSignIn: true,
        invitationToken: invitationTokenParam,
      }
      await authUser(body)
    } catch (error) {
      notification.error(getErrorMsg(error))
    } finally {
      setLoading(false)
    }
  }

  if (isAuth) return <Navigate to="/" />

  const btnProps: ButtonProps = {
    loading,
    block: true,
    type: 'primary',
  }

  return (
    <AuthPage>
      <Helmet>
        <title>Equility - Sign Up</title>
      </Helmet>
      <Row style={contentStyle}>
        <Col span={12}>
          <AuthWelcome />
        </Col>
        <Col span={12}>
          <Card title="Sign Up">
            <Button {...btnProps} onClick={googleSignUp} icon={<GoogleOutlined />}>
              Sign Up with Google
            </Button>
            <Divider>or</Divider>
            <Form onFinish={signUp} labelCol={{ span: 8 }} initialValues={{ email: emailParam }}>
              <Form.Item name="fullName" rules={rules.fullName}>
                <Input placeholder="Full Name" autoComplete="off" />
              </Form.Item>
              <Form.Item name="email" rules={rules.email}>
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item name="password" rules={rules.password}>
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item name="phoneNumber" rules={rules.phoneNumber}>
                <Input placeholder="Phone Number" />
              </Form.Item>
              <Form.Item name="terms" valuePropName="checked" rules={rules.terms}>
                <Typography.Text type="secondary">
                  <Checkbox>
                    <span>I agree to the</span>
                    &nbsp;
                    <a href="https://www.equilityhq.com/privacy-policy" target="_blank" rel="noreferrer">
                      Privacy Policy
                    </a>
                    &nbsp;
                    <span>and</span>
                    &nbsp;
                    <a href="https://www.equilityhq.com/terms-of-use" target="_blank" rel="noreferrer">
                      Terms of Use
                    </a>
                  </Checkbox>
                </Typography.Text>
              </Form.Item>
              <Form.Item>
                <Button {...btnProps} htmlType="submit">
                  Sign Up
                </Button>
              </Form.Item>
            </Form>
            <Flex gap={4}>
              <Text>Already customer?</Text>
              <Link to="/signin">Sign In</Link>
            </Flex>
          </Card>
        </Col>
        <Col span={24}>
          <AppFooter />
        </Col>
      </Row>
    </AuthPage>
  )
}

const contentStyle = {
  width: 800,
}
