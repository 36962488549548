import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { Alert, Button, Card, Col, DatePicker, Divider, Flex, Result, Spin, Tooltip, notification, App } from 'antd'
import { Form, Typography, Row, Slider } from 'antd'
import { SettingOutlined } from '@ant-design/icons'

import { SelectAccount } from 'components/SelectAccount'
import { HelpTooltip } from 'components/HelpTooltip'
import { InputCurrency } from 'components/InputCurrency'
import { AlertPlugin } from 'components/AlertPlugin'
import { CsvUpload } from './components/CsvUpload'
import { PlaidList } from './components/PlaidList'
import VideoTooltip from 'components/VideoTooltip'

import { useRole } from 'hooks/useRole'
import { useAccounts } from 'hooks/useAccounts'
import { useCompany } from 'hooks/useCompany'
import { useReconcile } from 'hooks/useReconcile'
import { useBankTransactions } from 'hooks/useBankTransactions'
import { useTransactions } from 'hooks/useTransactions'
import { formatDate } from 'utils/dates'
import { toCurrency } from 'utils/numbers'
import { getErrorMsg } from 'utils/geterror'
import { csvBoxToTxs, pladToTxs, reportToTsx } from 'utils/tx'
import { ConnectionType } from 'types/company.types'
import { RoleType } from 'types/user.types'
import type { CsvBoxTx, ITx } from 'types/tx.types'

import { useFlags } from 'flagsmith/react'
import dayjs, { Dayjs } from 'dayjs'
import { AddAccountModal } from 'components/AddAccountModal'

const { Text } = Typography
const { RangePicker } = DatePicker

const onFinishFailed = ({ errorFields }: any) => {
  if (errorFields.some((field: any) => field.name.includes('daterange'))) {
    notification.warning({
      message: 'Warning',
      description: 'Please select a date range.',
    })
  }
}

const SourceDataHeader = () => (
  <Flex align="center" gap={5}>
    <Text>Source Data Import</Text>
    <HelpTooltip text="Choose how to import your source data transactions below by linking an account or uploading a CSV document" />
  </Flex>
)

export function RecRun() {
  const { notification } = App.useApp()
  const navigate = useNavigate()

  const flags = useFlags(['disable_chrome_plugin'])

  const [searchParams, setSearchParams] = useSearchParams()
  const search = Object.fromEntries(searchParams) as any

  const [form] = Form.useForm<IFormData>()
  const [bankTxs, setBankTsx] = useState<ITx[] | null>(null)
  const [plaidAccount, setPlaidAccount] = useState<string>()

  const { accounts } = useAccounts()
  const { company, isLoading, revalidate } = useCompany()
  const { isAllowed } = useRole(RoleType.Member)

  const accountId = Form.useWatch<string>('accountId', form)
  const daterange = Form.useWatch('daterange', form)
  const beginBalance = Form.useWatch('beginBalance', form)
  const endBalance = Form.useWatch('endBalance', form)

  const companyId = company?.id || 0
  const to = daterange ? formatDate(daterange[1], 'YYYY-MM-DD') : '2000-01-01'
  const from = daterange ? formatDate(daterange[0], 'YYYY-MM-DD') : '2000-01-02'
  // const before = prevMonthStart(from) // to get the previous month transactions
  // hardcoded the from date so we can pull all quickbook transactions. 
  const txs = useTransactions({ accountId, companyId, from: search.from || '2000-01-01', to })

  const reconcile = useReconcile({ id: null })
  const plaidData = useBankTransactions({ id: plaidAccount || null, from, to })

  const qbo = (company?.connections || []).find((i) => i.type.id === ConnectionType.QBO)
  const isConnected = qbo?.status === 1

  const currency = company?.settings?.currency || ''
  const isLiability = accounts?.find((a) => a.platformId === accountId)?.isLiability

  const isDisabled = !daterange || !accountId || !accounts?.length || !company || !isConnected

  const [isAddAccountModalVisible, setIsAddAccountModalVisible] = useState(false)

  const [refreshPlaidKey, setRefreshPlaidKey] = useState(0)

  useEffect(() => {
    if (plaidData.data && !bankTxs) setBankTsx(pladToTxs(plaidData.data, !isLiability))
    if (plaidData.error) {
      notification.error(getErrorMsg(plaidData.error.response.data))
    }
  }, [plaidData])

  useEffect(() => {
    if (!search.sync) return
    if (search.accountId) form.setFieldsValue({ accountId: search.accountId } as any)
    setSearchParams({})
    localStorage.removeItem('qbosync')
    notification.success({ message: 'Success', description: 'QuickBooks Online data has been updated' })
  }, [search])

  useEffect(() => {
    if (search.sync) return
    if (!search.accountId) return
    if (!accounts?.length || !company) return
    if (form.isFieldsTouched()) return

    const start = dayjs(search.from)
    const end = dayjs(search.to)
    if (start.isValid() && end.isValid()) {
      form.setFieldsValue({
        accountId: search.accountId,
        account: search.accountId,
        beginBalance: parseFloat(search.startingBalance || '0'),
        endBalance: parseFloat(search.endingBalance || '0'),
        // daterange: [start, end],
      })
    } else {
      console.warn('Invalid date received from plugin')
    }
  }, [search, accounts, company])

  useEffect(() => {
    if (!isAddAccountModalVisible) {
      revalidate()
    }
  }, [isAddAccountModalVisible])

  useEffect(() => {}, [refreshPlaidKey])

  const onCSVChange = (data: CsvBoxTx[]) => {
    if (daterange && daterange[0] && daterange[1]) {
      const [startDate, endDate] = daterange

      const filteredData = data.filter((tx) => {
        const txDate = dayjs(tx.Date)
        return txDate.isSameOrAfter(dayjs(startDate).startOf('day')) && txDate.isBefore(dayjs(endDate).endOf('day'))
      })

      const txs = csvBoxToTxs(filteredData)
      setBankTsx(txs)
    } else {
      const txs = csvBoxToTxs(data)
      setBankTsx(txs)
    }
  }

  const onSubmit = (values: IFormData) => {
    if (txs.error) return notification.error(getErrorMsg(txs.error))
    if (!bankTxs) return notification.warning({ message: 'Warning', description: 'No bank transactions' })
    if (!txs.data) return notification.warning({ message: 'Warning', description: 'No account transactions' })
    if (!isConnected) return notification.error({ message: 'Error', description: 'QuickBooks Online is not connected' })
    if (!daterange) return notification.warning({ message: 'Warning', description: 'Please select a date range' })

    const balanceDiff = (values.endBalance || 0) - (values.beginBalance || 0)
    const bankBalance = bankTxs.reduce((a, b) => a + b.amount, 0)

    // Use a fixed epsilon value for comparing float values
    const isWithinTolerance = Math.abs(Math.abs(balanceDiff) - Math.abs(bankBalance)) < 0.01

    if (!isWithinTolerance) {
      const message = plaidAccount
        ? bankTestMsg(bankBalance, balanceDiff, currency)
        : cvsTestMsg(bankBalance, balanceDiff, currency)
      return notification.warning({ message: 'Warning', description: message })
    }

    const qboTxs = reportToTsx(txs.data, accountId, true)

    const rec = {
      ...values,
      companyId: company?.id,
      from: formatDate(values.daterange[0], 'YYYY-MM-DD'),
      to: formatDate(values.daterange[1], 'YYYY-MM-DD'),
      temp_data: { bank: bankTxs || [], qbo: qboTxs || [] },
      starting_balance: values.beginBalance,
      ending_balance: values.endBalance,
      result: [],
    }

    reconcile
      .create(rec)
      .then((r) => navigate(`./result/${r.id}`, { state: r }))
      .catch((err) => notification.error(getErrorMsg(err)))
  }

  const onCancel = () => {
    setPlaidAccount(undefined)
    setBankTsx(null)
  }

  const onClick = () => setIsAddAccountModalVisible(true)

  if (!isAllowed) return null

  const disableChrome = !flags?.disable_chrome_plugin?.enabled

  const isValidDateRange =
    daterange && Array.isArray(daterange) && daterange.every((date) => dayjs(date, 'YYYY-MM-DD', true).isValid())

  const isParamsIncomplete =
    !accountId || !daterange || !isValidDateRange || beginBalance === undefined || endBalance === undefined

  return (
    <Flex vertical>
      {!isConnected && !isLoading && disableChrome && (
        <Alert
          message="Quickbooks Integration"
          description={
            <Text>
              <a href={`/company/${company?.id}/settings/accounting`}>Connect your accounting platform</a> to use the
              features
            </Text>
          }
          type="warning"
          showIcon
        />
      )}
      <AlertPlugin />
      <Form layout="vertical" form={form} onFinish={onSubmit} onFinishFailed={onFinishFailed}>
        <Row gutter={24}>
          <Col span={16}>
            <Divider orientation="left">Reconciliation Parameters</Divider>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label={<DaterangeLabel />} name="daterange" required rules={rules}>
                  <RangePicker style={inputStyle} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={<GLAccountLabel />} name="accountId" required rules={rules}>
                  <SelectAccount accounts={accounts} onChange={() => {}} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Beginning Balance" name="beginBalance" required rules={rules}>
                  <InputCurrency style={inputStyle} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Ending Balance" name="endBalance" required rules={rules}>
                  <InputCurrency style={inputStyle} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Divider orientation="left">Auto matching</Divider>
            <Form.Item label={<DayrangeLabel />} name="dayrange" initialValue={7}>
              <Slider min={1} max={10} />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">
          <SourceDataHeader />
        </Divider>
        {!!bankTxs && (
          <Result
            icon={false}
            status="success"
            title="Bank Transactions"
            subTitle={bankTxs?.length + ' transactions retrieved from bank account'}
            extra={<Button onClick={onCancel}>Cancel</Button>}
          />
        )}
        {!bankTxs && (
          <Row gutter={24}>
            <Col span={11}>
              <Card
                size="small"
                title="Linked Accounts"
                extra={
                  <Button type="text" size="small" onClick={onClick}>
                    <SettingOutlined /> Add Account
                  </Button>
                }
              >
                <Spin spinning={plaidData.isLoading}>
                  <PlaidList key={refreshPlaidKey} onSelect={setPlaidAccount} isDateNotSelected={isParamsIncomplete} />
                </Spin>

                <AddAccountModal
                  open={isAddAccountModalVisible}
                  onClose={() => {
                    setIsAddAccountModalVisible(false)
                    setRefreshPlaidKey((prevKey) => prevKey + 1)
                  }}
                />
              </Card>
            </Col>
            <Col span={2}>
              <Divider>or</Divider>
            </Col>
            <Col span={11}>
              <Card
                size="small"
                title="Upload CSV Document"
                extra={
                  <VideoTooltip
                    videoUrl="https://assets.equilityhq.com/documentation-videos/CSV-Upload-Instructions.mp4"
                    buttonText="View CSV Upload Tutorial"
                  />
                }
              >
                <CsvUpload onChange={onCSVChange} isLiability={isLiability} isDateNotSelected={daterange == null} />
              </Card>
            </Col>
          </Row>
        )}
        <Divider />
        <Flex justify="end">
          <Tooltip title={isDisabled ? 'All fields must be completed to proceed with the reconciliation.' : ''}>
            <Button
              type="primary"
              style={runStyle}
              htmlType="submit"
              loading={reconcile.isLoading}
              disabled={isDisabled}
            >
              Run
            </Button>
          </Tooltip>
        </Flex>
      </Form>
    </Flex>
  )
}

const rules = [{ required: true }]

const DaterangeLabel = () => (
  <Flex align="center" gap={5}>
    <Text>Date Range</Text>
    <HelpTooltip text="All uncleared transactions that are within one month of the 'Start Date' will be imported into Equility. Please amend the start date, if you have uncleared transactions that are older than this." />
  </Flex>
)

const GLAccountLabel = () => (
  <Flex align="center" gap={5}>
    <Text>General Ledger Account</Text>
    <HelpTooltip text="Choose the general ledger account that you would like to reconcile" />
  </Flex>
)

const DayrangeLabel = () => (
  <Flex align="center" gap={5}>
    <Text>Specify number of days</Text>
    <HelpTooltip text="Number Of Days Before And After Transaction Date To Look For Potential Matches." />
  </Flex>
)

const bankTestMsg = (bankBalance: number, balanceDiff: number, currency: string) =>
  `The transactions within bank data total ${toCurrency(bankBalance, currency)}, ` +
  'which does not match the different between the `Beginning Balance`' +
  `and 'Ending Balance' of ${toCurrency(balanceDiff, currency)}.`

const cvsTestMsg = (bankBalance: number, balanceDiff: number, currency: string) =>
  `The summation of the transactions uploaded via CSV totals ${toCurrency(balanceDiff, currency)}, ` +
  `which does not match the difference between the 'Beginning Balance' and 'Ending Balance' of ${toCurrency(bankBalance, currency)} ` +
  `that was entered within the reconciliation parameters. Please upload a new CSV file with a summation that equals ${toCurrency(bankBalance, currency)} in order to continue.`

const runStyle: React.CSSProperties = {
  width: 200,
}

const inputStyle: React.CSSProperties = {
  width: '100%',
}

interface IFormData {
  accountId: string
  account: string
  beginBalance: number
  endBalance: number
  dayrange: number
  daterange: [Dayjs, Dayjs]
}
