import React, { useEffect, useState } from 'react'
import {Divider, Flex, Spin, Typography, App} from 'antd'
import { PlaidEmbeddedLink } from 'react-plaid-link'

import { useRole } from 'hooks/useRole'
import { usePlaidConnection, usePlaidLinkToken } from 'hooks/usePlaid'
import { useCompany } from 'hooks/useCompany'
import { ConnectionType } from 'types/company.types'
import { RoleType } from 'types/user.types'
import { PlaidConnectionResponse } from "../../../types/plaid.type"
import { AccountMatching } from "../../../components/AccountMatching"

const { Text } = Typography

export function Banks() {
  const {notification } = App.useApp();
  const { token: tokenData } = usePlaidLinkToken()
  const { company, revalidate } = useCompany(false)
  const { isAllowed } = useRole(RoleType.Member)
  const { loading, onConnect, onDelete } = usePlaidConnection(revalidate)

  const [connections, setConnections] = useState<PlaidConnectionResponse[]>([])

  useEffect(() => {
    const plaidItems = (company?.connections || [])
        .filter((i) => i.type.id === ConnectionType.Plaid) as PlaidConnectionResponse[]
    setConnections(plaidItems)
  }, [company?.connections])

  const handleConnect = async (token: string) => {
    if (!company?.id) return;
    try {
      await onConnect(token, company.id, false);
      await revalidate();
    } catch (err) {
      console.error('Connection error:', err);
      notification.error({
        message: 'Connection Error',
        description: 'Failed to establish Plaid connection'
      });
    }
  };

  const onExit = (error: any, metadata: any) => {
    console.info('Exit:', error, metadata)
    if (error) {
      notification.error({ message: 'Error', description: error.display_message })
    }
  }

  const handleDelete = (connection: PlaidConnectionResponse) => {
    if (!company?.id) return;
    if (!isAllowed) {
      notification.error({
        message: 'Error',
        description: 'You are not allowed to perform this action'
      });
      return;
    }
    onDelete(connection, company.id);
  };

  const token = tokenData?.linkToken
  if (!token) return null

  return (
      <Flex vertical>
        {isAllowed && (
            <>
              <Divider orientation="left">Connect your banks and cards</Divider>
              <PlaidEmbeddedLink
                  token={token}
                  style={plaidStyle}
                  onSuccess={handleConnect}
                  onEvent={console.log}
                  onExit={onExit}
                  key={token}
              />
              <Text>
                <small>* Please remember that we cannot and will not make any modifications to your data. Our integrations are needed to pull financial data and our access can be revoked at any time.</small>
              </Text>
            </>
        )}
        {!!connections.length && (
            <>
              <Divider orientation="left">Connections</Divider>
              <Spin spinning={loading}>
                <AccountMatching
                    connections={connections}
                    onDelete={handleDelete}
                    loading={loading}
                    companyId={company?.id}
                    isAllowed={isAllowed}
                />
              </Spin>
            </>
        )}
      </Flex>
  )
}

const plaidStyle: React.CSSProperties = {
  height: 350
}