import {Button, Col, Divider, Flex, Row, Typography, App} from 'antd'

import { MatchAccounts } from 'components/MatchAccounts'
import { CardIntegration } from 'components/CardIntegration'
import { SelectEntity } from 'components/SelectEntity'

import {useAccountsById, useSyncAccounts} from 'hooks/useAccounts'
import { useNewCompany } from '.'

import { api } from 'utils/axios'
import { useCompany } from 'hooks/useCompany'
import { getErrorMsg } from 'utils/geterror'
import { connectQBO } from 'utils/qbolink'

import { ConnectionType } from 'types/company.types'

import qbo from 'assets/quickbooks.png'
import { useFlags } from 'flagsmith/react'

const { Text } = Typography

interface IProps {
  onNext: () => void
}

export function AccountingStep(props: IProps) {
  const {notification } = App.useApp();
  const { create } = useCompany()
  const flags = useFlags(['disable_chrome_plugin'])
  const company = useNewCompany((s) => s.company)
  const setCompany = useNewCompany((s) => s.setCompany)

  const connection = company?.connections?.find((v) => v.type.id === ConnectionType.QBO)
  const isConnected = connection?.status === 1

  const accounts = useAccountsById(isConnected ? company?.id : undefined)
  const uncat = company?.settings?.uncat?.selected || []
  const { syncAccounts, isLoading: isSyncing } = useSyncAccounts(company?.id as number);

  const onConnect = async () => {
    if (isConnected) return

    try {
      let c = company
      if (!c?.id) {
        c = await create()
        setCompany(c)
      }

      if (!c.id) throw new Error('Error creating company')
      await connectQBO(c.id)

      notification.success({ message: 'Connected to Quickbooks' })

      const n = await api.get(`companies/${c.id}`)
      setCompany(n.data)
    } catch (error) {
      console.log('error', error)
      notification.error(getErrorMsg(error))
    }
  }

  const onUncatChange = (value: any) => {
    const v = { selected: value?.uncat || [] }
    const settings = { ...company?.settings, uncat: v }
    api
      .put(`companies/${company?.id}`, { settings })
      .then((res) => setCompany(res.data))
      .then(() => notification.success({ message: 'Settings updated' }))
      .catch((error) => notification.error(getErrorMsg(error)))
  }

  const onEntityChange = (entity: any) => {
    api
      .put(`companies/${company?.id}`, { entity })
      .then((res) => setCompany(res.data))
      .then(() => notification.success({ message: 'Legal entity type' }))
      .catch((error) => notification.error(getErrorMsg(error)))
  }

  const handleComplete = async () => {
    if (!isConnected) {
      notification.error({ message: 'QuickBooks is not connected.' });
      return;
    }

    try {
      // Optionally show a notification or loading indicator.
      notification.info({ message: 'Syncing accounts...', duration: 1.5 });
      // Await the syncAccounts call
      await syncAccounts();
      // Once sync completes, proceed to the next step.
      props.onNext();
    } catch (error) {
      console.error('Sync accounts error:', error);
      notification.error({ message: 'Failed to sync accounts.', description: getErrorMsg(error).description });
    }
  }

  return (
    <Flex vertical>
      <Divider orientation="left">Platform</Divider>
      <Row gutter={16}>
        <Col span={6}>
          <CardIntegration
            onClick={onConnect}
            item={{ image: qbo, title: 'Quickbooks', disabled:  flags?.disable_chrome_plugin?.enabled, connected: isConnected }}
          />
        </Col>
      </Row>
      {isConnected && (
        <>
          <Divider orientation="left">Business info</Divider>
          <Text>Company: {company?.name}</Text>
        </>
      )}
      <Divider orientation="left">Settings</Divider>
      <Flex vertical gap={10}>
        {isConnected && (
          <Flex align="center" gap={20}>
            <Text>Legal entity type:</Text>
            <SelectEntity placeholder="Select" onChange={onEntityChange} style={{ width: 500 }} />
          </Flex>
        )}
        {isConnected && (
          <Flex align="center" gap={20}>
            <Text>Check for Uncategorized Transactions in the General Ledger account(s):</Text>
            <MatchAccounts
              value={{ uncat }}
              onChange={onUncatChange}
              accounts={accounts.data || []}
              loading={accounts.isLoading}
              options={[{ id: 'uncat', name: 'Account(s)' }]}
              placeholder="Select Account(s)"
              multiple
              hidelabel
            />
          </Flex>
        )}
        {!isConnected && (
          <Flex>
            <Text type="secondary">Connect to accounting platform to get started</Text>
          </Flex>
        )}
      </Flex>
      <Divider />
      <Flex>
        <Button type="primary" onClick={handleComplete} disabled={!isConnected || isSyncing}>
          {isSyncing ? 'Syncing...' : 'Next'}
        </Button>
      </Flex>
    </Flex>
  )
}
