
import {Button, Divider, Flex, List, Modal, Tag, Typography, App,Select} from 'antd'
import { CardIntegration } from 'components/CardIntegration'
import { MatchAccounts } from 'components/MatchAccounts'
import { api } from 'utils/axios'
import { useRole } from 'hooks/useRole'
import { useCompany } from 'hooks/useCompany'
import { useAccounts } from 'hooks/useAccounts'
import { useCompanyStore } from 'store/company.store'
import { ConnectionType } from 'types/company.types'
import { IConnectionItem, ConnectionAction } from 'types/connection.types'
import { RoleType } from 'types/user.types'
import { useState, useEffect, useRef } from 'react'
import { useFlags } from 'flagsmith/react'
import ShopifyConnectModal from '../../../components/ShopifyConnectModal'
import { useConnections } from '../../../hooks/useConnections'
import { useLocation } from 'react-router-dom';

const { Text } = Typography;

export function Integrations() {
    const {notification } = App.useApp();
  const companyId = useCompanyStore((state) => state.id);
  const { company, revalidate } = useCompany();
  const { accounts, isLoading } = useAccounts();
  const { isAllowed } = useRole(RoleType.Member);
  const [loading, setLoading] = useState(false);
  const [activeConnection] = useState<number | null>(null);
  const [selectedStore, setSelectedStore] = useState<number | null>(null);
  const [shopifyModalVisible, setShopifyModalVisible] = useState(false);
  const [setupModalVisible, setSetupModalVisible] = useState(false);
  const [localMatched, setLocalMatched] = useState<{ [key: string]: Matched }>({});

  const flags = useFlags(['hide_shopify']);
  const {
    connections: shopifyConnections,
    hasActiveConnections,
    hasSyncingConnections,
    activeConnectionsCount,
  } = useConnections(ConnectionType.Shopify);

  // Set first store as selected when connections load
  useEffect(() => {
    if (shopifyConnections.length > 0 && !selectedStore) {
      setSelectedStore(shopifyConnections[0].keyId);
    }
  }, [shopifyConnections, selectedStore]);

  const items: IConnectionItem[] = [
    {
      image: require('assets/shopify.png'),
      title: 'Shopify',
      disabled: flags.hide_shopify.enabled,
      connected: hasActiveConnections,
      status: hasSyncingConnections ? 'syncing' : (hasActiveConnections ? 'connected' : 'not connected'),
    },
    { image: require('assets/amazon.png'), title: 'Amazon', disabled: true },
    { image: require('assets/walmart.png'), title: 'Walmart', disabled: true },
    { image: require('assets/etsy.png'), title: 'Etsy', disabled: true },
    { image: require('assets/squarespace.png'), title: 'Squarespace', disabled: true },
    { image: require('assets/ebay.png'), title: 'Ebay', disabled: true },
  ];

  // A ref to track pending changes
  const pendingChangesRef = useRef<{
    connectionId: number;
    value: Matched;
  } | null>(null);

  // (Optional) You can still use location if you need it elsewhere.
  const location = useLocation();

  // Create a function to handle saving changes
  const saveChanges = async (changes: { connectionId: number; value: Matched } | null) => {
    if (!changes || !isAllowed || !company?.id) return;

    try {
      await api.patch(
          `companies/${company.id}/connection/${changes.connectionId}`,
          {
            settings: {
              shopify: {
                matched: changes.value,
              },
            },
          }
      );
      pendingChangesRef.current = null;
    } catch (error) {
      console.error('Failed to save changes:', error);
      // Optionally show an error notification
      notification.error({
        message: 'Error',
        description: 'Failed to save account matching changes'
      });
    }
  };

  // Effect to handle route changes and component unmount
  useEffect(() => {
    // Save immediately when location changes
    if (pendingChangesRef.current) {
      saveChanges(pendingChangesRef.current);
    }

    // Cleanup function for unmount
    return () => {
      if (pendingChangesRef.current) {
        saveChanges(pendingChangesRef.current);
      }
    };
  }, [location, company?.id, isAllowed]); // Add location to dependencies


  const connectShopify = async (storeUrl: string) => {
    if (!isAllowed) {
      notification.error({ message: 'Error', description: 'You are not allowed to perform this action' });
      return;
    }
    if (!storeUrl) {
      notification.error({ message: 'Error', description: 'Please enter your Shopify store URL' });
      return;
    }

    const storeExists = shopifyConnections.some(
        conn => conn.connectionInfo?.store?.domain === storeUrl ||
            conn.connectionInfo?.realmId === storeUrl,
    );

    if (storeExists) {
      notification.error({ message: 'Error', description: 'This Shopify store is already connected' });
      return;
    }

    setLoading(true);
    try {
      const response = await api.post(`companies/${company?.id || companyId}/connection/link`, {
        provider: 'Shopify',
        realmId: storeUrl,
      });

      if (!response.data?.url) {
        throw new Error('No authentication URL received');
      }

      const authWindow = window.open(response.data.url, '_blank', 'width=600,height=600');
      if (!authWindow) {
        throw new Error('Unable to open authentication window. Please check your pop-up blocker settings.');
      }

      const pollTimer = setInterval(async () => {
        if (authWindow.closed) {
          clearInterval(pollTimer);
          await revalidate();
          setLoading(false);
          setShopifyModalVisible(false);
          notification.success({
            message: 'Success',
            description: 'Shopify connection added successfully',
          });
        }
      }, 500);
    } catch (error: any) {
      const errorMessage = typeof error?.response?.data === 'string'
          ? error.response.data
          : error?.response?.data?.message || error?.message || 'Failed to connect to Shopify';

      notification.error({
        message: 'Error',
        description: errorMessage,
      });
      setLoading(false);
    }
  };

  const onDisconnect = async (keyId: number) => {
    if (!isAllowed) {
      notification.error({ message: 'Error', description: 'You are not allowed to perform this action' });
      return;
    }

    setLoading(true);
    try {
      await api.delete(`companies/${company?.id}/connection/${keyId}`);
      await revalidate();
      if (selectedStore === keyId) {
        setSelectedStore(null);
      }
      notification.success({
        message: 'Success',
        description: 'Shopify store disconnected successfully',
      });
    } catch (error: any) {
      const errorMessage = typeof error?.response?.data === 'string'
          ? error.response.data
          : error?.response?.data?.message || error?.message || 'Failed to disconnect from Shopify';

      notification.error({
        message: 'Error',
        description: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const onClick = (item: IConnectionItem, action: ConnectionAction) => {
    if (action === ConnectionAction.Connect) {
      if (item.title === 'Shopify') {
        setShopifyModalVisible(true);
      }
    } else if (action === ConnectionAction.Setup) {
      setSetupModalVisible(true);
    }
  };

  const onMatchedChange = (value: Matched, connectionId: number) => {
    if (!isAllowed) {
      notification.error({
        message: 'Error',
        description: 'You are not allowed to perform this action'
      });
      return;
    }

    // Update local state immediately for UI feedback
    setLocalMatched(prev => ({
      ...prev,
      [connectionId]: value
    }));

    // Store pending changes
    pendingChangesRef.current = {
      connectionId,
      value
    };
  };

  const selectedConnection = shopifyConnections.find(conn => conn.keyId === selectedStore);
  const storeOptions = shopifyConnections.map(conn => ({
    label: conn.connectionInfo?.store?.store_name || conn.connectionInfo?.store?.domain || 'Store',
    value: conn.keyId
  }));

  useEffect(() => {
    if (selectedConnection) {
      setLocalMatched(prev => ({
        ...prev,
        [selectedConnection.keyId]: selectedConnection.settings?.shopify?.matched || {}
      }));
    }
  }, [selectedConnection]);

  if (!company) return null;

  return (
      <Flex vertical>
        <Divider orientation="left">Integration</Divider>
        <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={items}
            renderItem={(item) => (
                <List.Item key={item.title}>
                  <CardIntegration item={item} onClick={onClick} />
                </List.Item>
            )}
        />

        {hasActiveConnections && (
            <Flex vertical gap={20}>
              <Divider orientation="left">Settings</Divider>
              <Flex gap={8} align="center">
                <Text>Select Store:</Text>
                <Select
                    style={{ width: 300 }}
                    options={storeOptions}
                    value={selectedStore}
                    onChange={setSelectedStore}
                    placeholder="Select a Shopify store"
                />
              </Flex>

              {selectedConnection && (
                  <>
                    <Text>
                      We need to know how you match your e-commerce data to your general ledger accounts
                    </Text>
                    <MatchAccounts
                        value={localMatched[selectedConnection.keyId] || {}}
                        onChange={(value) => onMatchedChange(value, selectedConnection.keyId)}
                        accounts={accounts}
                        loading={isLoading || (activeConnection === selectedConnection.keyId && loading)}
                        options={shopifyAccounts}
                        placeholder="Select Account(s)"
                        multiple
                        disabled={!isAllowed}
                    />
                  </>
              )}
            </Flex>
        )}

        <Modal
            title="Shopify Integrations"
            open={setupModalVisible}
            onCancel={() => setSetupModalVisible(false)}
            footer={null}
            width={600}
        >
          <Flex vertical gap={10} style={{ marginTop: 10, marginBottom: 20 }}>
            <Text strong>Connected stores ({activeConnectionsCount}):</Text>
            {shopifyConnections.map((connection) => {
              const storeName = connection.connectionInfo?.store?.store_name ||
                  connection.connectionInfo?.store?.domain ||
                  'Store';

              return (
                  <Flex key={connection.keyId} justify="space-between" align="center">
                    <Text>{storeName}</Text>
                    <Flex gap={8} align="center">
                      <Tag color={connection.status === 1 ? 'blue' : 'orange'}>
                        {connection.status === 1 ? 'active' : 'syncing'}
                      </Tag>
                      <Button
                          danger
                          size="small"
                          onClick={() => onDisconnect(connection.keyId)}
                          loading={loading}
                      >
                        Disconnect
                      </Button>
                    </Flex>
                  </Flex>
              );
            })}
            <Divider />
            <Button
                type="primary"
                onClick={() => {
                  setSetupModalVisible(false);
                  setShopifyModalVisible(true);
                }}
            >
              Connect New Store
            </Button>
          </Flex>
        </Modal>

        <ShopifyConnectModal
            visible={shopifyModalVisible}
            onCancel={() => setShopifyModalVisible(false)}
            onConnect={connectShopify}
            loading={loading}
        />
      </Flex>
  );
}

type Matched = { [key: string]: string[] };

const shopifyAccounts = [
  { id: 'gross', name: 'Gross Sales', isCalc: false },
  { id: 'discount', name: 'Discounts', isCalc: false },
  { id: 'returns', name: 'Returns', isCalc: false },
  { id: 'net', name: 'Net Sales', isCalc: true },
  { id: 'shipping', name: 'Shipping', isCalc: false },
];
