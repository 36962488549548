import { ConnectionType, IntegrationStatus } from './company.types'

export interface IConnectionItem {
  image: string
  title: string
  disabled?: boolean
  connected?: boolean
  status?: string
}

export enum ConnectionAction {
  Connect,
  Setup,
}


export interface IConnectionData {
  connectionInfo: any;
  keyId: any;
  id: ConnectionType;
  name: string;
  status: IntegrationStatus;
  type: {
    id: ConnectionType;
    name: string;
  };
  settings?: ISettings;
}


export interface Integrations {
  name: string;
  integrationKeyId: number;
}

export interface ISettings {
  shopify?: {
    matched: {
      [key: string]: string[]
    }
  }
}