import { CloseCircleOutlined, MoreOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Dropdown, Table, App } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useState } from 'react'
import { formatDateTime } from 'utils/dates'

type InvitedMembersProps = {
  data: any
  loading: boolean
  teamId?: number
  resendInvite: (params: any) => Promise<void>
  rescindInvite: (params: any) => Promise<void>
}

function InvitedMembers(props: InvitedMembersProps) {
  const { notification } = App.useApp();
  const { data, loading, resendInvite, rescindInvite } = props
  const [loadingStates, setLoadingStates] = useState<Record<number, boolean>>({})

  const onResend = async (invite: any) => {
    try {
      setLoadingStates(prev => ({ ...prev, [invite.id]: true }))
      await resendInvite({
        inviteId: invite.id,
        teamId: invite.teamId
      })
      notification.success({ message: 'Invitation successfully sent' })
    } catch (error) {
      notification.error({ message: 'Failed to resend invitation' })
      console.error(error)
    } finally {
      setLoadingStates(prev => ({ ...prev, [invite.id]: false }))
    }
  }

  const onRescind = async (invite: any) => {
    try {
      setLoadingStates(prev => ({ ...prev, [invite.id]: true }))
      await rescindInvite({
        inviteId: invite.id,
        teamId: invite.teamId
      })
      notification.success({ message: 'Invitation successfully rescinded' })
    } catch (error) {
      notification.error({ message: 'Failed to rescind invitation' })
      console.error(error)
    } finally {
      setLoadingStates(prev => ({ ...prev, [invite.id]: false }))
    }
  }

  return (
      <Table
          dataSource={data}
          columns={getColumns(onResend, onRescind, loadingStates)}
          pagination={false}
          loading={loading}
          rowKey={'id'}
          locale={{
            emptyText: 'No pending invitations',
          }}
      />
  )
}

export default InvitedMembers

const ActionsColumn = (props: {
  onResend: () => void;
  onRescind: () => void;
  loading: boolean;
}) => {
  const onResend = () => {
    props.onResend()
  }

  const onRescind = () => {
    props.onRescind()
  }

  const menuItems = [
    {
      label: 'Resend',
      key: '1',
      icon: <ReloadOutlined />,
      onClick: onResend,
      disabled: props.loading
    },
    {
      label: 'Rescind',
      key: '2',
      icon: <CloseCircleOutlined />,
      onClick: onRescind,
      disabled: props.loading
    },
  ]

  return (
      <Dropdown trigger={['click']} menu={{ items: menuItems, onClick: (e) => e.domEvent.stopPropagation() }}>
        <Button
            shape="circle"
            size="small"
            type="link"
            loading={props.loading}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
            }}
        >
          <MoreOutlined />
        </Button>
      </Dropdown>
  )
}

const getColumns = (
    onResend: (invite: any) => void,
    onRescind: (invite: any) => void,
    loadingStates: Record<number, boolean>
): ColumnsType<any> => [
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    render: (role) => {
      if (role === 0) return 'Admin'
      if (role === 1000) return 'Member'
      if (role === 2000) return 'Guest'
    },
  },
  {
    title: 'Invited On',
    dataIndex: 'invitedAt',
    render: (date) => (date ? formatDateTime(date) : '-'),
  },
  {
    title: 'Invited By',
    dataIndex: 'invitedBy',
    render: (member) => member?.firstName + ' ' + member?.lastName,
  },
  {
    title: 'Action',
    key: 'action',
    render: (invite) => {
      return (
          <ActionsColumn
              onResend={() => onResend(invite)}
              onRescind={() => onRescind(invite)}
              loading={loadingStates[invite.id] || false}
          />
      )
    },
  },
]