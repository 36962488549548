import React from 'react'
import { Steps } from 'antd'

const { Step } = Steps

interface ProgressProps {
  currentStep: number
}

export const Progress: React.FC<ProgressProps> = ({ currentStep }) => {
  const steps = [
    { number: 1, text: 'Choose Service' },
    { number: 2, text: 'Grant QuickBooks Access' },
    { number: 3, text: 'Documents & Access' },
    { number: 4, text: 'Schedule Meeting' },
    { number: 5, text: 'Complete' },
  ]

  return (
    <div style={{ width: '100%', padding: '8px 0' }}>
      <Steps
        current={currentStep - 1}
        progressDot
        size="small"
        style={
          {
            columnGap: '4px',
            '--dot-size': '6px',
            '--line-width': '80%',
          } as React.CSSProperties
        }
      >
        {steps.map((step) => (
          <Step
            key={step.number}
            title={<span style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>{`${step.number}. ${step.text}`}</span>}
            style={{ flex: 1, minWidth: 0, padding: 0 }}
          />
        ))}
      </Steps>
    </div>
  )
}

export default Progress
