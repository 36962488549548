import { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Flex, Row, Table, Input } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import { api } from 'utils/axios'
import { downloadBlob } from 'utils/download'
import { ICompany } from 'types/company.types'
import { useAdminCompanies } from '../../hooks/userAdminCompanies'

export function CompaniesTab() {
  const companies = useAdminCompanies()
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)

  const download = async () => {
    setLoading(true)
    return api
      .get('admin/companies/export', { responseType: 'blob' })
      .then((r) => downloadBlob(r.data, 'equility-companies.xls'))
      .finally(() => setLoading(false))
  }

  const filteredData = companies.data?.filter((company) => {
    const searchLower = searchTerm.toLowerCase()
    return (
      company.name.toLowerCase().includes(searchLower) ||
      (company.alias || '').toLowerCase().includes(searchLower) ||
      (company.owner?.email || '').toLowerCase().includes(searchLower)
    )
  })

  return (
    <Flex vertical gap={10}>
      <Row gutter={24}>
        <Col span={8}>
          <Input
            placeholder="Search by name or alias..."
            prefix={<SearchOutlined />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            allowClear
          />
        </Col>
        <Col span={12}>
          <Divider orientation="left">{filteredData?.length} total</Divider>
        </Col>
        <Col span={4}>
          <Flex vertical justify="center" style={btnFlexStyle}>
            <Button block onClick={download} loading={loading}>
              Download
            </Button>
          </Flex>
        </Col>
      </Row>

      <Table
        dataSource={filteredData}
        columns={columns}
        rowKey="id"
        loading={companies.isLoading}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  )
}

const columns: ColumnsType<ICompany> = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 220,
    ellipsis: true,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Alias',
    dataIndex: 'alias',
    width: 150,
    ellipsis: true,
    render: (alias) => alias || '-',
  },
  // {
  //   title: 'Owner',
  //   dataIndex: ['owner'],
  //   width: 180,
  //   ellipsis: true,
  //   render: (owner) => owner ? `${owner.firstName} ${owner.lastName}` : '-',
  // },
  {
    title: 'Connections',
    dataIndex: 'connections',
    width: 120,
    render: (connections) => connections?.length || 0,
  },
  // {
  //   title: 'Active Connections',
  //   dataIndex: 'connections',
  //   width: 150,
  //   render: (connections) =>
  //     connections?.filter(c => c.status === IntegrationStatus.Active).length || 0,
  // },
  {
    title: 'Rules',
    dataIndex: 'rules',
    width: 100,
    render: (rules) => rules?.length || 0,
  },
  {
    title: 'Tier',
    dataIndex: 'accessLevel',
    width: 100,
    sorter: (a, b) => (a.accessLevel || 0) - (b.accessLevel || 0),
    render: (level) => {
      if (level === 1) return 'Pro'
      if (level === 2) return 'Advanced'
      return ''
    },
  },
  {
    title: 'Accounting Method',
    dataIndex: ['settings', 'accountingMethod'],
    width: 160,
    render: (method) => method || '-',
  },
  {
    title: 'Currency',
    dataIndex: ['settings', 'currency'],
    width: 100,
    render: (currency) => currency || '-',
  },
]

const btnFlexStyle: React.CSSProperties = {
  height: '100%',
}
