import React, { useEffect, useState } from 'react'
import { Progress } from './components/Progress'
import { Radio, Button, RadioChangeEvent, Typography, Row, Col, Select, Spin } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useCompanies } from 'hooks/useCompanies'
import type { ICompany } from 'types/company.types'

const { Title, Text } = Typography
const { Option } = Select

interface RadioOptionProps {
  value: string
  label: string
  name: string
  checked: boolean
  onChange: (e: RadioChangeEvent) => void
}

const RadioOption: React.FC<RadioOptionProps> = ({ value, label, name, checked, onChange }) => (
  <Radio
    name={name}
    value={value}
    checked={checked}
    onChange={onChange}
    style={{
      display: 'flex',
      alignItems: 'center',
      padding: '12px',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      marginBottom: '8px',
      cursor: 'pointer',
    }}
  >
    <Text>{label}</Text>
  </Radio>
)

export function ServicesPage() {
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()

  const companies = useCompanies()

  const [selectedCompanyId, setSelectedCompanyId] = useState<string>(
    () => localStorage.getItem('selectedCompanyId') || '',
  )
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>(
    () => localStorage.getItem('selectedCompanyName') || '',
  )
  const [selectedService, setSelectedService] = useState<string>(() => localStorage.getItem('selectedService') || '')
  const [timeline, setTimeline] = useState<string>(() => localStorage.getItem('timeline') || '')

  const handleNext = () => {
    if (selectedCompanyId && selectedService && timeline) {
      localStorage.setItem('selectedCompanyId', selectedCompanyId)
      localStorage.setItem('selectedCompanyName', selectedCompanyName)
      localStorage.setItem('selectedService', selectedService)
      localStorage.setItem('timeline', timeline)
      navigate(`/company/${params.id}/services/grant-access`)
    }
  }

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('selectedCompanyId')
      localStorage.removeItem('selectedCompanyName')
      localStorage.removeItem('selectedService')
      localStorage.removeItem('timeline')
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const companyOptions =
    companies.data?.map((company: ICompany) => ({
      label: company.alias || company.name,
      value: company.id,
    })) || []

  return (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', padding: '32px', marginBottom: '32px' }}>
      <Title level={2}>Services</Title>

      <div style={{ marginBottom: '64px' }}>
        <Progress currentStep={1} />
      </div>
      <div style={{ marginBottom: '32px' }}>
        <Title level={5}>
          Are you in the middle of a time crunch and need some last-minute help? For a flat $45/hr, we'll help you get
          your time-sensitive tasks completed accurately and on-time.
        </Title>
      </div>

      <div style={{ marginBottom: '32px' }}>
        <Title level={5}>Start by selecting the company that you'd like help with:</Title>
        {companies.isLoading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spin size="large" />
          </div>
        ) : (
          <Select
            style={{ width: '100%' }}
            placeholder="Select a company"
            labelInValue
            value={
              selectedCompanyId
                ? { value: selectedCompanyId, label: selectedCompanyName || selectedCompanyId }
                : undefined
            }
            onChange={(selected) => {
              const id = selected.value
              const name = selected.label

              setSelectedCompanyId(id)
              setSelectedCompanyName(name)
            }}
            size="large"
          >
            {companyOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        )}
      </div>

      <div style={{ marginBottom: '32px' }}>
        <Title level={5}>Select the service that you'd like help with:</Title>
        <Text style={{ display: 'block', marginBottom: '16px' }}>
          If help with more than one service is needed, please submit separate requests for each of these categories.
        </Text>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <RadioOption
              name="service"
              value="account"
              label="Account Reconciliations"
              checked={selectedService === 'account'}
              onChange={(e) => setSelectedService(e.target.value)}
            />
            <RadioOption
              name="service"
              value="ecommerce"
              label="E-Commerce Sales Data Entry"
              checked={selectedService === 'ecommerce'}
              onChange={(e) => setSelectedService(e.target.value)}
            />
          </Col>
          <Col span={12}>
            <RadioOption
              name="service"
              value="payroll"
              label="Payroll Reconciliations"
              checked={selectedService === 'payroll'}
              onChange={(e) => setSelectedService(e.target.value)}
            />
            <RadioOption
              name="service"
              value="depreciation"
              label="Depreciation & Amortization Entries"
              checked={selectedService === 'depreciation'}
              onChange={(e) => setSelectedService(e.target.value)}
            />
          </Col>
        </Row>
      </div>
      <div style={{ marginBottom: '32px' }}>
        <Title level={5}>And let us know when you'd like for the project to be completed by:</Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <RadioOption
              name="timeline"
              value="few-days"
              label="Within a few days"
              checked={timeline === 'few-days'}
              onChange={(e) => setTimeline(e.target.value)}
            />
            <RadioOption
              name="timeline"
              value="week"
              label="Within a week"
              checked={timeline === 'week'}
              onChange={(e) => setTimeline(e.target.value)}
            />
          </Col>
          <Col span={12}>
            <RadioOption
              name="timeline"
              value="few-weeks"
              label="Within a few weeks"
              checked={timeline === 'few-weeks'}
              onChange={(e) => setTimeline(e.target.value)}
            />
            <RadioOption
              name="timeline"
              value="other"
              label="Within a month"
              checked={timeline === 'other'}
              onChange={(e) => setTimeline(e.target.value)}
            />
          </Col>
        </Row>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={handleNext}
          disabled={!selectedCompanyId || !selectedService || !timeline}
          type="primary"
          size="large"
          style={{ padding: '0 32px', borderRadius: '8px' }}
        >
          Next
        </Button>
      </div>
    </div>
  )
}