import {CSVBoxButton} from '@csvbox/react'
import {App, Button, Flex, Select, Tooltip, Typography} from 'antd'
import {useUser} from 'hooks/useUser'
import {useEffect, useState} from 'react'

const { Text } = Typography

interface Props {
    isLiability?: boolean;
    isDateNotSelected?: boolean;
    onChange: (data: any[]) => void;
}

interface CsvRow {
    [key: string]: any;
}

const DATE_SELECTION_MESSAGE = 'Please select the date range, General Ledger account, and corresponding balances before proceeding.'

export function CsvUpload(props: Props) {
    const { notification } = App.useApp();
    const { isLiability, onChange, isDateNotSelected } = props
    const { user } = useUser()

    const [selected, setSelected] = useState<string>(keys[0])

    useEffect(() => {
        setSelected(isLiability ? keys[2] : keys[0])
    }, [isLiability])

    // // Helper function to log detailed diagnostics about amount processing
    // const logAmountProcessing = (rows: CsvRow[], amountColumn: string) => {
    //     console.log('---- Amount Processing Diagnostics ----');
    //
    //     // Check how many rows have the amount column
    //     const rowsWithAmountColumn = rows.filter(row => row[amountColumn] !== undefined);
    //     console.log(`${rowsWithAmountColumn.length} of ${rows.length} rows have an "${amountColumn}" column`);
    //
    //     // Log all unique amount formats
    //     const uniqueFormats = new Set();
    //     rows.forEach(row => {
    //         if (row[amountColumn] !== undefined) {
    //             uniqueFormats.add(row[amountColumn]);
    //         }
    //     });
    //     console.log('Unique amount formats found:');
    //     uniqueFormats.forEach(format => console.log(`  - ${format}`));
    //
    //     // Count positive and negative values
    //     const positiveCount = rows.filter(row => {
    //         const val = row[amountColumn];
    //         return val !== undefined && typeof val === 'string' &&
    //             val.includes('$') && !val.includes('(');
    //     }).length;
    //
    //     const negativeCount = rows.filter(row => {
    //         const val = row[amountColumn];
    //         return val !== undefined && typeof val === 'string' &&
    //             val.includes('$') && val.includes('(');
    //     }).length;
    //
    //     console.log(`Found ${positiveCount} positive values and ${negativeCount} negative values`);
    //     console.log('---------------------------------------');
    // };

    // Process the CSV data specifically for a bank statement format
    const processBankStatement = (rows: CsvRow[]): CsvRow[] => {
        if (!rows || !Array.isArray(rows) || rows.length === 0) return [];

        // Identify the amount column
        const firstRow = rows[0];
        const columnNames = Object.keys(firstRow);

        // 1. Try to find a column explicitly named "Amount" (with different casings)
        let amountColumn = columnNames.find(name =>
            name.toLowerCase() === 'amount'
        );

        // 2. If not found, look for columns with "amount" in their name
        if (!amountColumn) {
            amountColumn = columnNames.find(name =>
                name.toLowerCase().includes('amount')
            );
        }

        // 3. If still not found, look for a column with dollar signs
        if (!amountColumn) {
            for (const name of columnNames) {
                const hasValues = rows.some(row => {
                    const val = row[name];
                    return typeof val === 'string' && val.includes('$');
                });

                if (hasValues) {
                    amountColumn = name;
                    break;
                }
            }
        }

        // If we haven't found an amount column, return the original rows
        if (!amountColumn) {
            console.warn('No amount column found in the bank statement.');
            return rows;
        }

        // console.log(`Identified amount column: "${amountColumn}"`);

        // Log processing diagnostics
        // logAmountProcessing(rows, amountColumn);

        // Process each row
        return rows.map((row, index) => {
            const newRow = {...row};

            // Get the amount value
            const amountValue = row[amountColumn];

            // Skip processing if there's no amount value
            if (amountValue === undefined || amountValue === null) {
                return newRow;
            }

            try {
                // Convert to string if needed
                const amountStr = typeof amountValue === 'string'
                    ? amountValue
                    : String(amountValue);

                // Detect if it's a negative value (in parentheses)
                const isNegative = amountStr.includes('(') && amountStr.includes(')');

                // Clean up the amount string - remove $, parentheses, commas, spaces
                let cleanAmount = amountStr.replace(/[$,\s()]/g, '');

                // Apply negative sign if it was in parentheses
                if (isNegative) {
                    cleanAmount = '-' + cleanAmount;
                }

                // Convert to number
                const numericAmount = parseFloat(cleanAmount);

                // Only update if we got a valid number
                if (!isNaN(numericAmount)) {
                    // Update the row with the processed amount
                    newRow[amountColumn] = numericAmount.toString();
                } else {
                    console.warn(`Row ${index}: Could not parse "${amountValue}" as a number`);
                }
            } catch (error) {
                console.warn(`Row ${index}: Error processing amount "${amountValue}":`, error);
            }

            return newRow;
        });
    };

    const onImport = (_: boolean, metadata?: any) => {
        if (metadata.import_status !== 'success') {
            return notification.error({
                message: 'Error uploading file',
                description: 'Please try again',
            });
        }

        try {
            // Process the bank statement
            const processedRows = processBankStatement(metadata.rows);

            // Pass the processed data to the parent component
            onChange(processedRows);
        } catch (error) {
            console.error('Error processing bank statement:', error);
            notification.error({
                message: 'Error processing bank statement',
                description: 'There was an issue with the imported data. The original data will be used instead.',
            });

            // Fall back to the original data
            onChange(metadata.rows);
        }
    };

    const selectedOption = options.find((o) => o.value === selected);

    const uploadButton = (launch: () => void, isLoading: boolean) => (
        <Tooltip title={isDateNotSelected ? DATE_SELECTION_MESSAGE : ''}>
            <Button
                type="primary"
                loading={isLoading}
                onClick={launch}
                style={{ width: 120 }}
                disabled={isDateNotSelected}
            >
                Upload
            </Button>
        </Tooltip>
    );

    return (
        <Flex vertical gap={10}>
            <Flex gap={10}>
                <Select
                    value={selected}
                    style={inputStyle}
                    options={options}
                    onChange={setSelected}
                    disabled={isDateNotSelected}
                />
                <CSVBoxButton
                    key={selected}
                    user={{ user_id: user?.id }}
                    licenseKey={selected}
                    onImport={onImport}
                    render={uploadButton}
                />
            </Flex>
            <Text type="secondary">
                <b>Please Upload Your Bank Statement In CSV Format. Please Note:</b>
                <ul>
                    <li>Checking Accounts = Assets</li>
                    <li>Credit Cards = Liabilities</li>
                </ul>
            </Text>
            <Text type="secondary">{selectedOption?.desc}</Text>
        </Flex>
    );
}

const inputStyle: React.CSSProperties = {
    width: '100%',
}



const options = [
  {
    value: 'hEKT8TOuof8YtPhzNVFsrgqUnZhN38',
    label: 'Asset account - Amounts in one column',
    desc: (
      <>
        <b>Asset Account - Amounts in one column</b>
        <ul>
          <li>Money Spent - These amounts need to be negative</li>
          <li>Money Received - These amounts need to be positive</li>
        </ul>
      </>
    ),
  },
  {
    value: 'LHtnMpReSYicllPWJ5KcVU9nOqOccV',
    label: 'Asset account - Amounts in two columns',
    desc: (
      <>
        <b>Asset Account - Amounts in two columns</b>
        <ul>
          <li>Money Spent & Received - Both amounts need to be positive</li>
        </ul>
      </>
    ),
  },
  {
    value: 'zlN9QCA2vqce8gFPayWaHiJWPfGaCT',
    label: 'Liability account - Amounts in one column',
    desc: (
      <>
        <b>Liability account - Amounts in one column</b>
        <ul>
          <li>Money Spent - These amounts need to be positive</li>
          <li>Money Received - These amounts need to be negative</li>
        </ul>
      </>
    ),
  },
  {
    value: 'v8JR5rcYKkRk0SgLY7Z1EuQJGsDhOL',
    label: 'Liability account - Amounts in two column',
    desc: (
      <>
        <b>Liability account - Amounts in two columns</b>
        <ul>
          <li>Money Spent & Received - Both amounts need to be positive</li>
        </ul>
      </>
    ),
  },
]

const keys = [
  'hEKT8TOuof8YtPhzNVFsrgqUnZhN38',
  'LHtnMpReSYicllPWJ5KcVU9nOqOccV',
  'zlN9QCA2vqce8gFPayWaHiJWPfGaCT',
  'v8JR5rcYKkRk0SgLY7Z1EuQJGsDhOL',
]

export default CsvUpload