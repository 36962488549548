import useSWR, { useSWRConfig } from 'swr'

import { api } from 'utils/axios'
import { useCompanyStore } from 'store/company.store'
import {IAccount, UpdateAccounts} from 'types/account.types'
import useSWRMutation from 'swr/mutation'
import {useCallback, useMemo} from "react";

const fetchAccounts = (companyId: number) => {
  if (!companyId) return Promise.resolve([])
  return api
      .get('/accounting/accounts', { params: { companyId } })
      .then((res) => res.data);
};

export const useAccounts = (companyIdParam?: number) => {
  const storedCompanyId = useCompanyStore((state) => state.id);
  const companyId = companyIdParam ?? storedCompanyId;

  // Memoize the key to prevent unnecessary re-renders
  const key = useMemo(() =>
          companyId ? ['accounting/accounts', companyId] : null,
      [companyId]
  );

  const { mutate: revalidate } = useSWRConfig();

  // Add configuration options for better caching
  const { data, isLoading } = useSWR<IAccount[]>(
      key,
      () => fetchAccounts(companyId || 0),
      {
        revalidateOnFocus: false,  // Don't revalidate on window focus
        dedupingInterval: 5000,    // Dedupe requests within 5 seconds
        shouldRetryOnError: false  // Don't retry on error
      }
  );

  const memoizedRevalidate = useCallback(() =>
          revalidate(['accounting/accounts', companyId]),
      [revalidate, companyId]
  );

  return {
    accounts: data || [],
    isLoading,
    revalidate: memoizedRevalidate,
  };
};

export const useAccountsById = (companyId: number | undefined) => {
  const key = companyId ? ['accounting/accounts', companyId] : null
  return useSWR<IAccount[]>(key, () => fetch(companyId || null))
}

export const useAccount = (id: string) => {
  const { accounts, isLoading } = useAccounts()
  const account = accounts?.find((a) => a.platformId === id)
  return { account, isLoading }
}

export const useChangeEmail = () => {
  const {
    data,
    trigger: changeEmail,
    isMutating: changeEmailLoading,
  } = useSWRMutation('auth/change-email', changeEmailFetcher)
  return { data, changeEmail, changeEmailLoading }
}

const changeEmailFetcher = (url: string, { arg }: { arg: { email: string } }) =>
  api.post(url, arg).then((res) => res.data)

const fetch = (companyId: number | null) => {
  if (!companyId) return Promise.resolve([])
  return api.get('/accounting/accounts', { params: { companyId } }).then((res) => res.data)
}

// Helper function to format account balance
export const formatAccountBalance = (balance?: string): string => {
  if (!balance) return '$0.00'
  const num = parseFloat(balance)
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(num)
}



export const useUpdateAccount = () => {
  const companyId = useCompanyStore((state) => state.id)
  const { mutate } = useSWRConfig()

  const key = companyId ? ['accounting/accounts', companyId] : null

  const updateAccountFetcher = async (
      _key: (string | number)[],
      { arg }: { arg: UpdateAccounts }
  ) => {
    if (!companyId) {
      throw new Error('Company ID is required to update an account.')
    }
    const response = await api.patch('/accounting/accounts', arg, {
      params: { companyId },
    })
    return response.data
  }

  const { trigger, isMutating, data } = useSWRMutation(key, updateAccountFetcher)

  const updateAccount = async (account: UpdateAccounts) => {
    const result = await trigger(account)
    if (companyId) {
      mutate(['accounting/accounts', companyId])
    }
    return result
  }

  return { updateAccount, updateAccountLoading: isMutating, data }
}

export const useUpdateAccounts = (companyIdParam?: number) => {
  // Always call the store hook unconditionally
  const storedCompanyId = useCompanyStore((state) => state.id)
  // Use the passed companyId if provided; otherwise, fallback to the stored company ID
  const companyId = companyIdParam ?? storedCompanyId
  const { mutate } = useSWRConfig()
  const key = companyId ? ['accounting/accounts', companyId] : null
  // Helper to check for duplicate mappings
  const hasDuplicateMappings = (updates: UpdateAccounts[]) => {
    // Check if any platformId (accountId) appears more than once
    const platformIds = updates.map(update => update.accountId)
    const uniquePlatformIds = new Set(platformIds)

    // If we have fewer unique platformIds than total updates, we have duplicates
    return uniquePlatformIds.size !== updates.length
  }

  // Helper to validate update payload
  const validateUpdatePayload = (updates: UpdateAccounts[]) => {
    if (!companyId) {
      throw new Error('Company ID is required to update accounts.')
    }

    if (hasDuplicateMappings(updates)) {
      throw new Error('Cannot map multiple bank feed accounts to the same QuickBooks account.')
    }
  }
  const updateAccountsFetcher = async (
      _key: (string | number)[],
      { arg }: { arg: UpdateAccounts[] }
  ) => {
    validateUpdatePayload(arg)

    try {
      // Use PUT for all updates to maintain consistency
      const response = await api.put('/accounting/accounts', arg, {
        params: { companyId },
      })

      return response.data
    } catch (error: any) {
      console.error('API Error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      })
      throw error
    }
  }

  const { trigger, isMutating, data, error } = useSWRMutation(key, updateAccountsFetcher)

  const updateAccounts = async (accounts: UpdateAccounts[]) => {
    try {
      // console.log('updateAccounts received:', accounts)

      // For single account updates, use PATCH
      // if (accounts.length === 1) {
      //   try {
      //     console.log('Making single account update with PATCH')
      //     const response = await api.patch('/accounting/accounts', accounts[0], {
      //       params: {companyId}
      //     })
      //     if (companyId) {
      //       await mutate(['accounting/accounts', companyId])
      //     }
      //     console.log('updateAccounts response:', JSON.stringify(response.data))
      //     return response.data
      //   } catch (error) {
      //     console.error('updateAccounts error:', error)
      //     throw error
      //  }
      // }

      // For multiple accounts, use PUT
      const result = await trigger(accounts)

      if (companyId) {
        await mutate(['accounting/accounts', companyId])
      }
      return result
    } catch (error: any) {
      console.error('updateAccounts error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      })
      throw error
    }
  }

  return {
    updateAccounts,
    updateAccountsLoading: isMutating,
    data,
    error
  }
}

export function useSyncAccounts(companyId: number) {
  // Create a key for the syncAccounts endpoint if companyId is provided.
  const key = companyId ? `/accounting/accounts?companyId=${companyId}` : null;

  // Define the fetcher function for SWR Mutation.
  const syncAccountsFetcher = async (url: string) => {
    const response = await api.get(url);
    return response.data;
  };

  // useSWRMutation returns a trigger function that is asynchronous.
  const { trigger, isMutating, data, error } = useSWRMutation(key, syncAccountsFetcher);

  // Wrap the trigger in an async callback for additional consistency if needed.
  const syncAccounts = useCallback(async () => {
    if (!companyId) {
      throw new Error('companyId is required');
    }
    return await trigger();
  }, [companyId, trigger]);

  return { syncAccounts, isLoading: isMutating, data, error };
}