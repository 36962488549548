import { Tabs, TabsProps } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Page } from 'components/Page'
import { UsersTab } from './Users'
import { CompaniesTab } from './Companies'
import { RulesTab } from './Rules'
import { ServicesTab } from "./Services";

export function AdminPage() {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('1')

  useEffect(() => {
    // Get tab from URL query parameter
    const params = new URLSearchParams(location.search)
    const tab = params.get('tab')
    
    // Set active tab based on URL parameter
    if (tab === 'users') {
      setActiveTab('1')
    } else if (tab === 'companies') {
      setActiveTab('2')
    } else if (tab === 'rules') {
      setActiveTab('3')
    } else if (tab === 'services') {
      setActiveTab('4')
    }
  }, [location])

  return (
    <Page title="Admin">
      <Tabs activeKey={activeTab} onChange={setActiveTab} items={items} />
    </Page>
  )
}

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Users',
    children: <UsersTab />,
  },
  {
    key: '2',
    label: 'Companies',
    children: <CompaniesTab />,
  },
  {
    key: '3',
    label: 'Rules',
    children: <RulesTab />,
  },
  {
    key: '4',
    label: 'Services',
    children: <ServicesTab />,
  },
]
