import { useEffect } from 'react'
import { Link, useMatch, useNavigate } from 'react-router-dom'
import { Flex, Menu, Select, theme, Tooltip } from 'antd'
import type { ItemType, MenuItemType } from 'antd/es/menu/interface'
import * as Icon from '@ant-design/icons'

import { useCompanies } from 'hooks/useCompanies'
import { useCompanyStore } from 'store/company.store'
import { useAccessLevel } from 'hooks/useAccessLevel'
import { useFlags } from 'flagsmith/react'

export function CompanyMenu() {
  const { data, isLoading } = useCompanies()
  const { accessLevel } = useAccessLevel()
  const flags = useFlags(['disable_services_menu'])

  const navigate = useNavigate()
  const match = useMatch('/company/:id/*')

  const companyId = useCompanyStore((state) => state.id) ?? data?.[0]?.id ?? null
  const setCompany = useCompanyStore((state) => state.setCompany)

  useEffect(() => {
    if (!data) return
    if (!!companyId && !data.length) {
      setCompany(null)
      navigate('/')
    }
  }, [data])

  if (!companyId) return null
  if (!accessLevel) return null

  function getQboId(companyId: number) {
    const company = data?.find((company) => company.id === companyId)
    const qbo = company?.connections?.find((v) => v.type.id === 1)
    if (!qbo) return ''
    const connectionInfo = qbo?.connectionInfo
    return connectionInfo.realm_id
  }

  const allMenuItems = getMenuItems(companyId, getQboId(companyId))
  const filteredMenuItems = flags?.disable_services_menu?.enabled
      ? allMenuItems.filter((item): item is ItemType<MenuItemType> => item !== null && item.key !== 'services')
      : allMenuItems


  const menuItems = filteredMenuItems.map((item: any) =>
      accessLevel >= item.accesslevel
          ? item
          : {
            ...item,
            disabled: true,
            label: <Tooltip title={getMsgByAccessLevel(item.accesslevel)}>{item.label}</Tooltip>,
          },
  )
  // const allMenuItems = getMenuItems(companyId, getQboId(companyId))
  // const filteredMenuItems = flags?.disable_chrome_plugin?.enabled
  //   ? allMenuItems.filter((item): item is ItemType<MenuItemType> => item !== null && item.key !== 'reconcile')
  //   : allMenuItems
  //
  // const menuItems = filteredMenuItems.map((item: any) =>
  //   accessLevel >= item.accesslevel
  //     ? item
  //     : {
  //         ...item,
  //         disabled: true,
  //         label: <Tooltip title={getMsgByAccessLevel(item.accesslevel)}>{item.label}</Tooltip>,
  //       },
  // )

  const path = match?.params['*']?.split('/')?.[0]
  const selected = match ? menuItems.find((item) => item?.key === path)?.key || menuItems[0]?.key : ''

  const companies = data?.map((company) => ({ label: company.alias || company.name, value: company.id }))

  return (
    <Flex vertical gap={10}>
      <Flex style={companyStyle}>
        <Select
          // LYP-1072 - Allow users to search for company in top left dropdown box
          showSearch
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          variant="borderless"
          value={companyId}
          options={companies}
          loading={isLoading}
          style={{ width: '100%' }}
          onChange={(id) => navigate(`/company/${id}`)}
        />
      </Flex>
      <Menu mode="inline" items={menuItems} selectedKeys={[selected as string]} style={menuStyle} />
    </Flex>
  )
}

type MenuItems = (companyId: number, qboId: string) => ItemType<MenuItemType>[]
const getMenuItems: MenuItems = (companyId, qboId) => [
  {
    label: <Link to={`/company/${companyId}`}>Analytics</Link>,
    key: 'analytics',
    icon: <Icon.DashboardOutlined />,
    accesslevel: 0,
    title: 'Analytics',
  },
  {
    label: <Link to={`/company/${companyId}/review`}>Financial Review</Link>,
    key: 'review',
    icon: <Icon.FileProtectOutlined />,
    accesslevel: 2,
    title: 'Financial Review',
  },
  {
    label: <Link to={`/company/${companyId}/reconcile`}>Reconciliation</Link>,
    key: 'reconcile',
    icon: <Icon.InteractionOutlined />,
    accesslevel: 0,
    title: 'Reconciliation',
  },
  {
    label: <Link to={`/company/${companyId}/services`}>Services</Link>,
    key: 'services',
    icon: <Icon.AppstoreOutlined />,
    accesslevel: 0,
    title: 'Services',
  },
  {
    label: <Link to={`/company/${companyId}/settings`}>Settings</Link>,
    key: 'settings',
    icon: <Icon.SettingOutlined />,
    accesslevel: 0,
    title: 'Settings',
  },
  {
    label: (
      <a href={linkToQBO(qboId)} target="_blank" rel="noreferrer">
        QuickBooks Online
      </a>
    ),
    key: 'qbo',
    accesslevel: 0,
    icon: <Icon.LinkOutlined />,
  },
]

const companyStyle: React.CSSProperties = {
  background: 'white',
  borderRadius: theme.getDesignToken().borderRadius,
  marginTop: 10,
}

const menuStyle: React.CSSProperties = {
  borderRadius: theme.getDesignToken().borderRadius,
}

function getMsgByAccessLevel(level: number) {
  switch (level) {
    case 2:
      return 'Upgrade to Advanced Tier'
    case 1:
      return 'Upgrade to Pro Tier'
    default:
      return ''
  }
}

const qboUrl = 'https://c1.qbo.intuit.com/qbo1'

export function linkToQBO(qboId: string, page = 'switchCompany') {
  return `${qboUrl}/login?pagereq=${page}&companyId=${qboId}`
}
