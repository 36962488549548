import { Button, Card, Descriptions, Flex } from 'antd'
import { ChangeEmailModal } from 'components/ChangeEmailModal'
import { ChangePasswordModal } from 'components/ChangePasswordModal'

import { useUser } from 'hooks/useUser'
import { Fragment, useState } from 'react'
import { formatDate } from 'utils/dates'
import { auth } from 'utils/firebase'

export function PersonalPage() {
  const { user } = useUser()
  const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] = useState(false)
  const [isChangeEmailModalVisible, setIsChangeEmailModalVisible] = useState(false)

  const googleSignIn = user?.googleSignIn

  if (!user) return null

  const fbUser = auth.currentUser

  return (
    <Flex vertical gap={10}>
      <Card actions={[]}>
        <Descriptions>
          <Descriptions.Item label="Username" span={3}>
            {[user.firstName, user.lastName].join(' ') || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={3}>
            {fbUser?.email} {!fbUser?.emailVerified && '(needs verification)'}
          </Descriptions.Item>
          <Descriptions.Item label="Created on" span={3}>
            {formatDate(user.createdOn)}
          </Descriptions.Item>
          {/*<Descriptions.Item label="Team Member Role" span={3}>*/}
          {/*  {getRoleText(user.member?.role)}*/}
          {/*</Descriptions.Item>*/}
          <Descriptions.Item label="Subscription Tier" span={3}>
            {tiers[user.accessLevel || 0]}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Flex gap={10}>
        <Button disabled={googleSignIn} onClick={() => setIsChangeEmailModalVisible(true)}>
          Change email
        </Button>
        <Button disabled={googleSignIn} onClick={() => setIsChangePasswordModalVisible(true)}>
          Change password
        </Button>
      </Flex>

      {!googleSignIn && (
        <Fragment>
          <ChangePasswordModal
            open={isChangePasswordModalVisible}
            onClose={() => setIsChangePasswordModalVisible(false)}
          />

          <ChangeEmailModal open={isChangeEmailModalVisible} onClose={() => setIsChangeEmailModalVisible(false)} />
        </Fragment>
      )}
    </Flex>
  )
}

const tiers = ['Free', 'Pro', 'Advanced']
