import { useState, useEffect, useRef } from 'react'
import { SearchOutlined, EditOutlined, CheckCircleOutlined, CloseCircleOutlined, TeamOutlined, MoreOutlined, UserAddOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Flex, Row, Table, Input, Modal, Form, Switch, message, Dropdown } from 'antd'
import { useAdminUser } from '../../hooks/useAdminUser'
import type { ColumnsType } from 'antd/es/table'

import { api } from 'utils/axios'
import { formatDate, sortByDate } from 'utils/dates'
import { downloadBlob } from 'utils/download'
import type { IUser } from 'types/user.types'
import { toCurrency } from 'utils/numbers'
import TeamMembershipModal from 'components/TeamMembershipModal'

export function UsersTab() {
  const users = useAdminUser()
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [teamModalVisible, setTeamModalVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null)
  const [form] = Form.useForm()
  const [editLoading, setEditLoading] = useState(false)
  const [tableHeight, setTableHeight] = useState<number | string>('calc(100vh - 200px)')
  const containerRef = useRef<HTMLDivElement>(null)

  // Adjust table height when window is resized
  useEffect(() => {
    const updateTableHeight = () => {
      if (containerRef.current) {
        const topOffset = containerRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        // Leave some space at the bottom (e.g., 40px)
        setTableHeight(windowHeight - topOffset - 40);
      }
    };

    // Initial calculation
    updateTableHeight();

    // Add resize listener
    window.addEventListener('resize', updateTableHeight);

    // Cleanup
    return () => window.removeEventListener('resize', updateTableHeight);
  }, []);

  const download = async () => {
    setLoading(true)
    return api
        .get('admin/users/export', { responseType: 'blob' })
        .then((r) => downloadBlob(r.data, 'equility-users.xls'))
        .finally(() => setLoading(false))
  }

  const filteredData = users.data?.filter(user => {
    const searchLower = searchTerm.toLowerCase()
    return (
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchLower) ||
        user.email.toLowerCase().includes(searchLower) ||
        (user.phoneNumber || '').toLowerCase().includes(searchLower) ||
        (user.member?.team?.name || '').toLowerCase().includes(searchLower)
    )
  })

  const handleEditUser = (user: IUser) => {
    setSelectedUser(user)
    form.setFieldsValue({
      displayName: `${user.firstName} ${user.lastName}`,
      email: user.email,
      phoneNumber: user.phoneNumber || '',
      emailVerified: user.emailVerified || false,
      disabled: user.disabled || false
    })
    setEditModalVisible(true)
  }

  const handleTeamManagement = (user: IUser) => {
    setSelectedUser(user)
    setTeamModalVisible(true)
  }

  const handleEditSubmit = async () => {
    try {
      if (!selectedUser || !selectedUser.firebaseUid) {
        message.error('No Firebase ID available for this user')
        return
      }

      setEditLoading(true)
      const values = await form.validateFields()

      console.log('Updating user with UID:', selectedUser.firebaseUid, 'Values:', values);

      // Call your backend API that uses Firebase Admin SDK with the Firebase UID
      await api.put(`admin/users/firebase/${selectedUser.firebaseUid}`, {
        ...values,
        uid: selectedUser.firebaseUid
      })

      message.success('User updated successfully')
      setEditModalVisible(false)
      users.mutate() // Refresh the users data
    } catch (error: any) {
      console.error('Error updating user:', error)
      let errorMessage = 'Failed to update user'

      // Handle specific Firebase error codes
      if (error.response?.data?.message?.message) {
        errorMessage = error.response.data.message.message
      } else if (error.response?.data?.error) {
        errorMessage = error.response.data.error
      } else if (error.code) {
        switch (error.code) {
          case 'auth/email-already-exists':
            errorMessage = 'The email address is already in use by another account'
            break
          case 'auth/invalid-email':
            errorMessage = 'The email address is not valid'
            break
          case 'auth/user-not-found':
            errorMessage = 'User not found'
            break
          case 'auth/requires-recent-login':
            errorMessage = 'This operation requires recent authentication'
            break
        }
      }

      message.error(errorMessage)
    } finally {
      setEditLoading(false)
    }
  }


  // Enhanced columns with edit action
  const enhancedColumns: ColumnsType<IUser> = [
    ...columns,
    {
      title: 'FB Email Verified',
      dataIndex: 'emailVerified',
      width: 120,
      render: (verified: boolean) => verified ?
          <CheckCircleOutlined style={{ color: 'green' }} /> :
          <CloseCircleOutlined style={{ color: 'red' }} />
    },
    {
      title: 'FB Status',
      dataIndex: 'disabled',
      width: 100,
      render: (disabled: boolean) => disabled ?
          <span style={{ color: 'red' }}>Disabled</span> :
          <span style={{ color: 'green' }}>Active</span>
    },
    {
      title: 'Firebase ID',
      dataIndex: 'firebaseUid',
      width: 220,
      ellipsis: true,
    },
    {
      title: 'Action',
      key: 'action',
      width: 80,
      fixed: 'right' as const,
      render: (_, record: IUser) => {
        const menuItems = [
          {
            key: 'edit',
            label: 'FB Edit User',
            icon: <EditOutlined />,
            disabled: !record.firebaseUid,
            onClick: () => handleEditUser(record)
          },
          {
            key: 'team',
            label: 'Team Management',
            icon: <TeamOutlined />,
            onClick: () => handleTeamManagement(record)
          }
        ];
        
        return (
          <Dropdown 
            menu={{ items: menuItems }} 
            trigger={['click']}
            placement="bottomRight"
          >
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  return (
      <Flex vertical gap={10} style={{ height: '100%' }}>
        <Row gutter={24}>
          <Col span={8}>
            <Input
                placeholder="Search by name, email, phone number or team..."
                prefix={<SearchOutlined />}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                allowClear
            />
          </Col>
          <Col span={12}>
            <Divider orientation="left">{filteredData?.length} total</Divider>
          </Col>
          <Col span={4}>
            <Flex vertical justify="center" style={btnFlexStyle} gap={8}>
              <Button block onClick={download} loading={loading}>
                Download
              </Button>
              <Button 
                block 
                type="primary" 
                icon={<UserAddOutlined />}
                onClick={() => {
                  setSelectedUser(null);
                  setTeamModalVisible(true);
                }}
              >
                Add to Team
              </Button>
            </Flex>
          </Col>
        </Row>

        <div ref={containerRef} style={{ flex: 1, overflow: 'hidden' }}>
          <Table
              dataSource={filteredData}
              columns={enhancedColumns}
              rowKey="id"
              loading={users.isLoading}
              scroll={{ x: 'max-content', y: tableHeight }}
              pagination={{
                position: ['bottomCenter'],
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
              }}
              size="middle"
              style={{ flex: 1 }}
          />
        </div>

        {/* Edit User Modal */}
        <Modal
            title="Edit User"
            open={editModalVisible}
            onCancel={() => setEditModalVisible(false)}
            footer={[
              <Button key="cancel" onClick={() => setEditModalVisible(false)}>
                Cancel
              </Button>,
              <Button
                  key="submit"
                  type="primary"
                  loading={editLoading}
                  onClick={handleEditSubmit}
              >
                Save Changes
              </Button>,
            ]}
        >
          <Form
              form={form}
              layout="vertical"
          >
            <Form.Item
                name="displayName"
                label="Display Name"
            >
              <Input />
            </Form.Item>
            <Form.Item
                name="email"
                label="Email"
                rules={[{ type: 'email', message: 'Please enter a valid email' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                name="phoneNumber"
                label="Phone Number"
            >
              <Input />
            </Form.Item>
            <Form.Item
                name="emailVerified"
                label="Email Verified"
                valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
                name="disabled"
                label="Disable User"
                valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            {selectedUser && (
                <div style={{ marginBottom: 16 }}>
                  <strong>Firebase UID:</strong> {selectedUser.firebaseUid || 'Not available'}
                </div>
            )}
          </Form>
        </Modal>

        {/* Team Membership Modal */}
        <TeamMembershipModal
          open={teamModalVisible}
          onClose={() => setTeamModalVisible(false)}
          selectedUser={selectedUser}
          onSuccess={() => {
            users.mutate(); // Refresh users data after adding to team
          }}
        />
      </Flex>
  )
}

const columns: ColumnsType<IUser> = [
  {
    title: 'Name',
    dataIndex: 'firstName',
    width: 220,
    ellipsis: true,
    render: (name, record) => `${name} ${record.lastName}`,
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    ellipsis: true,
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: 'Last access',
    dataIndex: 'lastAccess',
    render: (date) => (date ? formatDate(date) : '-'),
    sorter: (a, b) => sortByDate(a.lastAccess, b.lastAccess),
    width: 140,
  },
  {
    title: 'Team',
    dataIndex: ['member', 'team', 'name'],
    width: 130,
    ellipsis: true,
  },
  {
    title: 'Role',
    dataIndex: ['member', 'role'],
    width: 90,
    render: (role, user) => {
      if (user.isOwner) return 'Owner'
      if (role === 0) return 'Admin'
      if (role === 1000) return 'Member'
      if (role === 2000) return 'Guest'
    },
  },
  {
    title: 'Companies',
    dataIndex: 'companies',
    width: 110,
    render: (companies) => companies,
  },
  {
    title: 'Tier',
    dataIndex: 'accessLevel',
    width: 100,
    sorter: (a, b) => a.accessLevel - b.accessLevel,
    render: (level) => {
      if (level === 1) return 'Pro'
      if (level === 2) return 'Advanced'
      return ''
    },
  },
  {
    title: 'Value',
    dataIndex: 'charge',
    width: 100,
    sorter: (a, b) => (a.charge || 0) - (b.charge || 0),
    render: (v) => toCurrency(v,'USD'),
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    ellipsis: true,
    sorter: (a, b) => {
      // If neither has a phone number, they're equal
      if (!a.phoneNumber && !b.phoneNumber) return 0;
      // If only a has a phone number, it comes first
      if (a.phoneNumber && !b.phoneNumber) return -1;
      // If only b has a phone number, it comes first
      if (!a.phoneNumber && b.phoneNumber) return 1;
      // If both have phone numbers, sort them normally
      return a.phoneNumber.localeCompare(b.phoneNumber);
    },
  },
  {
    title: 'Created',
    dataIndex: 'createdOn',
    render: (createdOn) => formatDate(createdOn, 'll'),
    width: 140,
  },
]

const btnFlexStyle: React.CSSProperties = {
  height: '100%',
}
