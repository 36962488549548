import { useEffect, useMemo, useState } from 'react'
import { Col, Flex, Form, Input, Modal, Row, Select, Steps, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { SelectProps } from 'antd/lib'

import { useCompanies } from 'hooks/useCompanies'
import { useTeamInvite, useTeams } from 'hooks/useTeam'
import { ICompany } from 'types/company.types'
import { ITeam } from 'types/team.types'
import { RoleType } from 'types/user.types'
import { getRoleText } from 'utils/team'

const { Text } = Typography

interface Props {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
}

interface FormValues {
  email: string
  teamId: number
  role: RoleType
  companies: number[]
}

function InviteTeamMateModal(props: Props) {
  const { onClose, open, onSuccess } = props
  const [form] = useForm<FormValues>()

  const { data: companies } = useCompanies()
  const { data: teams } = useTeams()
  const { invite: inviteTeammate, inviteLoading } = useTeamInvite()

  const [step, setStep] = useState(0)
  const [values, setValues] = useState<FormValues>({
    role: 0,
    email: '',
    teamId: 0,
    companies: [],
  })

  // Filter teams where user has admin access
  const adminTeams = useMemo(() => {
    return teams?.filter(team => {
      // Find the current user's membership in this team
      const currentUserMembership = team.members?.find(member =>
          member.role === RoleType.Admin
      );
      // Return true if the user is an admin in this team
      return !!currentUserMembership;
    });
  }, [teams]);

  // Set default team when teams are loaded
  useEffect(() => {
    if (adminTeams && adminTeams.length > 0 && step === 1) {
      form.setFieldValue('teamId', adminTeams[0].id);

      // Also update the values state
      setValues(prev => ({
        ...prev,
        teamId: adminTeams[0].id
      }));
    }
  }, [adminTeams, step, form]);

  const payload = useMemo(
      () => ({
        ...values,
        companies:
            values.companies.map((comp) => {
              const company = companies?.find((c) => c.id === comp)
              return { id: company?.id, name: company?.name }
            }) || [],
      }),
      [values, companies],
  )

  const onFinish = (formValues: FormValues) => {
    if (step === 4) {
      inviteTeammate(payload).then(() => {
        props.onClose()
        onSuccess?.()
      })
      return
    }

    let newPayload = { ...values, ...formValues }
    setValues(newPayload)
    setStep((prev) => prev + 1)
  }

  // Set initial team ID if teams are available
  const initialTeamId = adminTeams && adminTeams.length > 0 ? adminTeams[0].id : 0
  const initialValues: FormValues = { email: '', teamId: initialTeamId, role: RoleType.Member, companies: [] }

  // Fetch companies for the selected team
  useEffect(() => {
    if (values.teamId && companies) {
      // You might need to filter companies based on the selected team here
      // if your companies aren't already filtered by team
    }
  }, [values.teamId, companies])

  // on company access step active check to select/deselect all companies
  useEffect(() => {
    if (step !== 3) {
      return
    }

    const isAdminRole = values?.role === RoleType.Admin

    if (isAdminRole) {
      form.setFieldValue(
          'companies',
          companies?.map((comp) => comp.id),
      )
    } else {
      form.setFieldValue('companies', [])
    }
  }, [step])

  // on modal close reset form and state values
  useEffect(() => {
    if (!open) {
      setStep(0)
      setValues({ role: 0, email: '', teamId: initialTeamId, companies: [] })
      form.resetFields(['email', 'teamId', 'role', 'companies'])
    }
  }, [open, initialTeamId])

  return (
      <Modal
          width={700}
          open={open}
          onCancel={onClose}
          title="Invite teammate"
          okText={step === 4 ? 'Send Invite' : 'Next'}
          cancelText="Previous"
          okButtonProps={{ form: 'invite-form', htmlType: 'submit', loading: inviteLoading }}
          cancelButtonProps={{ htmlType: 'button', disabled: step === 0, onClick: () => setStep((prev) => prev - 1) }}
      >
        <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues} id="invite-form">
          <Steps
              current={step}
              onChange={setStep}
              size="small"
              items={[
                { title: 'Member Info' },
                { title: 'Team', disabled: step < 1 },
                { title: 'Member Role', disabled: step < 2 },
                { title: 'Company Access', disabled: step < 3 },
                { title: 'Review Details', disabled: step < 4 },
              ]}
              style={{ marginTop: '24px', marginBottom: '24px' }}
          />

          <Row gutter={16}>
            <Col span={24}>
              {step === 0 && <MemberInfoStep />}
              {step === 1 && <TeamSelectionStep teams={adminTeams} />}
              {step === 2 && <MemberRoleStep />}
              {step === 3 && <CompanyAccessStep companies={companies} disabled={values?.role === RoleType.Admin} />}
              {step === 4 && <ReviewDetailsStep {...payload} teams={teams} />}
            </Col>
          </Row>
        </Form>
      </Modal>
  )
}

function MemberInfoStep() {
  return (
      <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input valid email', type: 'email' }]}
      >
        <Input placeholder="example@gmail.com" />
      </Form.Item>
  )
}

function TeamSelectionStep({ teams }: { teams: ITeam[] | undefined }) {
  const teamOptions = teams?.map((team) => ({
    label: team.name,
    value: team.id,
  }));

  // Get the first team as default if available
  const defaultTeam = teams && teams.length > 0 ? teams[0].id : 0;

  return (
      <Form.Item
          label="Team"
          name="teamId"
          rules={[{ required: true, message: 'Please select a team' }]}
          initialValue={defaultTeam}
      >
        <Select
            placeholder="Select a team"
            options={teamOptions}
            optionFilterProp="label"
            showSearch
            defaultValue={defaultTeam}
            notFoundContent="No teams found where you have admin access"
        />
      </Form.Item>
  )
}

function MemberRoleStep() {
  const roleOptions: SelectProps['options'] = [
    {
      label: getRoleText(RoleType.Admin),
      value: RoleType.Admin,
      description:
          'Full access to Equility, and the ability to manage organization settings, company settings, and to add/delete users',
    },
    {
      label: getRoleText(RoleType.Member),
      value: RoleType.Member,
      description: 'Full access to Equility, and the ability to manage company settings',
    },
    {
      label: getRoleText(RoleType.ViewOnly),
      value: RoleType.ViewOnly,
      description: 'Read-only access to financial review reports and company analytics',
    },
  ]

  return (
      <Form.Item label="Role" name="role" rules={[{ required: true }]}>
        <Select
            placeholder="Select an option"
            options={roleOptions}
            optionFilterProp="label"
            optionRender={(option: any) => (
                <Flex vertical>
                  <Text>{option.label}</Text>
                  <Text type="secondary" style={{ whiteSpace: 'normal' }}>
                    {option.data.description}
                  </Text>
                </Flex>
            )}
        />
      </Form.Item>
  )
}

function CompanyAccessStep({ companies, disabled }: { companies: ICompany[] | undefined; disabled: boolean }) {
  const companyOptions = companies?.map((opt) => ({
    label: opt.name,
    value: opt.id,
  }))

  return (
      <Form.Item label="Companies" name="companies">
        <Select
            placeholder="Select companies"
            disabled={disabled}
            mode="multiple"
            showSearch={true}
            dropdownStyle={{ minWidth: 200 }}
            optionFilterProp="label"
            options={companyOptions}
        />
      </Form.Item>
  )
}

function ReviewDetailsStep(props: {
    role: number
    email: string
    teamId: number
    companies: { id: number | undefined; name: string | undefined }[]
    teams?: ITeam[]
}) {
    const selectedTeam = props.teams?.find(team => team.id === props.teamId)
    const teamName = selectedTeam?.name || 'Unknown team'

    // Filter out any companies with undefined names
    const validCompanies = props.companies.filter(comp => comp.name !== undefined)

    return (
        <Flex vertical gap="small">
            <Typography.Text>
                <b>Email</b>: {props?.email}
            </Typography.Text>
            <Typography.Text>
                <b>Team</b>: {teamName}
            </Typography.Text>
            <Typography.Text>
                <b>Role</b>: {props?.role !== undefined && getRoleText(props.role)}
            </Typography.Text>
            {validCompanies.length > 0 && (
                <Flex vertical>
                    <Typography.Text>
                        <b>Companies</b>: ({validCompanies.length})
                    </Typography.Text>
                    <Flex vertical style={{
                        maxHeight: '180px',
                        overflowY: 'auto',
                        border: '1px solid #f0f0f0',
                        borderRadius: '4px',
                        padding: '8px'
                    }}>
                        {validCompanies.map((company, index) => (
                            <Typography.Text key={index} style={{
                                display: 'inline-block',
                                marginRight: '8px',
                                marginBottom: '4px',
                                background: '#f5f5f5',
                                padding: '2px 8px',
                                borderRadius: '4px'
                            }}>
                                {company.name}
                            </Typography.Text>
                        ))}
                    </Flex>
                </Flex>
            )}
        </Flex>
    )
}

export default InviteTeamMateModal