import { useState, useEffect, useRef } from 'react'
import { SearchOutlined, DownloadOutlined, FileOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Flex, Row, Table, Input, Tag, Modal, List, Typography, Space, message } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import { api } from 'utils/axios'
import { formatDate } from 'utils/dates'
import { downloadBlob } from 'utils/download'
import { useAdminServices } from '../../hooks/useAdminServices'
import { IService, IServiceFile } from "../../types/service.types";

export function ServicesTab() {
    const services = useAdminServices()
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false)
    const [tableHeight, setTableHeight] = useState<number | string>('calc(100vh - 200px)')
    const containerRef = useRef<HTMLDivElement>(null)
    const [filesModalVisible, setFilesModalVisible] = useState(false)
    const [selectedService, setSelectedService] = useState<IService | null>(null)
    const [fileDetailLoading, setFileDetailLoading] = useState(false)
    const [serviceFiles, setServiceFiles] = useState<IServiceFile[]>([])

    // Adjust table height when window is resized
    useEffect(() => {
        const updateTableHeight = () => {
            if (containerRef.current) {
                const topOffset = containerRef.current.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;
                // Leave some space at the bottom
                setTableHeight(windowHeight - topOffset - 40);
            }
        };

        // Initial calculation
        updateTableHeight();

        // Add resize listener
        window.addEventListener('resize', updateTableHeight);

        // Cleanup
        return () => window.removeEventListener('resize', updateTableHeight);
    }, []);

    // Debug: Log data being received from the API
    useEffect(() => {
        if (services.data && services.data.length > 0) {
            console.log('Sample service data:', services.data[0]);
        }
    }, [services.data]);

    const download = async () => {
        setLoading(true)
        return api
            .get('admin/services/export', { responseType: 'blob' })
            .then((r) => downloadBlob(r.data, 'equility-services.xls'))
            .finally(() => setLoading(false))
    }

    // Move these functions inside the component so they have access to state
    const handleViewFiles = async (service: IService) => {
        setSelectedService(service)
        setFilesModalVisible(true)
        setFileDetailLoading(true)

        try {
            // Fetch service files if not already included in the service data
            if (!service.serviceFiles) {
                const response = await api.get(`admin/services/${service.id}/files`)
                setServiceFiles(response.data)
            } else {
                setServiceFiles(service.serviceFiles.map(sf => sf.file))
            }
        } catch (error) {
            console.error('Error fetching service files:', error)
            message.error('Failed to load service files')
        } finally {
            setFileDetailLoading(false)
        }
    }

    const downloadFile = async (file: IServiceFile) => {
        try {
            // Always use the backend endpoint to download the file
            const response = await api.get(`admin/files/${file.id}/download`, {
                responseType: 'blob'
            })
            downloadBlob(response.data, file.name)
            message.success('File downloaded successfully')
        } catch (error) {
            console.error('Error downloading file:', error)
            message.error('Failed to download file')
        }
    }

    const formatFileSize = (bytes: number) => {
        if (bytes === 0) return '0 Bytes'
        const k = 1024
        const sizes = ['Bytes', 'KB', 'MB', 'GB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    }

    const filteredData = services.data?.filter(service => {
        const searchLower = searchTerm.toLowerCase()
        return (
            service.companyName.toLowerCase().includes(searchLower) ||
            service.userName.toLowerCase().includes(searchLower) ||
            service.type.toLowerCase().includes(searchLower) ||
            service.status.toLowerCase().includes(searchLower)
        )
    })

    // Define columns inside the component to have access to handleViewFiles
    const columns: ColumnsType<IService> = [
        {
            title: 'Company',
            dataIndex: 'companyName',
            width: 200,
            ellipsis: true,
            sorter: (a, b) => a.companyName.localeCompare(b.companyName),
        },
        {
            title: 'User',
            dataIndex: 'userName',
            width: 200,
            ellipsis: true,
            sorter: (a, b) => a.userName.localeCompare(b.userName),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: 120,
            filters: [
                { text: 'Account', value: 'account' },
                { text: 'Ecommerce', value: 'ecommerce' },
                { text: 'Payroll', value: 'payroll' },
                { text: 'Depreciation', value: 'depreciation' }
            ],
            onFilter: (value, record) => record.type === value,
            render: (type) => {
                let color = 'blue';
                switch (type) {
                    case 'account':
                        color = 'green';
                        break;
                    case 'ecommerce':
                        color = 'orange';
                        break;
                    case 'payroll':
                        color = 'purple';
                        break;
                    case 'depreciation':
                        color = 'cyan';
                        break;
                }
                return <Tag color={color}>{type.toUpperCase()}</Tag>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 120,
            filters: [
                { text: 'Pending', value: 'pending' },
                { text: 'Processing', value: 'processing' },
                { text: 'Completed', value: 'completed' },
                { text: 'Failed', value: 'failed' }
            ],
            onFilter: (value, record) => record.status === value,
            render: (status) => {
                let color = 'default';
                switch (status) {
                    case 'pending':
                        color = 'warning';
                        break;
                    case 'processing':
                        color = 'processing';
                        break;
                    case 'completed':
                        color = 'success';
                        break;
                    case 'failed':
                        color = 'error';
                        break;
                }
                return <Tag color={color}>{status.toUpperCase()}</Tag>;
            },
        },
        {
            title: 'Files',
            dataIndex: 'fileCount',
            width: 80,
            sorter: (a, b) => a.fileCount - b.fileCount,
        },
        {
            title: 'Created',
            dataIndex: 'created_at',
            width: 150,
            sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            render: (date) => formatDate(date),
        },
        {
            title: 'Updated',
            dataIndex: 'updated_at',
            width: 150,
            sorter: (a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
            render: (date) => formatDate(date),
        },
        {
            title: 'ID',
            dataIndex: 'id',
            width: 250,
            ellipsis: true,
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 100,
            fixed: 'right' as const,
            render: (_, record) => (
                <Button
                    type="text"
                    icon={<FileOutlined />}
                    onClick={() => handleViewFiles(record)}
                    disabled={record.fileCount === 0}
                    title={record.fileCount === 0 ? "No files available" : "View files"}
                />
            )
        }
    ];

    return (
        <Flex vertical gap={10} style={{ height: '100%' }}>
            <Row gutter={24}>
                <Col span={8}>
                    <Input
                        placeholder="Search by company, user, type or status..."
                        prefix={<SearchOutlined />}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        allowClear
                    />
                </Col>
                <Col span={12}>
                    <Divider orientation="left">{filteredData?.length} total</Divider>
                </Col>
                <Col span={4}>
                    <Flex vertical justify="center" style={btnFlexStyle}>
                        <Button block onClick={download} loading={loading}>
                            Download
                        </Button>
                    </Flex>
                </Col>
            </Row>

            <div ref={containerRef} style={{ flex: 1, overflow: 'hidden' }}>
                <Table
                    dataSource={filteredData}
                    columns={columns}
                    rowKey="id"
                    loading={services.isLoading}
                    scroll={{ x: 'max-content', y: tableHeight }}
                    pagination={{
                        position: ['bottomCenter'],
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                    }}
                    size="middle"
                    style={{ flex: 1 }}
                />
            </div>

            {/* Files Modal */}
            <Modal
                title={`Files for ${selectedService?.type?.toUpperCase()} Service`}
                open={filesModalVisible}
                onCancel={() => setFilesModalVisible(false)}
                footer={[
                    <Button key="close" onClick={() => setFilesModalVisible(false)}>
                        Close
                    </Button>
                ]}
                width={700}
            >
                <div style={{ marginBottom: 16 }}>
                    <Typography.Text strong>Company:</Typography.Text> {selectedService?.companyName}<br />
                    <Typography.Text strong>User:</Typography.Text> {selectedService?.userName}<br />
                    <Typography.Text strong>Created:</Typography.Text> {selectedService ? formatDate(selectedService.created_at) : ''}
                </div>

                <List
                    loading={fileDetailLoading}
                    itemLayout="horizontal"
                    dataSource={serviceFiles}
                    locale={{ emptyText: 'No files found for this service' }}
                    renderItem={(file) => (
                        <List.Item
                            actions={[
                                <Button
                                    key="download"
                                    type="primary"
                                    size="small"
                                    icon={<DownloadOutlined />}
                                    onClick={() => downloadFile(file)}
                                >
                                    Download
                                </Button>
                            ]}
                        >
                            <List.Item.Meta
                                avatar={<FileOutlined style={{ fontSize: 24 }} />}
                                title={file.name}
                                description={
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text type="secondary">Type: {file.mime_type}</Typography.Text>
                                        <Typography.Text type="secondary">Size: {formatFileSize(file.size)}</Typography.Text>
                                    </Space>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Modal>
        </Flex>
    )
}

const btnFlexStyle: React.CSSProperties = {
    height: '100%',
}