import React, { useEffect, useState } from 'react'
import { App, Modal } from 'antd'
import { PlaidEmbeddedLink } from 'react-plaid-link'

import { usePlaidConnection, usePlaidLinkToken } from 'hooks/usePlaid'
import { useCompany } from 'hooks/useCompany'
import { ConnectionType } from 'types/company.types'
import { PlaidConnectionResponse } from '../types/plaid.type'

interface Props {
  open: boolean
  onClose: () => void
}

export function AddAccountModal(props: Props) {
  const { notification } = App.useApp()
  const { token: tokenData } = usePlaidLinkToken()
  const { company, revalidate } = useCompany(false)
  const { onConnect } = usePlaidConnection(revalidate)
  const [, setConnections] = useState<PlaidConnectionResponse[]>([])
  const { open, onClose } = props

  const onModalClose = () => {
    onClose()
  }

  useEffect(() => {
    const plaidItems = (company?.connections || []).filter(
      (i) => i.type.id === ConnectionType.Plaid,
    ) as PlaidConnectionResponse[]
    setConnections(plaidItems)
  }, [company?.connections])

  const handleConnect = async (token: string) => {
    if (!company?.id) return
    try {
      await onConnect(token, company.id, false)
      await revalidate()
    } catch (err) {
      console.error('Connection error:', err)
      notification.error({
        message: 'Connection Error',
        description: 'Failed to establish Plaid connection',
      })
    }
  }

  const onExit = (error: any, metadata: any) => {
    console.info('Exit:', error, metadata)
    if (error) {
      notification.error({ message: 'Error', description: error.display_message })
    }
  }

  const token = tokenData?.linkToken
  if (!token) return null

  return (
    <Modal
      title="Connect your banks and cards"
      open={open}
      onCancel={onModalClose}
      width={1000}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText="Close"
    >
      <PlaidEmbeddedLink
        token={token}
        style={plaidStyle}
        onSuccess={handleConnect}
        onEvent={console.log}
        onExit={onExit}
        key={token}
      />
    </Modal>
  )
}

const plaidStyle: React.CSSProperties = {
  height: 350,
}
