

const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

/*const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})*/

export function toNumber(value: number | string) {
  const v = getNumber(value)
  return numberFormatter.format(v)
}

const getLocaleByCurrency = (currency: string): string => {
  return currencyToLocaleMap[currency] || 'en-US';
}

export function toCurrency(value: number, currency: string, parenthesis = true) {
  if(currency === undefined || currency === '') currency = 'USD'
  const locale = getLocaleByCurrency(currency)
  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (value < 0) {
    const v = currencyFormatter.format(value).replace('-', '')
    if (v === `${currency}0.00`) return v;
    if (parenthesis) return '(' + v + ')'
    else return v
  }
  return currencyFormatter.format(value || 0)
}

export function toPercentage(value: number) {
  let v = value
  if (v === Infinity) v = 100
  if (v === -Infinity) v = -100
  return numberFormatter.format(v) + '%'
}

function getNumber(value: number | string) {
  return typeof value === 'string' ? parseFloat(value) || 0 : value || 0
}

export function toSafeDecimal(value: number | string) {
  return typeof value === 'string' ? parseFloat(parseFloat(value).toFixed(2)) : parseFloat(value.toFixed(2))
}



const currencyToLocaleMap: Record<string, string> = {
  AED: 'ar-AE',  AFN: 'fa-AF',  ALL: 'sq-AL',  AMD: 'hy-AM',  ANG: 'nl-CW',
  AOA: 'pt-AO',  ARS: 'es-AR',  AUD: 'en-AU',  AWG: 'nl-AW',  AZN: 'az-AZ',
  BAM: 'bs-BA',  BBD: 'en-BB',  BDT: 'bn-BD',  BGN: 'bg-BG',  BHD: 'ar-BH',
  BIF: 'fr-BI',  BMD: 'en-BM',  BND: 'ms-BN',  BOB: 'es-BO',  BRL: 'pt-BR',
  BSD: 'en-BS',  BTN: 'dz-BT',  BWP: 'en-BW',  BYN: 'be-BY',  BZD: 'en-BZ',
  CAD: 'en-CA',  CDF: 'fr-CD',  CHF: 'de-CH',  CLP: 'es-CL',  CNY: 'zh-CN',
  COP: 'es-CO',  CRC: 'es-CR',  CUP: 'es-CU',  CVE: 'pt-CV',  CZK: 'cs-CZ',
  DJF: 'fr-DJ',  DKK: 'da-DK',  DOP: 'es-DO',  DZD: 'ar-DZ',  EGP: 'ar-EG',
  ERN: 'ti-ER',  ETB: 'am-ET',  EUR: 'de-DE',  FJD: 'en-FJ',  FKP: 'en-FK',
  GBP: 'en-GB',  GEL: 'ka-GE',  GHS: 'en-GH',  GIP: 'en-GI',  GMD: 'en-GM',
  GNF: 'fr-GN',  GTQ: 'es-GT',  GYD: 'en-GY',  HKD: 'zh-HK',  HNL: 'es-HN',
  HRK: 'hr-HR',  HTG: 'fr-HT',  HUF: 'hu-HU',  IDR: 'id-ID',  ILS: 'he-IL',
  INR: 'hi-IN',  IQD: 'ar-IQ',  IRR: 'fa-IR',  ISK: 'is-IS',  JMD: 'en-JM',
  JOD: 'ar-JO',  JPY: 'ja-JP',  KES: 'en-KE',  KGS: 'ky-KG',  KHR: 'km-KH',
  KMF: 'fr-KM',  KPW: 'ko-KP',  KRW: 'ko-KR',  KWD: 'ar-KW',  KYD: 'en-KY',
  KZT: 'kk-KZ',  LAK: 'lo-LA',  LBP: 'ar-LB',  LKR: 'si-LK',  LRD: 'en-LR',
  LSL: 'en-LS',  LTL: 'lt-LT',  LVL: 'lv-LV',  LYD: 'ar-LY',  MAD: 'ar-MA',
  MDL: 'ro-MD',  MGA: 'mg-MG',  MKD: 'mk-MK',  MMK: 'my-MM',  MNT: 'mn-MN',
  MOP: 'zh-MO',  MRU: 'ar-MR',  MUR: 'en-MU',  MVR: 'dv-MV',  MWK: 'en-MW',
  MXN: 'es-MX',  MYR: 'ms-MY',  MZN: 'pt-MZ',  NAD: 'en-NA',  NGN: 'en-NG',
  NIO: 'es-NI',  NOK: 'nb-NO',  NPR: 'ne-NP',  NZD: 'en-NZ',  OMR: 'ar-OM',
  PAB: 'es-PA',  PEN: 'es-PE',  PGK: 'en-PG',  PHP: 'fil-PH', PKR: 'ur-PK',
  PLN: 'pl-PL',  PYG: 'es-PY',  QAR: 'ar-QA',  RON: 'ro-RO',  RSD: 'sr-RS',
  RUB: 'ru-RU',  RWF: 'rw-RW',  SAR: 'ar-SA',  SBD: 'en-SB',  SCR: 'en-SC',
  SDG: 'ar-SD',  SEK: 'sv-SE',  SGD: 'en-SG',  SHP: 'en-SH',  SLL: 'en-SL',
  SOS: 'so-SO',  SPL: 'en-SX',  SRD: 'nl-SR',  SSP: 'en-SS',  STN: 'pt-ST',
  SVC: 'es-SV',  SYP: 'ar-SY',  SZL: 'en-SZ',  THB: 'th-TH',  TJS: 'tg-TJ',
  TMT: 'tk-TM',  TND: 'ar-TN',  TOP: 'to-TO',  TRY: 'tr-TR',  TTD: 'en-TT',
  TWD: 'zh-TW',  TZS: 'sw-TZ',  UAH: 'uk-UA',  UGX: 'en-UG',  USD: 'en-US',
  UYU: 'es-UY',  UZS: 'uz-UZ',  VES: 'es-VE',  VND: 'vi-VN',  VUV: 'bi-VU',
  WST: 'sm-WS',  XAF: 'fr-CM',  XCD: 'en-AG',  XOF: 'fr-SN',  XPF: 'fr-PF',
  YER: 'ar-YE',  ZAR: 'af-ZA',  ZMW: 'en-ZM',  ZWL: 'en-ZW'
};

