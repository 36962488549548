import { useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import dayjs from 'dayjs'
import { SendOutlined, UploadOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Flex, Input, List, Typography, Upload } from 'antd'
import { useComments } from 'constants/useComments'
import { useFileManager } from 'hooks/useFileManager'
import { getFileNameFromUrlWithQuery } from 'utils/form'
import { useInfiniteComments } from 'hooks/useInfiniteComments'

type TaskCommentsProps = {
  entityId: number
  entityType: string
}

export function TaskComments(props: TaskCommentsProps) {
  const { entityId, entityType } = props
  const buttonRef = useRef<HTMLButtonElement>(null)
  const listContainer = useRef<HTMLDivElement>(null)

  const [comment, setComment] = useState('')
  const [fileList, setFileList] = useState<any[]>([])

  const { comments, commentsLoading, totalCount, refetch, loadMoreComments } = useInfiniteComments({
    pagination: { pageSize: 5 },
    entityId,
    entityType,
  })

  const { createComment, createLoading } = useComments()
  const { deleteSingle, uploadSingle } = useFileManager()

  // TODO: fix type
  const onCustomUpload = async (props: any) => {
    const res = await uploadSingle(props.file)
    props.onSuccess(res, props.file)
  }
  // TODO: fix type
  const onCustomDelete = (e: any) => {
    const file = e.response
    deleteSingle({ file })
    return true
  }

  const scrollToTheLastComment = () => {
    const el = listContainer.current
    el && window.requestAnimationFrame(() => (el.scrollTop = el.scrollHeight))
  }

  const onCreateComment = () => {
    const files = fileList.map((file) => file?.response)

    if (!comment.trim() && !files.length) {
      return
    }

    createComment(
      { entityId, entityType, body: comment, files },
      {
        onSuccess: () => {
          setComment('')
          setFileList([])
          refetch().then(() => {
            scrollToTheLastComment()
          })
        },
      },
    )
  }

  return (
    <div className="taskComments">
      {totalCount ? (
        <Typography.Text>
          {totalCount} {totalCount > 1 ? 'comments' : 'comment'}
        </Typography.Text>
      ) : null}

      {comments.length ? (
        <div
          ref={listContainer}
          id="scrollableDiv"
          style={{ maxHeight: 300, overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse' }}
        >
          <InfiniteScroll
            scrollThreshold={200}
            dataLength={comments.length}
            next={loadMoreComments}
            hasMore={totalCount ? totalCount > comments.length : false}
            inverse={true}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            <List
              dataSource={comments}
              loading={commentsLoading}
              style={{
                overflow: 'hidden',
                padding: '4px 4px 4px 0px',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
              renderItem={(item) =>
                item &&
                item.user && (
                  <li style={{ marginBottom: '10px' }}>
                    <Card>
                      <Card.Meta
                        avatar={<Avatar src={item.user.photoUrl} />}
                        title={`${item.user.firstName} ${item.user.lastName}`}
                        description={
                          <div>
                            {item.body}
                            {!!item.files?.length &&
                              item.files.map((file: any, index: number) => (
                                <div
                                  key={`file-${index}`}
                                  style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}
                                >
                                  <a href={file} rel="noreferrer" target="_blank">
                                    {getFileNameFromUrlWithQuery(file)}
                                  </a>
                                </div>
                              ))}
                          </div>
                        }
                      />
                      <div style={{ marginTop: 10, textAlign: 'right', fontSize: 12 }}>
                        {dayjs(item.createdAt).fromNow()}
                      </div>
                    </Card>
                  </li>
                )
              }
            />
          </InfiniteScroll>
        </div>
      ) : null}

      <Input.TextArea
        placeholder="Add a comment..."
        autoSize={{ minRows: 3 }}
        onChange={(e) => setComment(e.target.value)}
        value={comment}
      />

      <Flex style={{ marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <Upload
          customRequest={onCustomUpload}
          onRemove={onCustomDelete}
          listType="text"
          fileList={fileList}
          onChange={(e) => setFileList(e.fileList)}
          multiple
        >
          <Button style={{ display: 'none' }} icon={<UploadOutlined />} ref={buttonRef} />
        </Upload>

        <Flex style={{ marginTop: 10 }}>
          <Button icon={<UploadOutlined />} onClick={() => buttonRef.current?.click()} />
          <Button
            type="primary"
            icon={<SendOutlined />}
            loading={createLoading}
            onClick={onCreateComment}
            style={{ marginLeft: 10 }}
          />
        </Flex>
      </Flex>
    </div>
  )
}
