import { useState, useMemo } from 'react'
import { Button, Flex, Tabs, Input, Tooltip, Select } from 'antd'
import { Tab } from 'rc-tabs/lib/interface'
import { Page } from 'components/Page'
import AcceptedMembers from './AcceptedMembers'
import InvitedMembers from './InvitedMembers'
import InviteTeamMateModal from 'components/InviteTeammateModal'
import TeamSettings from './TeamSettings'
import { useUser } from 'hooks/useUser'
import { IAcceptedMember, RoleType } from 'types/user.types'
import {
  useTeam,
  useTeamInvites,
  useTeamRescindInvite,
  useTeamResendInvite,
} from 'hooks/useTeam'
import { analytics } from '../../utils/analytics'

const { Search } = Input

export function TeamPage() {
  const [activeTab, setActiveTab] = useState('1')
  const [inviteModalVisible, setInviteModalVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  // State to hold the currently selected team ID.
  const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null)

  const { user } = useUser()
  // useTeam hook returns teams along with a refetch function.
  const {
    data: teams,
    isLoading: teamsLoading,
    deleteLoading,
    deleteMember,
    updateRole,
    updateRoleLoading,
    updateCompanyAccess,
    updateCompanyAccessLoading,
    refetchTeam,
  } = useTeam()

  const { resendInvite, resendInviteLoading } = useTeamResendInvite()
  const { rescindInvite, rescindInviteLoading } = useTeamRescindInvite()

  // If no team is selected, default to the first team in the list.
  const selectedTeam =
      teams && (selectedTeamId ? teams.find((team: any) => team.id === selectedTeamId) : teams[0])

  // Use team-specific invites hook
  const {
    data: invites,
    isLoading: invitesLoading,
    refetch: refetchInvites,
  } = useTeamInvites(selectedTeam?.id)

  // Combine owner and members for the selected team
  const acceptedMembers = selectedTeam ? selectedTeam.members || [] : []
  
  // Create a combined list of members and owner
  const combinedMembers = useMemo(() => {
    if (!selectedTeam || !selectedTeam.owner) return acceptedMembers;
    
    // Check if owner is already in the members list
    const ownerInMembers = acceptedMembers.some(
      (member: IAcceptedMember) => member.user.id === selectedTeam.owner?.id
    );
    
    if (ownerInMembers) return acceptedMembers;
    
    // Create an owner member object
    const ownerMember: IAcceptedMember = {
      id: -1, // Use a special ID for the owner
      role: RoleType.Owner, // Use the Owner role type
      user: {
        ...selectedTeam.owner,
        companies: [] // Initialize with empty companies array
      }
    };
    
    return [ownerMember, ...acceptedMembers];
  }, [selectedTeam, acceptedMembers]);
  
  // Filter combined members for the selected team using the search query.
  const filteredAcceptedMembers = combinedMembers.filter((item: IAcceptedMember) => {
    const searchValue = searchQuery.toLowerCase()
    return (
        item.user?.firstName.toLowerCase().includes(searchValue) ||
        item.user?.lastName.toLowerCase().includes(searchValue) ||
        item.user?.email.toLowerCase().includes(searchValue)
    )
  })

  // Filter invited members using the search query
  const filteredInvitedMembers = invites?.filter((item: any) => {
    const searchValue = searchQuery.toLowerCase()
    return item.email.toLowerCase().includes(searchValue)
  })

  const isAdminUser = user?.member?.role === RoleType.Admin

  analytics.page()

  // Define the base tab items.
  const items: Tab[] = [
    {
      key: '1',
      label: 'Team members',
      children: (
          <AcceptedMembers
              data={filteredAcceptedMembers}
              loading={teamsLoading}
              isAdminUser={isAdminUser}
              teamId={selectedTeam?.id}
              deleteLoading={deleteLoading}
              deleteMember={deleteMember}
              updateRole={updateRole}
              updateRoleLoading={updateRoleLoading}
              updateCompanyAccess={updateCompanyAccess}
              updateCompanyAccessLoading={updateCompanyAccessLoading || teamsLoading}
          />
      ),
    },
    {
      key: '2',
      label: 'Invited',
      children: (
          <InvitedMembers
              data={filteredInvitedMembers}
              loading={invitesLoading || resendInviteLoading || rescindInviteLoading}
              teamId={selectedTeam?.id}
              resendInvite={(params) =>
                  resendInvite({
                    inviteId: params.inviteId,
                    teamId: selectedTeam?.id
                  })
              }
              rescindInvite={(params) =>
                  rescindInvite({
                    inviteId: params.inviteId,
                    teamId: selectedTeam?.id
                  }, { onSuccess: refetchInvites })
              }
          />
      ),
    },
  ]

  // Add a Settings tab if the user is an admin.
  if (isAdminUser) {
    items.push({
      key: '3',
      label: 'Settings',
      children: selectedTeam ? (
          <TeamSettings team={selectedTeam} refetchTeam={refetchTeam} />
      ) : (
          'No team selected'
      ),
    })
  }

  const tabItems = items

  const onInviteSuccess = () => {
    setActiveTab('2')
    refetchInvites()
  }

  return (
      <Page title="Team Management">
        <Flex vertical gap={10}>
          <Flex style={headerStyle} gap={20} align="center" justify="space-between">
            {/* Dropdown to select a team */}
            <Select
                placeholder="Select team"
                value={selectedTeam?.id}
                onChange={(value) => setSelectedTeamId(value)}
                style={{ width: 200 }}
                options={
                    teams?.map((team: any) => ({
                      label: team.name,
                      value: team.id,
                    })) || []
                }
            />
            <Search
                style={searchStyle}
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Tooltip
                title={
                    !isAdminUser &&
                    "This functionality is only available to users with an 'Admin' role"
                }
            >
              <Button
                  type="primary"
                  onClick={() => setInviteModalVisible(true)}
                  disabled={!isAdminUser}
              >
                Invite a team member
              </Button>
            </Tooltip>
          </Flex>
          <Tabs activeKey={activeTab} onChange={setActiveTab} items={tabItems} />
          <InviteTeamMateModal
              open={inviteModalVisible}
              onClose={() => setInviteModalVisible(false)}
              onSuccess={onInviteSuccess}
          />
        </Flex>
      </Page>
  )
}

const headerStyle: React.CSSProperties = {
  marginTop: 10,
}

const searchStyle: React.CSSProperties = {
  width: 300,
}
