import { Tabs, TabsProps } from 'antd'

import { Page } from 'components/Page'
import { Advisory } from './Advisory'
import { analytics } from '../../utils/analytics'
import { useFlags } from 'flagsmith/react'

export function HelpPage() {
  analytics.page()
  const flags = useFlags(['disable_faq'])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Advisory',
      children: <Advisory />,
    },
  ]

  if (!flags.disable_faq.enabled) {
    items.push({
      key: '2',
      label: 'FAQs',
      children: 'Under construction',
    })
  }

  return (
      <Page title="Help">
        <Tabs defaultActiveKey="1" items={items} />
      </Page>
  )
}