// Helper function to format dates for the API
export function formatDateForApi(dateStr: string): string {
    const date = new Date(dateStr)
    return date.toISOString()
}

// Update the URL generator to handle various ID types
export function getTransactionsApiUrl(params: {
    companyId: string | number,
    accountId: string,
    from: string,
    to: string
}): string {
    const { companyId, accountId, from, to } = params
    return `/banks/transactions/?companyId=${companyId}&accountId=${accountId}&from=${formatDateForApi(from)}&to=${formatDateForApi(to)}`
}