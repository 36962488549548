import { useEffect, useRef, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { Col, Flex, Row } from 'antd'

import { FullLoader } from 'components/FullLoader'
import { Details } from './components/Details'
import { Stats } from './components/Stats'
import { TxList } from './components/TxList'

import { useMatcher } from 'hooks/useMatcher'
import { useReconcile } from 'hooks/useReconcile'
import { useWebSocket } from "../../../hooks/useWebsockets"

export function RecResult() {
  const match = useMatch('company/:companyId/reconcile/result/:id')
  const id = match?.params?.id ? parseInt(match.params.id) : null
  const currency = ''
  const rec = useReconcile({ id })
  const matcher = useMatcher()
  const [loading, setLoading] = useState(false)

  // Get WebSocket connection hooks
  const { ensureConnected } = useWebSocket()

  // Shared ref to track if WebSocket handlers are already registered
  // This prevents both TxList components from registering the same handlers
  const webSocketHandlersRegisteredRef = useRef(false)

  // Keep track if we already performed matching
  const matchingPerformedRef = useRef(false)

  // Track whether connection has been established
  const connectionAttemptedRef = useRef(false)

  // Establish WebSocket connection once when component mounts
  useEffect(() => {
    // Only attempt to connect once
    if (connectionAttemptedRef.current) return;

    let isMounted = true
    connectionAttemptedRef.current = true;

    const setupWebSocket = async () => {
      try {
        console.log('Establishing WebSocket connection for transaction reconciliation...')
        const connected = await ensureConnected()

        if (isMounted) {
          if (connected) {
            console.log('WebSocket connection established successfully')
          } else {
            console.warn('Could not establish WebSocket connection for transaction reconciliation')
          }
        }
      } catch (error) {
        console.error('Error establishing WebSocket connection:', error)
        // Reset flag to allow retry on next render if needed
        if (isMounted) {
          connectionAttemptedRef.current = false;
        }
      }
    }

    setupWebSocket()

    // Cleanup function
    return () => {
      isMounted = false
      // Do NOT reset connectionAttemptedRef here to prevent reconnection attempts
      // during component re-renders
    }
  }, [ensureConnected])

  // Initialize matcher with reconciliation data
  useEffect(() => {
    if (!rec.data || matchingPerformedRef.current) return

    console.info('Matching transactions...')
    const data = rec.data.temp_data
    matcher.matchAll(data.qbo, data.bank, rec.data.dayrange)

    // Mark matching as performed to prevent repeated calls
    matchingPerformedRef.current = true
  }, [rec.data, matcher])

  // Global cleanup when component unmounts
  useEffect(() => {
    return () => {
      // Reset the WebSocket handlers registration flag on unmount
      webSocketHandlersRegisteredRef.current = false;
      // Reset matching performed flag
      matchingPerformedRef.current = false;
    }
  }, []);

  if (!rec.data || rec.isLoading) return <FullLoader />

  return (
      <Flex vertical>
        <Row gutter={20}>
          <Col span={8}>
            <Details data={rec.data} matcher={matcher} />
          </Col>
          <Col span={16}>
            <Stats data={rec.data} matcher={matcher} currency={currency}/>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <TxList
                matcher={matcher}
                type="qbo"
                title="QuickBooks Online Transactions"
                rec={rec.data}
                loading={loading}
                onLoading={setLoading}
                webSocketHandlersRegistered={webSocketHandlersRegisteredRef}
            />
          </Col>
          <Col span={12}>
            <TxList
                matcher={matcher}
                type="bank"
                title="Source Data Transactions"
                rec={rec.data}
                loading={loading}
                onLoading={setLoading}
                webSocketHandlersRegistered={webSocketHandlersRegisteredRef}
            />
          </Col>
        </Row>
      </Flex>
  )
}