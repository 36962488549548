import React, {useCallback, useEffect, useState} from 'react'
import {Alert, App, Button, Card, Flex, Select, Spin, Typography} from 'antd'
import {formatAccountBalance, useAccounts, useUpdateAccounts} from "../hooks/useAccounts"
import {PlaidConnectionResponse} from "../types/plaid.type"
import {StatusTag} from "../utils/bank-feed-states";
import {usePlaidLink} from "react-plaid-link";
import {ReloadOutlined, SettingOutlined} from "@ant-design/icons";
import {usePlaidConnection, usePlaidLinkTokenUpdate, usePlaidBankFeeds} from "../hooks/usePlaid";
import type {IPlaidLinkToken} from "../types/user.types";


const { Text } = Typography

interface AccountMatchingProps {
    connections: PlaidConnectionResponse[]
    isSetup?: boolean
    onComplete?: () => void
    onDelete?: (connection: PlaidConnectionResponse) => void
    loading?: boolean
    companyId?: number
    isAllowed?: boolean
}

export const AccountMatching: React.FC<AccountMatchingProps> = ({
                                                                    connections,
                                                                    isSetup = false,
                                                                    onComplete,
                                                                    onDelete,
                                                                    companyId,
                                                                    loading = false,
                                                                    isAllowed = true
                                                                }) => {
    const { accounts, isLoading: accountsLoading, revalidate: revalidateAccounts } = useAccounts(companyId)
    const { updateAccounts, updateAccountsLoading } = useUpdateAccounts(companyId)
    const [pendingMappings, setPendingMappings] = useState<Record<string, string>>({})
    const [hasChanges, setHasChanges] = useState(false)
    const [updatingConnection, setUpdatingConnection] = useState<string | null>(null);
    const { onConnect } = usePlaidConnection(revalidateAccounts)
    const { refresh: refreshBankFeed } = usePlaidBankFeeds(companyId)
    const [refreshingConnection, setRefreshingConnection] = useState<string | null>(null)
    const {  notification } = App.useApp();

    const bankAccounts = accounts.filter(account => account.isBank)

    const { token, isLoading: tokenLoading } = usePlaidLinkTokenUpdate(
        companyId && updatingConnection ? {
            companyId,
            bankFeedId: updatingConnection
        } : undefined
    );

    // Add effect to handle beforeunload when there are pending changes in setup mode
    useEffect(() => {
        if (isSetup && hasChanges) {
            const handleBeforeUnload = async (e: BeforeUnloadEvent) => {
                e.preventDefault()
                await submitMappings()
                return undefined
            }

            window.addEventListener('beforeunload', handleBeforeUnload)
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload)
            }
        }
    }, [isSetup, hasChanges])

    // Add effect to autosave when navigating away in setup mode
    useEffect(() => {
        if (isSetup) {
            return () => {
                if (hasChanges) {
                    submitMappings()
                }
            }
        }
    }, [isSetup, hasChanges])

    const handleAccountMatch = (plaidAccountId: string, qboAccountId: string) => {
        // Check if this QBO account is already mapped to a different Plaid account
        const isDuplicate = Object.entries(pendingMappings).some(
            ([existingPlaidId, existingPlatformId]) =>
                existingPlatformId === qboAccountId && existingPlaidId !== plaidAccountId
        )

        if (isDuplicate) {
            notification.error({
                message: 'Invalid Mapping',
                description: 'This QuickBooks account is already mapped to another bank feed account.',
            })
            return
        }

        setPendingMappings(prev => ({
            ...prev,
            [plaidAccountId]: qboAccountId
        }))
        setHasChanges(true)
    }

    const getSelectedValue = (plaidAccountId: string) => {
        // First check pending mappings
        const pendingValue = pendingMappings[plaidAccountId]
        if (pendingValue) {
            return pendingValue
        }

        // Then check for existing mapping in QBO accounts
        const matchedQboAccount = bankAccounts.find(qboAccount =>
            qboAccount.bankFeedAccountId === plaidAccountId
        )

        return matchedQboAccount?.platformId
    }

    const submitMappings = async () => {
        if (!hasChanges || Object.keys(pendingMappings).length === 0) {
            console.warn('No changes to submit');
            return;
        }

        // Prepare  updates the payload.
        const updates = Object.entries(pendingMappings).map(
            ([bankFeedAccountId, accountId]) => ({
                accountId,
                bankFeedAccountId,
            })
        );

        try {
            await updateAccounts(updates);
            await revalidateAccounts();

            setHasChanges(false);
            setPendingMappings({});

            notification.success({
                message: 'Success',
                description: 'Account mappings updated successfully',
            });
        } catch (error) {
            console.error('Error updating account mappings', error);
            notification.error({
                message: 'Error',
                description: 'Failed to update account mappings',
            });
        }
    };

    const handleComplete = async () => {
        if (hasChanges) {
            await submitMappings()
        }
        onComplete?.()
    }

    // Plaid Link configuration
    const onSuccess = useCallback(async (token: string) => {
        if (!companyId) return;
        try {
            await onConnect(token, companyId,true);
            await revalidateAccounts();
            notification.success({
                message: 'Success',
                description: 'Bank connection updated successfully',
            });
        } catch (error) {
            console.error('Error updating connection:', error);
        } finally {
            setUpdatingConnection(null);
        }
    }, [companyId, onConnect, revalidateAccounts]);

    const onExit = useCallback(() => {
        setUpdatingConnection(null);
    }, []);

    const { open, ready } = usePlaidLink({
        token: (token as IPlaidLinkToken)?.linkToken || '',
        onSuccess,
        onExit,
    });

    // Effect to open Plaid when token is ready
    useEffect(() => {
        if ((token as IPlaidLinkToken)?.linkToken && ready) {
            open();
        }
    }, [token, ready, open]);

    const handleUpdateConnection = (connection: PlaidConnectionResponse) => {
        setUpdatingConnection(connection.connectionInfo?.id);
    };

    const handleRefreshConnection = async (connection: PlaidConnectionResponse) => {
        if (!connection.connectionInfo?.id) return;

        setRefreshingConnection(connection.connectionInfo.id);
        try {
            await refreshBankFeed(connection.connectionInfo.id);
            await revalidateAccounts();
        } finally {
            setRefreshingConnection(null);
        }
    };

    // First handle all loading states
    if (accountsLoading || updateAccountsLoading) {
        return <Spin />
    }

    // Then check for bank connections
    if (!connections?.length) {
        return <Text>No bank connections available. Please connect a bank account to proceed.</Text>
    }
    return (
        <Flex vertical gap={16}>
            {!accounts.length && (
                <Alert
                    message="QuickBooks Accounts Missing"
                    description="To map your bank accounts, please connect your QuickBooks company."
                    type="warning"
                    showIcon
                />
            )}
            <Text>
                Match your bank accounts with your QuickBooks accounts to ensure proper synchronization.
            </Text>

            {connections.map((connection) => {
                const bankFeeds = connection.connectionInfo?.accounts || []

                return (
                    <Card
                        key={connection.keyId}
                        size="small"
                        title={connection.connectionInfo?.name}
                        extra={
                            <Flex gap={20} align="center" justify="center">
                                <StatusTag state={connection.connectionInfo?.state} />
                                <Button
                                    icon={<ReloadOutlined />}
                                    size="small"
                                    onClick={() => handleRefreshConnection(connection)}
                                    loading={refreshingConnection === connection.connectionInfo?.id}
                                    disabled={!isAllowed}
                                >
                                    Refresh
                                </Button>

                                <Button
                                    icon={<SettingOutlined />}
                                    size="small"
                                    onClick={() => handleUpdateConnection(connection)}
                                    loading={tokenLoading && updatingConnection === connection.connectionInfo.id}
                                    disabled={!isAllowed}
                                >
                                    Manage
                                </Button>
                                {onDelete && (
                                    <Button
                                        danger
                                        size="small"
                                        onClick={() => onDelete(connection)}
                                        loading={loading}
                                        disabled={!isAllowed}
                                    >
                                        Delete
                                    </Button>
                                )}
                            </Flex>
                        }
                    >
                        <Flex vertical gap={16}>
                            {bankFeeds.map((bankFeed) => {
                                const selectedValue = getSelectedValue(bankFeed.id)
                                return (
                                    <Card
                                        key={bankFeed.id}
                                        size="small"
                                        type="inner"
                                        title={
                                            <Flex justify="space-between" align="center">
                                                <Text>{bankFeed.name}</Text>
                                                <Text type="secondary">*{bankFeed.number || ''}</Text>
                                            </Flex>
                                        }
                                    >
                                        <Flex vertical gap={8}>
                                            <Text type="secondary">Select matching QuickBooks account:</Text>
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Select QuickBooks account"
                                                value={selectedValue}
                                                onChange={(value) => handleAccountMatch(bankFeed.id, value)}
                                                options={bankAccounts.map((qboAccount) => {
                                                    const isAlreadyMapped = Object.entries(pendingMappings).some(
                                                        ([existingPlaidId, existingPlatformId]) =>
                                                            existingPlatformId === qboAccount.platformId &&
                                                            existingPlaidId !== bankFeed.id
                                                    )

                                                    return {
                                                        label: (
                                                            <Flex
                                                                justify="space-between"
                                                                align="center"
                                                                style={{ width: '100%', minWidth: '300px', gap: '24px' }}
                                                            >
                                                                <Text style={{ flex: 1 }}>{qboAccount.name}</Text>
                                                                <Text type="secondary" style={{ whiteSpace: 'nowrap' }}>
                                                                    {formatAccountBalance(qboAccount.balance)}
                                                                </Text>
                                                            </Flex>
                                                        ),
                                                        value: qboAccount.platformId,
                                                        disabled: isAlreadyMapped
                                                    }
                                                })}
                                            />
                                        </Flex>
                                    </Card>
                                )
                            })}
                        </Flex>
                    </Card>
                )
            })}

            {(!isSetup || hasChanges) && (
                <Flex justify="end" gap={8}>
                    <Button onClick={handleComplete} loading={updateAccountsLoading}>
                        Save Changes
                    </Button>
                </Flex>
            )}
        </Flex>
    )
}