import { useState, useEffect } from 'react'
import { api } from 'utils/axios'
import { useCompany } from 'hooks/useCompany'

export interface IQboClass {
  Id: string
  Name: string
  SubClass: boolean
  ParentRef?: {
    value: string
    name?: string
  }
  FullyQualifiedName: string
  Active: boolean
}

export interface IHierarchicalClass extends IQboClass {
  children: IHierarchicalClass[]
  level: number
}

export function useClasses() {
  const { company } = useCompany()
  const [classes, setClasses] = useState<IQboClass[]>([])
  const [hierarchicalClasses, setHierarchicalClasses] = useState<IHierarchicalClass[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  // Transform flat classes into hierarchical structure
  useEffect(() => {
    if (!classes.length) return;
    
    const buildHierarchy = () => {
      // Create a map for quick lookup
      const classMap = new Map<string, IHierarchicalClass>();
      
      // First pass: create class objects with proper structure
      classes.forEach(cls => {
        classMap.set(cls.Id, {
          ...cls,
          children: [],
          level: 0
        });
      });
      
      // Second pass: build hierarchy
      const rootClasses: IHierarchicalClass[] = [];
      classes.forEach(cls => {
        const currentClass = classMap.get(cls.Id);
        if (!currentClass) return;
        
        if (cls.SubClass && cls.ParentRef?.value) {
          // This is a child class
          const parent = classMap.get(cls.ParentRef.value);
          if (parent) {
            currentClass.level = parent.level + 1;
            parent.children.push(currentClass);
          } else {
            // If parent not found, add to root
            rootClasses.push(currentClass);
          }
        } else {
          // This is a root class
          rootClasses.push(currentClass);
        }
      });
      
      setHierarchicalClasses(rootClasses);
    };
    
    buildHierarchy();
  }, [classes]);

  useEffect(() => {
    if (!company?.id) return;

    setLoading(true)
    setError(null)

    api
      .get(`/accounting/classes?companyId=${company.id}`)
      .then((response) => {
        setClasses(response.data || [])
      })
      .catch((err) => {
        console.error('Error fetching QuickBooks classes:', err)
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [company?.id])

  // Helper function to flatten hierarchical classes for dropdown
  const getFlattenedClasses = (): { key: string; value: string }[] => {
    const flatten = (classes: IHierarchicalClass[], result: { key: string; value: string }[] = []) => {
      classes.forEach(cls => {
        // Add indent based on level
        const indent = '　'.repeat(cls.level);
        result.push({ key: cls.Id, value: `${indent}${cls.Name}` });
        
        if (cls.children?.length) {
          flatten(cls.children, result);
        }
      });
      return result;
    };
    
    return flatten(hierarchicalClasses);
  };

  return { 
    classes, 
    hierarchicalClasses, 
    flattenedClasses: getFlattenedClasses(),
    loading, 
    error 
  }
}