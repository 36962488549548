import {useState, useEffect, useRef} from 'react'
import { Button, Divider, Flex, List, Typography, App } from 'antd'

import { CardIntegration } from 'components/CardIntegration'
import { MatchAccounts } from 'components/MatchAccounts'

import { useNewCompany } from '.'
import { useAccountsById } from 'hooks/useAccounts'

import { api } from 'utils/axios'
import { getErrorMsg } from 'utils/geterror'
import { ConnectionType } from 'types/company.types'
import { ConnectionAction, IConnectionItem } from 'types/connection.types'
import ShopifyConnectModal from '../../components/ShopifyConnectModal'
import { useFlags } from 'flagsmith/react'
import {useLocation} from "react-router-dom";

const { Text } = Typography

interface IProps {
  onNext: () => void
  onBack?: () => void
}

export function IntegrationStep(props: IProps) {
  const {notification } = App.useApp();
  const company = useNewCompany(s => s.company);
  const setCompany = useNewCompany(s => s.setCompany);
  const accounts = useAccountsById(company?.id);
  const [loading, setLoading] = useState(false);
  const [shopifyModalVisible, setShopifyModalVisible] = useState(false);
  const [localMatched, setLocalMatched] = useState<{ [key: number]: Matched }>({});
  const flags = useFlags(['hide_shopify'])
  const location = useLocation()
  const pendingChangesRef = useRef<{
    connectionId: number;
    value: Matched;
  } | null>(null)

  const saveChanges = async (changes: { connectionId: number; value: Matched } | null) => {
    if (!changes || !company?.id) return;

    try {
      await api.patch(
          `companies/${company.id}/connection/${changes.connectionId}`,
          {
            settings: {
              shopify: {
                matched: changes.value,
              },
            },
          }
      );
      pendingChangesRef.current = null;
    } catch (error) {
      console.error('Failed to save changes:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to save account matching changes'
      });
    }
  };

  const shopifyConnections = (company?.connections || [])
      .filter((i) => i.type.id === ConnectionType.Shopify);

  const connectShopify = async (shopifyStoreUrl: string) => {
    if (!company?.id) {
      notification.error({ message: 'Something went wrong' });
      return;
    }
    if (!shopifyStoreUrl) {
      notification.error({ message: 'Please enter your Shopify store URL' });
      return;
    }

    const storeExists = shopifyConnections.some(
        conn => conn.connectionInfo?.store?.domain === shopifyStoreUrl ||
            conn.connectionInfo?.realmId === shopifyStoreUrl,
    );

    if (storeExists) {
      notification.error({ message: 'This Shopify store is already connected' });
      return;
    }

    setLoading(true);
    try {
      const response = await api.post(`companies/${company.id}/connection/link`, {
        provider: 'Shopify',
        realmId: shopifyStoreUrl
      });

      const authUrl = response.data.url;

      if (authUrl) {
        const authWindow = window.open(authUrl, '_blank', 'width=600,height=600');

        if (authWindow) {
          const pollTimer = setInterval(async () => {
            if (authWindow.closed) {
              clearInterval(pollTimer);
              const updatedCompany = await api.get(`companies/${company.id}`);
              setCompany(updatedCompany.data);
              setLoading(false);
              setShopifyModalVisible(false);
              notification.success({ message: 'Shopify connection completed' });
            }
          }, 500);
        } else {
          notification.error({ message: 'Unable to open authentication window. Please check your pop-up blocker settings.' });
          setLoading(false);
        }
      } else {
        throw new Error('No authentication URL received from the server');
      }
    } catch (error) {
      notification.error(getErrorMsg(error));
      setLoading(false);
    }
  };

  const onClick = (item: IConnectionItem, action: ConnectionAction) => {
    if (!company?.id) return notification.error({ message: 'Something went wrong' });
    if (action === ConnectionAction.Connect) {
      if (item.title === 'Shopify') setShopifyModalVisible(true);
    }
  };

  const isShopify = !!shopifyConnections.length;
  const isSync = shopifyConnections.some((i) => i.status === 2);
  const isConnected = !isSync && shopifyConnections.some((i) => i.status === 1);

  const items: IConnectionItem[] = [
    {
      image: require('assets/shopify.png'),
      title: 'Shopify',
      disabled: flags.hide_shopify.enabled,
    },
    {
      image: require('assets/amazon.png'),
      title: 'Amazon',
      disabled: true,
    },
    {
      image: require('assets/walmart.png'),
      title: 'Walmart',
      disabled: true,
    },
    {
      image: require('assets/etsy.png'),
      title: 'Etsy',
      disabled: true,
    },
    {
      image: require('assets/squarespace.png'),
      title: 'Squarespace',
      disabled: true,
    },
    {
      image: require('assets/ebay.png'),
      title: 'Ebay',
      disabled: true,
    }
  ]

  items[0].connected = isConnected;
  items[0].status = isShopify ?
      (isSync ? 'syncing' : 'connected')
      : 'not connected';

  const onMatchedChange = (value: Matched, connectionId: number) => {
    // Merge the new value with existing values for this connection
    setLocalMatched(prev => {
      const existingConnectionMatched = prev[connectionId] || {};
      const newConnectionMatched = {
        ...existingConnectionMatched,  // Keep previous selections
        ...value  // Add new selection
      };

      return {
        ...prev,
        [connectionId]: newConnectionMatched
      };
    });

    // Similarly update pending changes
    pendingChangesRef.current = {
      connectionId,
      value: {
        ...(localMatched[connectionId]),  // Keep previous selections
        ...value  // Add new selection
      }
    };
  };


  // Set initial local matched state when connections load
  useEffect(() => {
    if (shopifyConnections.length > 0) {
      setLocalMatched(prev => {
        const initialMatched: { [key: number]: Matched } = {};
        shopifyConnections.forEach(conn => {
          // Preserve any existing local state for this connection
          const existingLocal = prev[conn.keyId] || {};
          const existingSettings = conn.settings?.shopify?.matched || {};

          initialMatched[conn.keyId] = {
            ...existingLocal,
            ...existingSettings
          };
        });
        return initialMatched;
      });
    }
  }, [shopifyConnections]);

  // Effect to handle saving changes
  useEffect(() => {
    if (pendingChangesRef.current) {
      saveChanges(pendingChangesRef.current);
    }

    return () => {
      if (pendingChangesRef.current) {
        saveChanges(pendingChangesRef.current);
      }
    };
  }, [location, company?.id]);


  return (
      <Flex vertical>
        <Divider orientation="left">Integration</Divider>
        <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={items}
            renderItem={(item) => (
                <List.Item key={item.title}>
                  <CardIntegration item={item} onClick={onClick} />
                </List.Item>
            )}
        />
        {isShopify && (
            <Flex vertical gap={20}>
              <Divider orientation="left">Settings</Divider>
              <Text>We need to know how you match your e-commerce data to your general ledger accounts</Text>
              {shopifyConnections.map((connection) => (
                  <MatchAccounts
                      key={connection.keyId}
                      value={localMatched[connection.keyId] || {}}
                      onChange={(value) => onMatchedChange(value, connection.keyId)}
                      accounts={accounts.data || []}
                      loading={accounts.isLoading || loading}
                      options={shopifyAccounts}
                      placeholder='Select Account(s)'
                      multiple
                  />
              ))}
            </Flex>
        )}
        <Divider />
        <Flex gap={10}>
          <Button onClick={props.onBack}>Back</Button>
          {isShopify ? (
              <Button onClick={props.onNext} loading={loading}>Next</Button>
          ) : (
              <Button type="primary" onClick={props.onNext} loading={loading}>Skip</Button>
          )}
        </Flex>
        <ShopifyConnectModal
            visible={shopifyModalVisible}
            onCancel={() => setShopifyModalVisible(false)}
            onConnect={connectShopify}
            loading={loading}
        />
      </Flex>
  );
}

const shopifyAccounts = [
  {id: 'gross', name: 'Gross Sales', isCalc: false},
  {id: 'discount', name: 'Discounts', isCalc: false},
  {id: 'returns', name: 'Returns', isCalc: false},
  {id: 'net', name: 'Net Sales', isCalc: true},
  {id: 'shipping', name: 'Shipping', isCalc: false},
]

type Matched = { [key: string]: string[] }