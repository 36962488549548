import React, { useState, useEffect } from 'react'
import { Button, Typography, Space, Input, Radio, Upload, Form, DatePicker, Card, notification, message } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { Progress } from './components/Progress'
import { api } from 'utils/axios'
import { ServiceType } from 'types/service.types'
import dayjs from 'dayjs'

const { Title, Text } = Typography
const { TextArea } = Input
const { RangePicker } = DatePicker

type DateRange = [string, string]

interface EntryBase {
  id: string
  dateRange: DateRange
  notes: string
}

interface AccountEntry extends EntryBase {
  accountName: string
  statements: any[]
  onlineBankingAccessGranted: boolean
}

interface EcommerceEntry extends EntryBase {
  platform: string
  paymentProcessor: string
  accountingMethod: 'cash' | 'accrual'
  statements: any[]
}

interface PayrollEntry extends EntryBase {
  provider: string
  accountingMethod: 'cash' | 'accrual'
  statements: any[]
  payrollAccessGranted: boolean
}

interface DepreciationEntry extends EntryBase {
  assetDetails: string
  statements: any[]
}

interface FormData {
  accountEntries: AccountEntry[]
  ecommerceEntries: EcommerceEntry[]
  payrollEntries: PayrollEntry[]
  depreciationEntries: DepreciationEntry[]
}

export const validateFile = (file: File, maxSizeMB: number = 10): boolean | typeof Upload.LIST_IGNORE => {
  const isValidSize = file.size <= maxSizeMB * 1024 * 1024
  if (!isValidSize) {
    message.error(`File must be smaller than ${maxSizeMB}MB!`)
    return Upload.LIST_IGNORE
  }

  const allowedTypes = [
    // PDF
    'application/pdf',
    // Documents
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/vnd.oasis.opendocument.text', // .odt
    'text/plain', // .txt
    'text/rtf', // .rtf
    'application/rtf', // alternative mime for .rtf
    // Images
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/webp',
    'image/svg+xml',
    'image/tiff',
    // Videos
    'video/mp4',
    'video/quicktime', // .mov
    'video/x-msvideo', // .avi
    'video/x-ms-wmv',
    'video/webm',
    'video/mpeg',
    // Excel
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.oasis.opendocument.spreadsheet', // .ods
    'text/csv',
  ]

  const isValidType = allowedTypes.includes(file.type)
  if (!isValidType) {
    message.error('File type not allowed! Please upload documents, images, videos, PDFs, or Excel files.')
    return Upload.LIST_IGNORE
  }

  return true
}

const emptyDateRange: DateRange = ['', '']

export function DocumentsAccess() {
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()
  const [selectedService, setSelectedService] = useState<string>('')
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('')
  const [timeline, setTimeline] = useState<string>('')

  const generateId = (type: keyof FormData, index: number) => {
    const timestamp = Date.now()
    return `${params.id}_${type}_${index}_${timestamp}`
  }

  const [formData, setFormData] = useState<FormData>(() => {
    const savedData = localStorage.getItem('documentsFormData')
    if (savedData) {
      return JSON.parse(savedData)
    }
    return {
      accountEntries: [
        {
          id: generateId('accountEntries', 0),
          dateRange: emptyDateRange,
          accountName: '',
          statements: [],
          notes: '',
          onlineBankingAccessGranted: false,
        },
      ],
      ecommerceEntries: [
        {
          id: generateId('ecommerceEntries', 0),
          dateRange: emptyDateRange,
          platform: '',
          paymentProcessor: '',
          accountingMethod: 'cash',
          statements: [],
          notes: '',
        },
      ],
      payrollEntries: [
        {
          id: generateId('payrollEntries', 0),
          dateRange: emptyDateRange,
          provider: '',
          accountingMethod: 'cash',
          statements: [],
          notes: '',
          payrollAccessGranted: false,
        },
      ],
      depreciationEntries: [
        {
          id: generateId('depreciationEntries', 0),
          dateRange: emptyDateRange,
          assetDetails: '',
          statements: [],
          notes: '',
        },
      ],
    }
  })

  useEffect(() => {
    const dataToSave = {
      ...formData,
      accountEntries: formData.accountEntries.map((entry) => ({
        ...entry,
        statements: [],
        dateRange: entry.dateRange,
      })),
      ecommerceEntries: formData.ecommerceEntries.map((entry) => ({
        ...entry,
        statements: [],
        dateRange: entry.dateRange,
      })),
      payrollEntries: formData.payrollEntries.map((entry) => ({
        ...entry,
        statements: [],
        dateRange: entry.dateRange,
      })),
      depreciationEntries: formData.depreciationEntries.map((entry) => ({
        ...entry,
        statements: [],
        dateRange: entry.dateRange,
      })),
    }

    localStorage.setItem('documentsFormData', JSON.stringify(dataToSave))
  }, [formData])

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('documentsFormData')
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    const service = localStorage.getItem('selectedService')
    const companyName = localStorage.getItem('selectedCompanyName')
    const timelineValue = localStorage.getItem('timeline')
    if (service) {
      setSelectedService(service)
    }
    if (companyName) {
      setSelectedCompanyName(companyName)
    }

    if (timelineValue) {
      setTimeline(timelineValue)
    }
  }, [])

  const handleSubmit = async () => {
    try {
      const validateEntry = (entry: any, type: string) => {
        if (!entry.dateRange[0] || !entry.dateRange[1]) {
          return false
        }

        switch (type) {
          case 'account':
            // Make sure either statements are uploaded or online banking access is granted
            return entry.accountName && (entry.statements.length > 0 || entry.onlineBankingAccessGranted)
          case 'ecommerce':
            return entry.platform && entry.paymentProcessor && entry.accountingMethod
          case 'payroll':
            return (
              entry.provider && entry.accountingMethod && (entry.statements.length > 0 || entry.payrollAccessGranted)
            )
          case 'depreciation':
            return entry.assetDetails
          default:
            return false
        }
      }

      const entries =
        selectedService === 'account'
          ? formData.accountEntries
          : selectedService === 'ecommerce'
            ? formData.ecommerceEntries
            : selectedService === 'payroll'
              ? formData.payrollEntries
              : formData.depreciationEntries

      const isValid = entries.every((entry) => validateEntry(entry, selectedService))

      if (!isValid) {
        notification.error({
          message: 'Required Fields Missing',
          description: 'Please fill in all required fields.',
        })
        return
      }

      const serviceData = selectedService
      const documentsData = formData

      const serviceResponse = await api.post(`companies/${params.id}/services`, {
        type: serviceData as ServiceType,
        metadata: {
          ...documentsData,
          companyName: selectedCompanyName,
          timeline: timeline,
        },
      })

      if (serviceResponse?.data?.success) {
        notification.success({ message: 'Request created successfully' })

        let totalFilesUploaded = 0
        let uploadSuccessful = true

        const serviceEntries =
          serviceData === 'account'
            ? documentsData.accountEntries
            : serviceData === 'ecommerce'
              ? documentsData.ecommerceEntries
              : serviceData === 'payroll'
                ? documentsData.payrollEntries
                : serviceData === 'depreciation'
                  ? documentsData.depreciationEntries
                  : []

        for (const entry of serviceEntries) {
          if (entry.statements && entry.statements.length > 0) {
            const formData = new FormData()
            let fileCount = 0

            entry.statements.forEach((fileItem: any) => {
              const file = fileItem.originFileObj || fileItem.file || fileItem

              if (file instanceof File || file.type) {
                formData.append('files', file)
                fileCount++
              } else {
                console.warn('Invalid file object:', file)
              }
            })

            if (fileCount > 0) {
              try {
                const uploadResponse = await api.post(
                  `companies/${params.id}/services/${serviceResponse.data.service.id}/files`,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  },
                )

                if (uploadResponse?.data?.success) {
                  totalFilesUploaded += fileCount
                } else {
                  uploadSuccessful = false
                  notification.error({
                    message: 'File upload failed',
                    description: 'Upload was not successful',
                  })
                }
              } catch (uploadError: any) {
                uploadSuccessful = false
                notification.error({
                  message: 'File upload failed',
                  description: uploadError instanceof Error ? uploadError.message : 'Failed to upload files',
                })
                throw uploadError
              }
            }
          }
        }

        if (uploadSuccessful && totalFilesUploaded > 0) {
          notification.success({
            message: `Successfully uploaded ${totalFilesUploaded} files in total`,
          })
        }

        navigate(`/company/${params.id}/services/schedule-meeting`)
      } else {
        notification.error({
          message: 'Service creation failed',
          description: 'Service creation was not successful',
        })
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      notification.error({
        message: error instanceof Error ? error.message : 'Failed to create service',
      })
    }
  }

  const addEntry = (type: keyof FormData) => {
    setFormData((prev) => {
      const currentLength = prev[type].length
      const baseEntry = {
        id: generateId(type, currentLength),
        dateRange: emptyDateRange,
      }

      switch (type) {
        case 'accountEntries':
          return {
            ...prev,
            accountEntries: [
              ...prev.accountEntries,
              {
                ...baseEntry,
                accountName: '',
                statements: [],
                notes: '',
                onlineBankingAccessGranted: false,
              } as AccountEntry,
            ],
          }
        case 'ecommerceEntries':
          return {
            ...prev,
            ecommerceEntries: [
              ...prev.ecommerceEntries,
              {
                ...baseEntry,
                platform: '',
                paymentProcessor: '',
                accountingMethod: 'cash',
                statements: [],
                notes: '',
              } as EcommerceEntry,
            ],
          }
        case 'payrollEntries':
          return {
            ...prev,
            payrollEntries: [
              ...prev.payrollEntries,
              {
                ...baseEntry,
                provider: '',
                accountingMethod: 'cash',
                statements: [],
                notes: '',
                payrollAccessGranted: false,
              } as PayrollEntry,
            ],
          }
        case 'depreciationEntries':
          return {
            ...prev,
            depreciationEntries: [
              ...prev.depreciationEntries,
              {
                ...baseEntry,
                assetDetails: '',
                statements: [],
                notes: '',
              } as DepreciationEntry,
            ],
          }
        default:
          return prev
      }
    })
  }

  const removeEntry = (type: keyof FormData, id: string) => {
    setFormData((prev) => ({
      ...prev,
      [type]: prev[type].filter((entry) => entry.id !== id),
    }))
  }

  const updateEntry = <T extends keyof FormData>(type: T, id: string, field: keyof FormData[T][number], value: any) => {
    setFormData((prev) => ({
      ...prev,
      [type]: prev[type].map((entry) => (entry.id === id ? { ...entry, [field]: value } : entry)),
    }))
  }

  const handleDateRangeChange = (type: keyof FormData, id: string, dates: any) => {
    if (!dates || !dates[0] || !dates[1]) {
      updateEntry(type, id, 'dateRange', emptyDateRange)
      return
    }

    const startDate = dates[0]
    const endDate = dates[1]
    const today = dayjs()

    // Check if end date is after start date
    if (endDate.isBefore(startDate)) {
      notification.error({
        message: 'Invalid Date Range',
        description: 'End date cannot be before start date',
      })
      updateEntry(type, id, 'dateRange', emptyDateRange)
      return
    }

    // Check if end date is not in the future
    if (endDate.isAfter(today)) {
      notification.error({
        message: 'Invalid Date Range',
        description: 'End date cannot be in the future',
      })
      updateEntry(type, id, 'dateRange', emptyDateRange)
      return
    }

    const dateRange: DateRange = [startDate.toISOString(), endDate.toISOString()]
    updateEntry(type, id, 'dateRange', dateRange)
  }

  const toggleOnlineBankingAccess = (id: string, value: boolean) => {
    updateEntry('accountEntries', id, 'onlineBankingAccessGranted', value)
  }

  const togglePayrollAccess = (id: string, value: boolean) => {
    updateEntry('payrollEntries', id, 'payrollAccessGranted', value)
  }

  const renderAccountReconciliations = () => (
    <div className="space-y-4">
      {formData.accountEntries.map((entry, index) => (
        <Card
          key={entry.id}
          title="Account Details"
          extra={
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() => removeEntry('accountEntries', entry.id)}
            />
          }
          style={{ marginBottom: '16px' }}
        >
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <div>
              <Text strong style={{ fontSize: '16px' }}>
                {index === 0
                  ? "Please provide the details related to the first account that you'd like assistance with:"
                  : 'Please provide the details related to the next account, as well:'}
              </Text>
            </div>

            <div>
              <Text strong>
                Bank/Credit Card Account Name <span style={{ color: '#ff4d4f' }}>*</span>
              </Text>
              <Input
                style={{ marginTop: '8px' }}
                value={entry.accountName}
                onChange={(e) => updateEntry('accountEntries', entry.id, 'accountName', e.target.value)}
              />
            </div>
            <div>
              <Text strong>
                Reconciliation Period <span style={{ color: '#ff4d4f' }}>*</span>
              </Text>
              <RangePicker
                style={{ marginTop: '8px', width: '100%' }}
                onChange={(dates) => handleDateRangeChange('accountEntries', entry.id, dates)}
                value={
                  entry.dateRange[0] && entry.dateRange[1]
                    ? [dayjs(entry.dateRange[0]), dayjs(entry.dateRange[1])]
                    : null
                }
                disabledDate={(current) => current && current > dayjs().endOf('day')}
              />
            </div>
            <div>
              <Text strong>Account Statements</Text>
              <Upload.Dragger
                multiple
                beforeUpload={(file) => validateFile(file)}
                onChange={({ fileList }) => updateEntry('accountEntries', entry.id, 'statements', fileList)}
              >
                <p className="ant-upload-text">Upload any document files</p>
              </Upload.Dragger>
            </div>
            <div>
              <Text strong>Online Banking Access</Text>
              <div style={{ marginTop: '8px' }}>
                <Text>
                  If you did not upload your bank statement data, please grant hello@equilityhq.com online banking
                  access to the accounts that you'd like us to assist with. Once this has been done, please click the
                  button below:
                </Text>
                <div style={{ marginTop: '16px' }}>
                  <Button
                    type={entry.onlineBankingAccessGranted ? 'primary' : 'default'}
                    onClick={() => toggleOnlineBankingAccess(entry.id, !entry.onlineBankingAccessGranted)}
                    style={{ marginRight: '8px' }}
                  >
                    Online Banking Access Granted
                  </Button>
                  {entry.statements.length === 0 && !entry.onlineBankingAccessGranted && (
                    <Text type="danger" style={{ marginLeft: '8px' }}>
                      * Required if no statements are uploaded
                    </Text>
                  )}
                </div>
              </div>
            </div>

            <div>
              <Text strong>Additional Notes</Text>
              <TextArea
                style={{ marginTop: '8px' }}
                rows={3}
                placeholder="Add any additional notes or comments (optional)"
                value={entry.notes}
                onChange={(e) => updateEntry('accountEntries', entry.id, 'notes', e.target.value)}
              />
            </div>
          </Space>
        </Card>
      ))}
      <Button type="dashed" onClick={() => addEntry('accountEntries')} block icon={<PlusOutlined />}>
        Add Another Account
      </Button>
    </div>
  )

  const renderEcommerce = () => (
    <div className="space-y-4">
      {formData.ecommerceEntries.map((entry, index) => (
        <Card
          key={entry.id}
          title={'E-commerce Details'}
          extra={
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() => removeEntry('ecommerceEntries', entry.id)}
            />
          }
          style={{ marginBottom: '16px' }}
        >
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <div>
              <Text strong style={{ fontSize: '16px' }}>
                {index === 0
                  ? "Please provide the details related to the first e-comerce platform that you'd like assistance with:"
                  : 'Please provide the details related to the next e-commerce platform as well:'}
              </Text>
            </div>

            <div>
              <Text strong>
                E-commerce Platform <span style={{ color: '#ff4d4f' }}>*</span>
              </Text>
              <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                <Text>
                  Please grant hello@equilityhq.com access to all of the e-commerce platforms that you need assistance
                  with, and then list the names of the platform(s) below:
                </Text>
              </div>
              <Input
                placeholder="List platforms you granted access to"
                value={entry.platform}
                onChange={(e) => updateEntry('ecommerceEntries', entry.id, 'platform', e.target.value)}
              />
            </div>
            <div>
              <Text strong>
                Payment Processor(s) <span style={{ color: '#ff4d4f' }}>*</span>
              </Text>
              <Input
                style={{ marginTop: '8px' }}
                placeholder="List payment processors you granted access to"
                value={entry.paymentProcessor}
                onChange={(e) => updateEntry('ecommerceEntries', entry.id, 'paymentProcessor', e.target.value)}
              />
            </div>
            <div>
              <Text strong>
                Reconciliation Period <span style={{ color: '#ff4d4f' }}>*</span>
              </Text>
              <RangePicker
                style={{ marginTop: '8px', width: '100%' }}
                onChange={(dates) => handleDateRangeChange('ecommerceEntries', entry.id, dates)}
                value={
                  entry.dateRange[0] && entry.dateRange[1]
                    ? [dayjs(entry.dateRange[0]), dayjs(entry.dateRange[1])]
                    : null
                }
                disabledDate={(current) => current && current > dayjs().endOf('day')}
              />
            </div>
            <div>
              <Text strong style={{ marginRight: '16px' }}>
                Accounting Method <span style={{ color: '#ff4d4f' }}>*</span>
              </Text>
              <Radio.Group
                value={entry.accountingMethod}
                onChange={(e) => updateEntry('ecommerceEntries', entry.id, 'accountingMethod', e.target.value)}
                style={{ marginTop: '8px' }}
              >
                <Radio value="cash">Cash</Radio>
                <Radio value="accrual">Accrual</Radio>
              </Radio.Group>
            </div>
            <div>
              <Text strong>Supporting Documents (if applicable)</Text>
              <Upload.Dragger
                multiple
                beforeUpload={(file) => validateFile(file)}
                onChange={({ fileList }) => updateEntry('ecommerceEntries', entry.id, 'statements', fileList)}
              >
                <p className="ant-upload-text">Upload any document files</p>
              </Upload.Dragger>
            </div>
            <div>
              <Text strong>Additional Notes</Text>
              <TextArea
                style={{ marginTop: '8px' }}
                rows={3}
                placeholder="Add any additional notes or comments (optional)"
                value={entry.notes}
                onChange={(e) => updateEntry('ecommerceEntries', entry.id, 'notes', e.target.value)}
              />
            </div>
          </Space>
        </Card>
      ))}
      <Button type="dashed" onClick={() => addEntry('ecommerceEntries')} block icon={<PlusOutlined />}>
        Add Another E-commerce Entry
      </Button>
    </div>
  )

  const renderPayroll = () => (
    <div className="space-y-4">
      {formData.payrollEntries.map((entry, _index) => (
        <Card
          key={entry.id}
          title="Payroll Details"
          extra={
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() => removeEntry('payrollEntries', entry.id)}
            />
          }
          style={{ marginBottom: '16px' }}
        >
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <div>
              <Text strong>
                Payroll Provider <span style={{ color: '#ff4d4f' }}>*</span>
              </Text>
              <Input
                style={{ marginTop: '8px' }}
                placeholder="List payroll providers you granted access to"
                value={entry.provider}
                onChange={(e) => updateEntry('payrollEntries', entry.id, 'provider', e.target.value)}
              />
            </div>
            <div>
              <Text strong>
                Reconciliation Period <span style={{ color: '#ff4d4f' }}>*</span>
              </Text>
              <RangePicker
                style={{ marginTop: '8px', width: '100%' }}
                onChange={(dates) => handleDateRangeChange('payrollEntries', entry.id, dates)}
                value={
                  entry.dateRange[0] && entry.dateRange[1]
                    ? [dayjs(entry.dateRange[0]), dayjs(entry.dateRange[1])]
                    : null
                }
                disabledDate={(current) => current && current > dayjs().endOf('day')}
              />
            </div>
            <div>
              <Text strong style={{ marginRight: '16px' }}>
                Accounting Method <span style={{ color: '#ff4d4f' }}>*</span>
              </Text>
              <Radio.Group
                value={entry.accountingMethod}
                onChange={(e) => updateEntry('payrollEntries', entry.id, 'accountingMethod', e.target.value)}
                style={{ marginTop: '8px' }}
              >
                <Radio value="cash">Cash</Radio>
                <Radio value="accrual">Accrual</Radio>
              </Radio.Group>
            </div>
            <div>
              <Text strong>Supporting Documents</Text>
              <Upload.Dragger
                multiple
                beforeUpload={(file) => validateFile(file)}
                onChange={({ fileList }) => updateEntry('payrollEntries', entry.id, 'statements', fileList)}
              >
                <p className="ant-upload-text">Upload any document files</p>
              </Upload.Dragger>
            </div>
            <div>
              <Text strong>Payroll Access</Text>
              <div style={{ marginTop: '8px' }}>
                <Text>
                  If you did not upload the payroll statements for the reconciliation period above, then please grant
                  hello@equilityhq.com online access to the payroll provider that you'd like us to assist with. Once
                  this has been done, please click the button below:
                </Text>
                <div style={{ marginTop: '16px' }}>
                  <Button
                    type={entry.payrollAccessGranted ? 'primary' : 'default'}
                    onClick={() => togglePayrollAccess(entry.id, !entry.payrollAccessGranted)}
                    style={{ marginRight: '8px' }}
                  >
                    Payroll Access Granted
                  </Button>
                  {entry.statements.length === 0 && !entry.payrollAccessGranted && (
                    <Text type="danger" style={{ marginLeft: '8px' }}>
                      * Required if no statements are uploaded
                    </Text>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Text strong>Additional Notes</Text>
              <TextArea
                style={{ marginTop: '8px' }}
                rows={3}
                placeholder="Add any additional notes or comments (optional)"
                value={entry.notes}
                onChange={(e) => updateEntry('payrollEntries', entry.id, 'notes', e.target.value)}
              />
            </div>
          </Space>
        </Card>
      ))}
      {/* <Button type="dashed" onClick={() => addEntry('payrollEntries')} block icon={<PlusOutlined />}>
        Add Another Payroll Entry
      </Button> */}
    </div>
  )

  const renderDepreciation = () => (
    <div className="space-y-4">
      {formData.depreciationEntries.map((entry, index) => (
        <Card
          key={entry.id}
          title={'Depreciation & Amortization Details'}
          extra={
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() => removeEntry('depreciationEntries', entry.id)}
            />
          }
          style={{ marginBottom: '16px' }}
        >
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <div>
              <Text strong style={{ fontSize: '16px' }}>
                {index === 0
                  ? "Please provide the details related to the first depreciation or amortization account that you'd like assistance with:"
                  : 'Please provide the details related to the next depreciation or amortization account, as well:'}
              </Text>
            </div>
            <div>
              <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                <Text strong>
                  Please list the asset account name(s) as listed in QuickBooks Online, along with the estimated useful
                  life. If you do not know the useful life, simply put 'I don't know'.
                  <span style={{ color: '#ff4d4f' }}> * </span>
                </Text>
              </div>
              <TextArea
                style={{ marginTop: '8px' }}
                rows={4}
                value={entry.assetDetails}
                onChange={(e) => updateEntry('depreciationEntries', entry.id, 'assetDetails', e.target.value)}
              />
            </div>
            <div>
              <Text strong>
                Depreciation Period <span style={{ color: '#ff4d4f' }}>*</span>
              </Text>
              <RangePicker
                style={{ marginTop: '8px', width: '100%' }}
                onChange={(dates) => handleDateRangeChange('depreciationEntries', entry.id, dates)}
                value={
                  entry.dateRange[0] && entry.dateRange[1]
                    ? [dayjs(entry.dateRange[0]), dayjs(entry.dateRange[1])]
                    : null
                }
                disabledDate={(current) => current && current > dayjs().endOf('day')}
              />
            </div>
            <div>
              <Text strong>Supporting Documents (if applicable)</Text>
              <Upload.Dragger
                multiple
                beforeUpload={(file) => validateFile(file)}
                onChange={({ fileList }) => updateEntry('depreciationEntries', entry.id, 'statements', fileList)}
              >
                <p className="ant-upload-text">Upload any document files</p>
              </Upload.Dragger>
            </div>
            <div>
              <Text strong>Additional Notes</Text>
              <TextArea
                style={{ marginTop: '8px' }}
                rows={3}
                placeholder="Add any additional notes or comments (optional)"
                value={entry.notes}
                onChange={(e) => updateEntry('depreciationEntries', entry.id, 'notes', e.target.value)}
              />
            </div>
          </Space>
        </Card>
      ))}
      <Button type="dashed" onClick={() => addEntry('depreciationEntries')} block icon={<PlusOutlined />}>
        Add Another account
      </Button>
    </div>
  )

  const renderServiceForm = () => {
    switch (selectedService) {
      case 'account':
        return renderAccountReconciliations()
      case 'ecommerce':
        return renderEcommerce()
      case 'payroll':
        return renderPayroll()
      case 'depreciation':
        return renderDepreciation()
      default:
        return <Text>Please select a service first</Text>
    }
  }

  return (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', padding: '32px', marginBottom: '32px' }}>
      <Title level={2}>Document & Platform Access</Title>

      <div style={{ marginBottom: '64px' }}>
        <Progress currentStep={3} />
      </div>

      <Form layout="vertical">
        {renderServiceForm()}

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px', gap: '16px' }}>
          <Button onClick={() => navigate(-1)} size="large" style={{ padding: '0 32px', borderRadius: '8px' }}>
            Back
          </Button>

          <Button onClick={handleSubmit} type="primary" size="large" style={{ padding: '0 32px', borderRadius: '8px' }}>
            Create Request
          </Button>
        </div>
      </Form>
    </div>
  )
}
