import {CheckCircleOutlined, ExclamationCircleOutlined, MinusCircleOutlined, SyncOutlined} from "@ant-design/icons";
import { Tag } from "antd";
import React from "react";

const BankFeedState = {
    BANK_FEED_STATE_NONE: 0,
    BANK_FEED_STATE_GOOD: 1,
    BANK_FEED_STATE_REAUTH: 2,
    BANK_FEED_STATE_REFRESHING: 4,
};


export const getStateDisplay = (state: number) => {
    switch (state) {
        case BankFeedState.BANK_FEED_STATE_GOOD:
            return { label: 'Active', color: 'success', icon: <CheckCircleOutlined /> };
        case BankFeedState.BANK_FEED_STATE_REAUTH:
            return { label: 'Needs Reauthorization', color: 'warning', icon: <ExclamationCircleOutlined /> };
        case BankFeedState.BANK_FEED_STATE_REFRESHING:
            return { label: 'Refreshing', color: 'processing', icon: <SyncOutlined spin /> };
        case BankFeedState.BANK_FEED_STATE_NONE:
        default:
            return { label: 'Inactive', color: 'default', icon: <MinusCircleOutlined /> };
    }
};

export const StatusTag = ({ state }: { state: number }) => {
    const stateInfo = getStateDisplay(state)
    return (
        <Tag icon={stateInfo.icon} color={stateInfo.color}>{stateInfo.label}</Tag>
    )
}
