import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  Typography,
  Table,
  Modal,
  TableColumnsType,
  Form,
  Row, Switch,
  List, Tooltip, App,
} from 'antd'
import { SelectEntity } from 'components/SelectEntity'
import { SelectMethod } from 'components/SelectMethod'

import { useRole } from 'hooks/useRole'
import { useCompany } from 'hooks/useCompany'
import { formatDate, parseDateRage } from 'utils/dates'
import {ConnectionType, IntegrationStatus, IRule} from 'types/company.types'
import { RoleType } from 'types/user.types'
import { useFlags } from 'flagsmith/react'
import type { IDefaultRule } from 'types/default-rules.types'
import { useDefaultRules } from '../../../hooks/useDefaultRule'
import { useRules } from '../../../hooks/useRules'
import { SourceItem } from '../../../types/source.types'
import { IConnectionData } from '../../../types/connection.types'
import { useReview } from '../../../hooks/useReview'
import RateLimitedButton from 'components/RateLimitedButton'
import {useAccounts} from "../../../hooks/useAccounts";

const { RangePicker } = DatePicker
const { Text } = Typography
const dateRange = parseDateRage()


interface SourceState {
  enabled: boolean;
  connectionId?: ConnectionType;
  connections?: IConnectionData[];
}


export function ReviewRun() {
  const {notification } = App.useApp();
  const navigate = useNavigate()
  const { rules = [], remove, isLoading } = useRules()

  const flags = useFlags(['hide_shopify'])
  /* Used for filtering on any particular record*/
  const customRules = useRules()
  /* handling the Rule toggle*/
  const [, setRuleData] = useState<IRule[]>([])

  const { create: createReview, isLoading: isCreatingReview } = useReview({ id: null })
  const isReviewInProgress = useRef(false)
  const [isInCooldown, setIsInCooldown] = useState(false)
  const cooldownTimer = useRef<NodeJS.Timeout>()
  const { company } = useCompany()
  const { isAllowed } = useRole(RoleType.Member)


  const [form] = Form.useForm()

  const entity = company?.entity
  const method = company?.settings?.accountingMethod || 'Cash'

  // Memoize connection data
  const { qbo, isConnected, shopify } = useMemo(() => {
    const qboConn = (company?.connections || []).find((i) => i.type.id === ConnectionType.QBO)
    return {
      qbo: qboConn,
      isConnected: qboConn?.status === 1,
      shopify: company?.connections?.filter((i) => i.type.id === ConnectionType.Shopify)}
  }, [company?.connections])

  const { accounts } = useAccounts()

  // Get bank account IDs
  const bankAccounts = useMemo(() =>
          accounts
              .filter(account => account.bankFeedAccountId !== null)
              .map(account => ({
                connectionInfo: { accountId: account.bankFeedAccountId },
                keyId: account.bankFeedAccountId,
                id: ConnectionType.Plaid,
                name: account.name,
                status: IntegrationStatus.Active,
                type: {
                  id: ConnectionType.Plaid,
                  name: 'Bank Feed'
                }
              })as IConnectionData)
      , [accounts]);

  const sourceData: SourceItem[] = [
    {
      id: 'qbo',
      title: 'QuickBooks Online',
      description: 'Accounting data',
      disabled: true,
      requiresSelection: false,
      connections: qbo ? [qbo] : [],
      active: true, // QBO is always enabled by default
    },
    {
      id: 'shopify',
      title: 'Shopify',
      description: 'E-commerce data',
      disabled: flags.hide_shopify.enabled || (!shopify || shopify.length === 0),
      requiresSelection: true,
      connections: shopify,
      // getConnectionLabel: (connection) =>
      //   connection.connectionInfo?.store?.store_name || connection.connectionInfo?.shop_name || `Store ${connection.id}`,
      active: shopify && shopify.length > 0 , // Shopify starts enabled by default
      tooltip: (!shopify || shopify.length === 0) ? {
        title: (
            <span>Please configure Shopify in <Link to={`/company/${company?.id}/settings/integrations`}>Company Settings</Link>.</span>
        ),
        placement: 'right',
      }: undefined
    },
    {
      id: 'bankfeed',
      title: 'Bank & Credit Cards',
      description: '',
      disabled:  (!bankAccounts || bankAccounts.length === 0),
      requiresSelection: true,
      connections: bankAccounts,
       active: bankAccounts && bankAccounts.length > 0 , // Banking Data starts enabled by default
      tooltip: (!bankAccounts || bankAccounts.length === 0) ? {
        title: (
            <span>Please configure banking data in <Link to={`/company/${company?.id}/settings/banks`}>Bank and Credit Cards Settings</Link>.</span>
        ),
        placement: 'right',
      }: undefined
    }
  ]
  const [sourceStates, setSourceStates] = useState<Record<string, SourceState>>(() => {
    return sourceData.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: {
          enabled: item.active || false,
          connections: item.connections  // Store all connections
        }
      };
    }, {});
  });


  // Extract search params processing
  // const search: SearchParams = useMemo(
  //   () => Object.fromEntries(searchParams) as any,
  //   [searchParams],
  // )


  // useEffect(() => {
  //   if (!company || !search.daterange || !search.sync || isInitialized) return
  //
  //   const daterange = search.daterange.split('--')
  //   const state = {
  //     from: daterange[0],
  //     to: daterange[1],
  //     method: company?.settings?.accountingMethod || 'Cash',
  //     entity: company?.entity,
  //   }
  //
  //   setIsInitialized(true)
  //   localStorage.removeItem('qbosync')
  //   navigate(`/company/${company.id}/review/result`, { state })
  //   setRuleData(initialCustomRuleData)
  //
  // }, [company, search, initialCustomRuleData, isInitialized])


  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [selectedRecord, setSelectedRecord] = useState<any | null>(null)

  const handleRowClick = useCallback((record: IDefaultRule) => {
    setSelectedRecord(record)
    setIsModalVisible(true)
  }, [])

  const closeModal = () => {
    setIsModalVisible(false)
    setSelectedRecord(null)
  }

  // const handleSourceChange = ({ sourceId, enabled, connectionId, connection }: SourceChangeEvent<IConnectionData>) => {
  //   setSourceStates(prev => ({
  //     ...prev,
  //     [sourceId]: {
  //       enabled,
  //       connectionId: connectionId ?? undefined,
  //       connection: connection ?? undefined,
  //     },
  //   }))
  // }

  // const runReview = (state: any) => {
  //   const path = `/company/${company?.id}/review/result`
  //   navigate(path, { state })
  // }

  const onSubmit = async (values: any) => {
    if (!company) return
    if (!isConnected) {
      return notification.error({
        message: 'Error',
        description: 'QuickBooks Online is not connected',
      })
    }

    // Prevent multiple simultaneous submissions
    if (isReviewInProgress.current || isInCooldown) {
      notification.warning({
        message: 'Review in Progress',
        description: isInCooldown ?
            'Please wait a few minutes before starting another review.' :
            'A review is already in progress. Please wait for it to complete.',
      })
      return
    }

    try {
      isReviewInProgress.current = true
      const integrations: ({ name: string; keyId: any } | undefined)[] = Object.entries(sourceStates)
          .filter(([sourceId, src]) =>
              src.enabled &&
              src.connections &&  // Check if connections exists
              sourceId !== 'qbo'
          )
          .flatMap(([sourceId, src]) =>
              // Since we checked for src.connections in filter, TS knows it's defined here
              src.connections?.map(conn => ({
                name: sourceId,
                keyId: conn.keyId,
              }))
          );

      const reviewData = {
        from: formatDate(values.daterange[0], 'YYYY-MM-DD'),
        to: formatDate(values.daterange[1], 'YYYY-MM-DD'),
        method: values.method,
        entity: values.entity || undefined,
        integrations: integrations,
      }
      // its being created on reviewResult page
      const createdReview = await createReview(reviewData)

      // Start cooldown period after successful review
      setIsInCooldown(true)
      cooldownTimer.current = setTimeout(() => {
        setIsInCooldown(false)
      }, 10 * 1000) // 10 second cooldown

      navigate(`/company/${company.id}/review/result/${createdReview.id}`, {
        state: {
          from: reviewData.from,
          to: reviewData.to,
          method: reviewData.method,
          entity: reviewData.entity,
          integrations: reviewData.integrations,
        },
      })
    } catch (error: any) {
      console.log('Review creation error:', error?.response || error)

      if (error?.response?.status === 500 && error?.response?.data?.message === "Error: Unauthorized") {
        notification.error({
          message: 'Authorization Required',
          description: 'Your QuickBooks connection needs to be reauthorized. Redirecting to accounting settings...',
          duration: 0,
        })

        // Redirect to accounting settings after a short delay
        setTimeout(() => {
          navigate('../settings/accounting', { relative: 'path' })
        }, 500)
        return
      }

      notification.error({
        message: 'Error',
        description: 'Failed to create review. Please try again.',
      })
    } finally {
      isReviewInProgress.current = false
    }
  }

  // Cleanup timer on unmount
  useEffect(() => {
    return () => {
      if (cooldownTimer.current) {
        clearTimeout(cooldownTimer.current)
      }
    }
  }, [])

  // Use the new hook to fetch default rules
  const { data: defaultRules = [], isLoading: isLoadingDefaultRules } = useDefaultRules(entity)

  const onDelete = useCallback(
    (id: number) => {
      remove(id)
    },
    [remove],
  )


  const handleSuccess = () => {
    notification.success({ message: 'Success', description: 'Rule updated' })
  }
  const handleSwitchToggle = (id: number) => (checked: boolean) => {
    if (id) {
      const rule = id ? customRules.rules?.find((r) => r.id === id) : undefined
      if (rule) {
        const updatedRule = { ...rule, active: checked }
        customRules.update(updatedRule).then(handleSuccess)
      }
    }

    setRuleData((prevRuleData) =>
      prevRuleData.map((rule) =>
        rule.id === id ? { ...rule, active: checked } : rule,
      ),
    )
  }

  const renderActions = useCallback(
    (_: any, rule: IRule) => (
      <Flex gap={10}>
        <Link to={`rules/${rule.id}`}>
          <Button size="small" type="text">
            Edit
          </Button>
        </Link>
        <Button size="small" type="text" danger onClick={() => onDelete(rule.id)}>
          Delete
        </Button>
      </Flex>
    ),
    [onDelete],
  )

  const renderSwitch = useCallback((_: any, rule: IRule) => (

    <Switch
      checked={rule.active} // Set initial checked state from 'active'
      onChange={handleSwitchToggle(rule.id)} // Handle toggle changes locally
    />
  ), [handleSwitchToggle])

  const columns: TableColumnsType<IRule> = useMemo(
    () => [
      {
        title: 'Rule',
        dataIndex: 'name',
        width: 300,
        ellipsis: true,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        ellipsis: true,
      },
      {
        title: 'Source Account',
        dataIndex: 'account',
        width: 300,
        ellipsis: true,
        render: (account) => account?.label,
      },
      {
        title: 'Enabled/Disabled',
        dataIndex: 'active',
        ellipsis: true,
        render: renderSwitch,
      },
      {
        title: 'Action',
        dataIndex: 'id',
        width: 150,
        render: renderActions,
      },
    ],
    [renderSwitch, renderActions])



  const handleConnectionToggle = useCallback((sourceId: string, checked: boolean) => {
    setSourceStates(prev => ({
      ...prev,
      [sourceId]: {
        ...prev[sourceId],
        enabled: checked
      }
    }))
  }, [])

  const columns2: TableColumnsType<IDefaultRule> = [
    {
      title: 'Rule',
      dataIndex: 'name',
      width: 300,
      ellipsis: true,
    },
  ]
  const buttonDisabled = isReviewInProgress.current || isInCooldown


  const onRow = useCallback(
    (record: IDefaultRule) => ({
      onClick: () => handleRowClick(record),
    }),
    [handleRowClick],
  )

  if (!company?.id) return null
  if (!isAllowed) return null

  return (
    <Flex vertical>
      {!isConnected && (
        <Alert
          message="Quickbooks Integration"
          description={
            <Text>
              <a href={`/company/${company?.id}/settings/accounting`}>Connect your accounting platform</a> to use the features
            </Text>
          }
          type="warning"
          showIcon
        />
      )}
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row gutter={24}>
          <Col span={16}>
            <Divider orientation="left">Review Parameters</Divider>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Date Range" name="daterange" initialValue={dateRange}>
                  <RangePicker style={inputStyle} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Accounting Method" name="method" initialValue={method}>
                  <SelectMethod />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Divider orientation="left">Business info</Divider>
            <Form.Item
              label="Legal Entity Type"
              name="entity"
              initialValue={entity}
              tooltip="If you'd like to change your legal entity type, please navigate to Settings > Business Details and select the desired option from the 'Legal Entity Type' dropdown."
            >
              <SelectEntity disabled />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Source data</Divider>
        <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={sourceData}
            renderItem={(item) => (
                <List.Item>
                  <Flex vertical gap={10}>
                    <List.Item.Meta
                        title={item.title}
                        description={item.description}
                    />
                    <Flex>
                      {item.tooltip ? (
                          <Tooltip {...item.tooltip}>
                            <span>
                <Switch
                    checked={sourceStates[item.id]?.enabled ?? item.active}
                    disabled={item.disabled}
                    onChange={(checked) => handleConnectionToggle(item.id, checked)}
                />
              </span>
                          </Tooltip>
                      ) : (
                          <Switch
                              checked={sourceStates[item.id]?.enabled ?? item.active}
                              disabled={item.disabled}
                              onChange={(checked) => handleConnectionToggle(item.id, checked)}
                          />
                      )}
                    </Flex>
                  </Flex>
                </List.Item>
            )}
        />
        <Link to="../settings/integrations" relative="path">
          <Button>Setup source data</Button>
        </Link>

        <Flex vertical gap={10}>
          <Flex vertical>
            <Divider orientation="left">Custom Rules</Divider>
            <Table
              loading={isLoading}
              dataSource={rules || []}
              columns={columns}
              rowKey="id"
              pagination={false}
            />
          </Flex>
          <Flex justify="flex-start" style={{ marginTop: 20 }}>
            <Link to="./rules/add">
              <Button type="primary" style={{ width: 200, backgroundColor: '#1890ff' }}>
                Add Rule
              </Button>
            </Link>
          </Flex>
          <Flex vertical>
            <Divider orientation="left">Default Review Rules</Divider>
            <Flex vertical gap={10}>
              <Text>
                The default rules are applied to all companies by default based on their business
                entity type. Custom rules can be added to make them more specific to your
                company.
              </Text>
              <Table
                loading={isLoadingDefaultRules}
                dataSource={defaultRules}
                columns={columns2}
                rowKey="id"
                pagination={false}
                onRow={onRow}
              />
            </Flex>
          </Flex>
        </Flex>

        <Divider />
        <Flex justify="flex-end">
          <RateLimitedButton
            type="primary"
            style={runStyle}
            htmlType="submit"
            disabled={buttonDisabled}
            loading={isCreatingReview}>
            Run
          </RateLimitedButton>
        </Flex>
      </Form>

      <Modal
        title={selectedRecord?.name}
        open={isModalVisible}
        footer={null}
        onCancel={closeModal}
        width={600}
      >
        {selectedRecord && (
          <Flex vertical gap={16}>
            <Typography.Title level={5}>Description</Typography.Title>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedRecord.description,
              }}
              className="rule-description"
            />
            <style>{`
              .rule-description {
                font-size: 14px;
                line-height: 1.5;
                color: rgba(0, 0, 0, 0.85);
              }
              .rule-description br {
                margin-bottom: 8px;
              }
              .rule-description span {
                display: block;
                margin-bottom: 16px;
              }
              .rule-description ul, 
              .rule-description ol {
                margin: 8px 0;
                padding-left: 24px;
              }
              .rule-description li {
                margin-bottom: 8px;
              }
              .rule-description p {
                margin-bottom: 16px;
              }
            `}</style>
          </Flex>
        )}
      </Modal>
    </Flex>
  )
}

const runStyle: React.CSSProperties = {
  width: 200,
}

const inputStyle: React.CSSProperties = {
  width: '100%',
}