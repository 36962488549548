import {Button, Col, Divider, Flex, Form, Input, Popconfirm, Row, Upload, App} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { api } from 'utils/axios'

import { SelectEntity } from 'components/SelectEntity'
import { SelectMethod } from 'components/SelectMethod'

import { useCompany } from 'hooks/useCompany'
import { useRole } from 'hooks/useRole'

import { ICompany } from 'types/company.types'
import { RoleType } from 'types/user.types'

import { useCompanyStore } from 'store/company.store'

const MAX_FILE_SIZE = 4 * 1024 * 1024
const ALLOWED_TYPES = ['image/jpeg', 'image/png']
const MAX_DIMENSIONS = 400

export function Info() {
  const {notification } = App.useApp();
  const { company, update, remove } = useCompany()
  const { isAllowed } = useRole(RoleType.Member)
  const setCompany = useCompanyStore((state) => state.setCompany)
  const navigate = useNavigate()
  
  
  
  const [fileList, setFileList] = useState<UploadFile[]>(() => {
    if (company?.file_logo_id && company?.logoUrl) {
      return [{
        uid: company.file_logo_id,
        name: 'company-logo',
        status: 'done',
        url: company.logoUrl,
      }]
    }
    return []
  })

  useEffect(() => {
    
    if (company?.file_logo_id && company?.logoUrl) {
      setFileList([{
        uid: company.file_logo_id,
        name: 'company-logo',
        status: 'done',
        url: company.logoUrl,
      }])
    } else {
      setFileList([])
    }
  }, [company])
  
  const [uploading, setUploading] = useState(false)

  if (!company) return null

  const onChange = (values: Partial<ICompany>) => {
    if (!isAllowed)
      return notification.error({ message: 'Error', description: 'You are not allowed to perform this action' })
    update({ ...company, ...values })
  }

  const onDelete = async () => {
    if (!isAllowed)
      return notification.error({ message: 'Error', description: 'You are not allowed to perform this action' })

    await remove(company)
    setCompany(null)
    navigate('/')
  }

  const beforeUpload = (file: RcFile): Promise<boolean> | boolean => {
    if (!ALLOWED_TYPES.includes(file.type)) {
      notification.error({ message: 'Error', description: 'You can only upload JPG/PNG files!' })
      return false
    }

    if (file.size > MAX_FILE_SIZE) {
      notification.error({ message: 'Error', description: 'Image must be smaller than 4MB!' })
      return false
    }

    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = URL.createObjectURL(file)
      
      img.onload = () => {
        URL.revokeObjectURL(img.src)
        
        if (img.width !== img.height) {
          notification.error({ message: 'Error', description: 'Image must be square!' })
          setFileList([])
          reject()
          return
        }
        
        if (img.width > MAX_DIMENSIONS) {
          notification.error({ 
            message: 'Error', 
            description: `Image dimensions must be ${MAX_DIMENSIONS}x${MAX_DIMENSIONS}px or smaller!`
          })
          setFileList([])
          reject()
          return
        }
        
        resolve(true)
      }
    })
  }

  const handleUpload = async (options: UploadRequestOption) => {
    const { file, onSuccess, onError } = options
    setUploading(true)
  
    try {
      if (!isAllowed) {
        throw new Error('You are not allowed to perform this action')
      }
      console.log('Starting logo upload:', {
        companyId: company.id,
        currentLogoId: company.file_logo_id
      })
  
      const formData = new FormData()
      formData.append('file', file)
  
      const { data } = await api.post(
        `companies/${company.id}/logo/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
  
      console.log('Upload response:', {
        response: data,
        newFileId: data.fileId,
        fileUrl: data.fileUrl
      })
      
      notification.success({ message: 'Success', description: 'Logo uploaded successfully!' })
      onSuccess?.(data)
    } catch (error) {
      notification.error({ 
        message: 'Error', 
        description: error instanceof Error ? error.message : 'Logo upload failed' 
      })
      onError?.(error as Error)
      setFileList([])
    } finally {
      setUploading(false)
    }
  }

  const handleChange: UploadProps['onChange'] = ({ file, fileList: newFileList }) => {

    console.log('Upload status change:', {
      fileStatus: file.status,
      fileResponse: file.response,
      listLength: newFileList.length
    })
    if (file.status === 'error' || file.status === 'removed') {
      setFileList([])
      return
    }
    
    if (file.status === 'uploading' || file.status === 'done') {
      const updatedFileList = newFileList.map(f => {
        if (f.status === 'done' && f.response) {
          return {
            ...f,
            url: f.response.fileUrl 
          }
        }
        return f
      })
      setFileList(updatedFileList)
    }
  }

  const initialName = company.alias || company.name
  const method = company.settings?.accountingMethod
  const currency = company.settings?.currency

  return (
    <Flex vertical>
      <Divider orientation="left">{company.name}</Divider>
      <Form layout="vertical" onValuesChange={onChange}>
        <Row gutter={24}>
          <Col span={12}>
            <Flex vertical gap={10}>
              <Form.Item label="Business name" name="alias" initialValue={initialName}>
                <Input placeholder="Business name" disabled={!isAllowed} />
              </Form.Item>
            </Flex>
          </Col>
          <Col span={12}>
            <Flex vertical gap={10}>
              <Form.Item label="Legal entity type" name="entity" initialValue={company.entity}>
                <SelectEntity placeholder="Select" disabled={!isAllowed} />
              </Form.Item>
            </Flex>
          </Col>
        </Row>
        <Divider orientation="left">Settings</Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Accounting method" name={['settings', 'accountingMethod']} initialValue={method}>
              <SelectMethod placeholder="Select" disabled={!isAllowed} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={['settings', 'currency']} label="Default Currency" initialValue={currency}>
              <Input value={currency} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Company Logo">
              <Upload
                listType="picture-card"
                fileList={fileList}
                beforeUpload={beforeUpload}
                customRequest={handleUpload}
                onChange={handleChange}
                maxCount={1}
                disabled={uploading || !isAllowed}
                showUploadList={{
                  showPreviewIcon: true,
                  showRemoveIcon: true 
                }}
              >
                {fileList.length === 0 && (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload Logo</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        {isAllowed && (
          <>
            <Divider orientation="left">Administration</Divider>
            <Popconfirm
              title="Are you sure you want to delete this company? This action cannot be undone."
              onConfirm={onDelete}
            >
              <Button danger>Delete company</Button>
            </Popconfirm>
          </>
        )}
      </Form>
    </Flex>
  )
}