import { useState, useEffect } from 'react'
import { Modal, Form, Input, Select, Button, message, Spin, Typography, Divider } from 'antd'
import { api } from 'utils/axios'
import { useTeams } from 'hooks/useTeam'
import { useCompanies } from 'hooks/useCompanies'
import { useUser } from 'hooks/useUser'
import { RoleType } from 'types/user.types'
import { getRoleText } from 'utils/team'

import { IUser } from 'types/user.types'

interface TeamMembershipModalProps {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
  selectedUser?: IUser | null
}

const { Text } = Typography

const TeamMembershipModal = ({ open, onClose, onSuccess, selectedUser }: TeamMembershipModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [fetchingMembership, setFetchingMembership] = useState(false)
  const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null)
  // Fix 1: Prefix with underscore to indicate it's used indirectly
  const [, setSelectedCompanies] = useState<number[]>([])
  const [isAdmin, setIsAdmin] = useState(false)

  const { data: teams, isLoading: teamsLoading } = useTeams()
  // Fix 2: Prefix with underscore or remove if not needed at all
  const { isLoading: companiesLoading } = useCompanies()
  const { user: currentUser } = useUser()

  // State to store the selected user's team membership data
  const [userTeamMemberships, setUserTeamMemberships] = useState<any[]>([])

  // Fix 3: Since this state variable is set but never read, prefix with underscore
  const [, setTeamCompaniesMap] = useState<Record<number, any[]>>({})

  // Track the userType
  const [userType, setUserType] = useState<string>(selectedUser ? 'manage' : 'self')

  // Available companies for the selected team
  const [availableCompanies, setAvailableCompanies] = useState<any[]>([])

  // Fetch user's team membership when selectedUser changes
  useEffect(() => {
    const fetchUserTeamMembership = async () => {
      if (selectedUser && open) {
        try {
          setFetchingMembership(true)
          const response = await api.get(`admin/team-memberships/${selectedUser.id}`)
          const membershipData = response.data

          if (membershipData) {
            // Ensure membershipData is always an array
            const memberships = Array.isArray(membershipData) ? membershipData : [membershipData]
            setUserTeamMemberships(memberships)

            // Create a map of teamId to companies
            const teamsMap: Record<number, any[]> = {}
            memberships.forEach(team => {
              teamsMap[team.teamId] = team.companies || []
            })
            setTeamCompaniesMap(teamsMap)

            // Set initial values for the form
            if (form.getFieldValue('userType') === 'manage' && memberships.length > 0) {
              const firstTeam = memberships[0]
              form.setFieldsValue({
                teamId: firstTeam.teamId,
                role: firstTeam.role,
                companyIds: firstTeam.companies.map((company: { id: number }) => company.id)
              })

              // Update state
              setSelectedTeamId(firstTeam.teamId)
              setSelectedCompanies(firstTeam.companies.map((company: { id: number }) => company.id))
              setIsAdmin(firstTeam.role === RoleType.Admin)
              setAvailableCompanies(firstTeam.companies)
            } else if (['self', 'other'].includes(form.getFieldValue('userType')) && memberships.length > 0) {
              // When adding someone to a team, just pre-select the first team
              const firstTeam = memberships[0]
              form.setFieldsValue({
                teamId: firstTeam.teamId
              })
              setSelectedTeamId(firstTeam.teamId)
              setAvailableCompanies(firstTeam.companies)
            }
          }
        } catch (error) {
          console.error('Error fetching user team membership:', error)
          message.error('Failed to fetch user team membership')
        } finally {
          setFetchingMembership(false)
        }
      }
    }

    fetchUserTeamMembership()
  }, [selectedUser, open, form])

  // Reset form when modal is opened/closed or selectedUser changes
  useEffect(() => {
    if (open) {
      // Set initial values based on selectedUser
      const initialValues: {
        userType: string;
        role: RoleType;
        email?: string;
      } = {
        userType: selectedUser ? 'manage' : 'self',
        role: RoleType.Member,
      }

      // If a user is selected, pre-fill the email
      if (selectedUser) {
        initialValues.email = selectedUser.email
      }

      // For 'self' type, use the current user's email from the hook
      if (!selectedUser && initialValues.userType === 'self' && currentUser) {
        initialValues.email = currentUser.email
      }

      form.setFieldsValue(initialValues)
      setSelectedTeamId(null)
      setSelectedCompanies([])
      setIsAdmin(false)
      setUserType(selectedUser ? 'manage' : 'self')
    }
  }, [open, form, selectedUser, currentUser])

  // Handle userType change
  const handleUserTypeChange = (value: string) => {
    setUserType(value)
  }

  // When userType changes, update the form and email field
  useEffect(() => {
    if (open && form) {
      if (userType === 'manage' && selectedUser && userTeamMemberships.length > 0) {
        // Use the first team when managing
        const firstTeam = userTeamMemberships[0]
        form.setFieldsValue({
          teamId: firstTeam.teamId,
          role: firstTeam.role,
          companyIds: firstTeam.companies?.map((company: { id: number }) => company.id) || []
        })

        setSelectedTeamId(firstTeam.teamId)
        setSelectedCompanies(firstTeam.companies?.map((company: { id: number }) => company.id) || [])
        setIsAdmin(firstTeam.role === RoleType.Admin)
        setAvailableCompanies(firstTeam.companies || [])
      } else if (userType === 'self' && currentUser) {
        // For self, pre-populate with current user's email
        form.setFieldsValue({
          email: currentUser.email
        })
      } else if (['self', 'other'].includes(userType) && selectedUser && userTeamMemberships.length > 0) {
        // For self/other, just select the team, reset other fields
        form.setFieldsValue({
          teamId: null,
          role: RoleType.Member,
          companyIds: []
        })

        setSelectedTeamId(null)
        setSelectedCompanies([])
        setIsAdmin(false)
        setAvailableCompanies([])
      }
    }
  }, [userType, selectedUser, userTeamMemberships, open, form, currentUser])

  // When team is selected, update available companies
  const handleTeamChange = (value: number) => {
    setSelectedTeamId(value)

    // Update available companies based on the selected team
    if (userTeamMemberships.length > 0) {
      // Find the selected team in userTeamMemberships
      const selectedTeamMembership = userTeamMemberships.find(team => team.teamId === value)

      if (selectedTeamMembership) {
        setAvailableCompanies(selectedTeamMembership.companies || [])

        // Reset company selection when team changes
        form.setFieldsValue({ companyIds: [] })
        setSelectedCompanies([])

        // If admin role is selected, select all companies
        if (isAdmin && selectedTeamMembership.companies) {
          const allCompanyIds = selectedTeamMembership.companies.map((company: { id: number }) => company.id)
          setSelectedCompanies(allCompanyIds)
          form.setFieldsValue({ companyIds: allCompanyIds })
        }
      }
    }
  }

  // When role changes, update admin status
  const handleRoleChange = (value: RoleType) => {
    const isAdminRole = value === RoleType.Admin
    setIsAdmin(isAdminRole)

    // If admin, select all companies for the current team
    if (isAdminRole && selectedTeamId && availableCompanies.length > 0) {
      const allCompanyIds = availableCompanies.map(company => company.id)
      setSelectedCompanies(allCompanyIds)
      form.setFieldsValue({ companyIds: allCompanyIds })
    }
  }

  const handleCompanyChange = (values: number[]) => {
    setSelectedCompanies(values)
  }

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      setLoading(true)

      // Get the action type
      const actionType = values.userType // 'manage', 'self', or 'other'

      // Prepare the payload based on the action type
      if (actionType === 'manage' && selectedUser) {
        // Update the selected user's team membership
        await api.put(`admin/team-memberships/${selectedUser.id}`, {
          userId: selectedUser.id,
          teamId: values.teamId,
          role: values.role,
          companyIds: values.companyIds
        })
        message.success('Team membership updated successfully')
      } else if (actionType === 'self') {
        // Make sure we have an email for the current user
        if (!values.email) {
          throw new Error('Email is required for adding yourself to a team')
        }

        // Add current user to the team
        await api.post('admin/team-memberships', {
          email: values.email,
          teamId: values.teamId,
          role: values.role,
          companyIds: values.companyIds
        })
        message.success('You have been added to the team successfully')
      } else if (actionType === 'other') {
        // Add another user to the team
        if (!values.email) {
          throw new Error('Email is required when adding another user')
        }

        await api.post('admin/team-memberships', {
          email: values.email,
          teamId: values.teamId,
          role: values.role,
          companyIds: values.companyIds
        })
        message.success('User has been added to the team successfully')
      }

      // Close modal and trigger success callback
      onClose()
      if (onSuccess) {
        onSuccess()
      }
    } catch (error: any) {
      console.error('Error managing team membership:', error)
      let errorMessage = 'Failed to manage team membership'

      if (error.response?.data?.message) {
        errorMessage = error.response.data.message
      } else if (error.message) {
        errorMessage = error.message
      }

      message.error(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const roleOptions = [
    { label: getRoleText(RoleType.Admin), value: RoleType.Admin },
    { label: getRoleText(RoleType.Member), value: RoleType.Member },
    { label: getRoleText(RoleType.ViewOnly), value: RoleType.ViewOnly }
  ]

  // Generate team options from userTeamMemberships when self/other is selected
  const getTeamOptions = () => {
    if (['self', 'other'].includes(userType) && selectedUser && userTeamMemberships.length > 0) {
      return userTeamMemberships.map(team => ({
        label: team.teamName,
        value: team.teamId
      }))
    }

    // Otherwise, use all teams
    return teams?.map(team => ({
      label: team.name,
      value: team.id
    })) || []
  }

  return (
      <Modal
          title={selectedUser ? `Manage Team for ${selectedUser.firstName} ${selectedUser.lastName}` : "Add User to Team"}
          open={open}
          onCancel={onClose}
          footer={[
            <Button key="cancel" onClick={onClose}>
              Cancel
            </Button>,
            <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleSubmit}
            >
              {selectedUser ? 'Update Team Membership' : 'Add to Team'}
            </Button>
          ]}
          width={600}
      >
        {(teamsLoading || companiesLoading || fetchingMembership) ? (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <Spin size="large" />
              <div style={{ marginTop: '10px' }}>Loading...</div>
            </div>
        ) : (
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                  userType: selectedUser ? 'manage' : 'self',
                  role: RoleType.Member
                }}
            >
              {/* Display current team info if a user is selected */}
              {selectedUser && userTeamMemberships.length > 0 && (
                  <div style={{ marginBottom: 24, background: '#f5f5f5', padding: 16, borderRadius: 4 }}>
                    <Typography.Title level={5}>Current Team Information</Typography.Title>
                    {userTeamMemberships.map((team, index) => (
                        <div key={team.teamId} style={index > 0 ? { marginTop: 16, paddingTop: 16, borderTop: '1px solid #e8e8e8' } : {}}>
                          <p><strong>Team:</strong> {team.teamName || 'None'}</p>
                          <p><strong>Role:</strong> {getRoleText(team.role) || 'None'}</p>
                          <p><strong>Companies:</strong> {team.companies?.map((c: any) => c.name).join(', ') || 'None'}</p>
                        </div>
                    ))}
                  </div>
              )}

              <Form.Item
                  name="userType"
                  label="Action"
                  rules={[{ required: true, message: 'Please select an action' }]}
              >
                <Select
                    options={selectedUser ? [
                      { label: 'Manage this user\'s team membership', value: 'manage' },
                      { label: 'Add myself to this user\'s team', value: 'self' },
                      { label: 'Add another user to this user\'s team', value: 'other' }
                    ] : [
                      { label: 'Add myself to a team', value: 'self' },
                      { label: 'Add another user', value: 'other' }
                    ]}
                    onChange={handleUserTypeChange}
                />
              </Form.Item>

              <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.userType !== currentValues.userType}
              >
                {({ getFieldValue }) => {
                  const userType = getFieldValue('userType');
                  return (userType === 'other' || userType === 'self') ? (
                      <Form.Item
                          name="email"
                          label="Email"
                          rules={[
                            { required: true, message: 'Please enter email' },
                            { type: 'email', message: 'Please enter a valid email' }
                          ]}
                      >
                        <Input
                            placeholder="user@example.com"
                            disabled={userType === 'self' }
                        />
                      </Form.Item>
                  ) : null
                }}
              </Form.Item>

              <Form.Item
                  name="teamId"
                  label="Team"
                  rules={[{ required: true, message: 'Please select a team' }]}
              >
                <Select
                    placeholder="Select a team"
                    onChange={handleTeamChange}
                    options={getTeamOptions()}
                />
              </Form.Item>

              <Form.Item
                  name="role"
                  label="Role"
                  rules={[{ required: true, message: 'Please select a role' }]}
              >
                <Select
                    placeholder="Select a role"
                    options={roleOptions}
                    onChange={handleRoleChange}
                />
              </Form.Item>

              <Divider orientation="left">Companies</Divider>

              <Form.Item
                  name="companyIds"
                  label="Select Companies"
                  rules={[{ required: true, message: 'Please select at least one company' }]}
                  extra={isAdmin ? "Admin users automatically have access to all companies" : ""}
              >
                <Select
                    mode="multiple"
                    placeholder="Select companies"
                    onChange={handleCompanyChange}
                    options={availableCompanies.map(company => ({
                      label: company.name,
                      value: company.id
                    }))}
                    disabled={isAdmin}
                    style={{ width: '100%' }}
                />
              </Form.Item>

              {isAdmin && (
                  <Text type="secondary">
                    Note: Admin users automatically have access to all companies in the team.
                  </Text>
              )}
            </Form>
        )}
      </Modal>
  )
}

export default TeamMembershipModal