import { Button, notification, Typography } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { Progress } from './components/Progress'
import { useUser } from 'hooks/useUser'

const { Title, Text } = Typography

export function ScheduleMeeting() {
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()
  const { user } = useUser()

  const handleComplete = () => {
    navigate(`/company/${params.id}/services/service-completion`)
  }

  const handleScheduleMeeting = () => {
    if (!user) {
      notification.error({
        message: 'Error',
        description: 'Unable to access user information. Please try again.',
      })
      return
    }
    const fullName = `${user.firstName} ${user.lastName}`.trim()
    const email = user.email || ''

    const calendlyUrl = new URL('https://calendly.com/michaelnieto')
    calendlyUrl.searchParams.append('name', fullName)
    calendlyUrl.searchParams.append('email', email)

    window.open(calendlyUrl.toString(), '_blank')
  }

  return (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', padding: '32px', marginBottom: '32px' }}>
      <Title level={2}>Services</Title>

      <div style={{ marginBottom: '64px' }}>
        <Progress currentStep={4} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '32px',
          maxWidth: '800px',
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <Text style={{ fontSize: '18px' }}>
          Please schedule a meeting time, so we can go over the details of your project and get started on the work:
        </Text>

        <Button
          type="primary"
          size="large"
          onClick={handleScheduleMeeting}
          style={{
            width: '100%',
            maxWidth: '300px',
            height: '50px',
            fontSize: '18px',
            backgroundColor: '#2E77D0',
            borderColor: '#2E77D0',
            borderRadius: '8px',
          }}
        >
          Schedule Meeting
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px', gap: '16px' }}>
        <Button type="primary" size="large" onClick={handleComplete} style={{ padding: '0 32px', borderRadius: '8px' }}>
          Next
        </Button>
      </div>
    </div>
  )
}
