import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { RoleType } from 'types/user.types'
import { api } from 'utils/axios'
import { ITeam } from 'types/team.types'

export function useTeam() {
  const { data, error, isLoading, isValidating, mutate } = useSWR('team')
  const refetchTeam = () => mutate(data)

  const { isMutating: deleteLoading, trigger: deleteMember } = useSWRMutation('team/user', deleteFetcher, {
    onSuccess: refetchTeam,
  })

  const { isMutating: updateRoleLoading, trigger: updateRole } = useSWRMutation('team/user-role', updateFetcher, {
    onSuccess: refetchTeam,
  })

  const { isMutating: updateCompanyAccessLoading, trigger: updateCompanyAccess } = useSWRMutation(
      'team/user-company',
      updateUserCompanyFetcher,
      { onSuccess: refetchTeam },
  )

  return {
    data,
    error,
    isLoading: isLoading || isValidating,
    refetchTeam,
    deleteLoading,
    deleteMember,
    updateRole,
    updateRoleLoading,
    updateCompanyAccessLoading,
    updateCompanyAccess,
  }
}

export function useTeams() {
  const { data, error, isLoading, isValidating, mutate } = useSWR<ITeam[]>('team')

  return {
    data,
    error,
    isLoading: isLoading || isValidating,
    refetch: () => mutate(data)
  }
}

export function useTeamInvites(teamId?: number) {
  const endpoint = teamId ? `team/${teamId}/invites` : 'team/invites'
  const { data, error, isLoading, isValidating, mutate } = useSWR(endpoint, {})
  const refetch = () => mutate(data)

  return { data, error, refetch, isLoading: isLoading || isValidating }
}

export function useTeamInvite() {
  const { data, isMutating: inviteLoading, trigger: invite } = useSWRMutation('team-invite', inviteFetcher)
  return { data, inviteLoading, invite }
}

export function useTeamResendInvite() {
  const { data, isMutating: resendInviteLoading, trigger: resendInvite } = useSWRMutation('team-resend-invite', resendInviteFetcher)
  return { data, resendInviteLoading, resendInvite }
}

export function useTeamRescindInvite() {
  const {
    data,
    isMutating: rescindInviteLoading,
    trigger: rescindInvite,
  } = useSWRMutation('team-rescind-invite', rescindInviteFetcher)

  return { data, rescindInviteLoading, rescindInvite }
}

type InviteFetcherPayload = {
  email: string
  teamId: number
  role: RoleType
  companies?: { id: number | undefined; name: string | undefined }[]
}

type ResendInvitePayload = {
  inviteId: number
  teamId: number
}

type RescindInvitePayload = {
  inviteId: number
  teamId: number
}

type DeleteFetcherPayload = {
  id: number
}

type UpdateFetcherPayload = {
  user: number
  role: RoleType
}

type UpdateUserCompanyFetcherPayload = {
  user: number
  company: number
  access: boolean
}

const inviteFetcher = (url: string, { arg }: { arg: InviteFetcherPayload }) => {
  const endpoint = `team/invite`;

  // Send the complete payload including teamId
  return api.post(endpoint, arg).then((res) => res.data);
}

const resendInviteFetcher = (url: string, { arg }: { arg: ResendInvitePayload }) => {
  const endpoint = `team/${arg.teamId}/invite/${arg.inviteId}/resend`;
  return api.post(endpoint).then((res) => res.data);
}

const rescindInviteFetcher = (url: string, { arg }: { arg: RescindInvitePayload }) => {
  const endpoint = `team/${arg.teamId}/invite/${arg.inviteId}`;
  return api.delete(endpoint).then((res) => res.data);
}

const deleteFetcher = (url: string, { arg }: { arg: DeleteFetcherPayload }) => {
  return api.delete(`${url}/${arg.id}`).then((res) => res.data)
}

const updateFetcher = (url: string, { arg }: { arg: UpdateFetcherPayload }) => {
  return api.patch(url, arg).then((res) => res.data)
}

const updateUserCompanyFetcher = (url: string, { arg }: { arg: UpdateUserCompanyFetcherPayload }) => {
  return api.patch(url, arg).then((res) => res.data)
}