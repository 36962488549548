import { Flex, Table, Typography } from 'antd'

import { ColorNumber } from 'components/ColorNumber'
import { useCompany } from 'hooks/useCompany'
import { toCurrency } from 'utils/numbers'
import { useTransactions } from 'hooks/useTransactions'
import { useCompanyStore } from 'store/company.store'
import { prevMonthStart } from 'utils/dates'
import type { IReportRow } from 'types/report.type'

const { Text } = Typography

interface Props {
  accounts: string[]
  from: string
  to: string
  method?: string
  uncleared?: boolean
  currency?: string
}

export function TxList(props: Props) {
  const { company } = useCompany()
  const { accounts, from, to, method, uncleared, currency =  company?.settings?.currency || '' } = props

  const companyId = useCompanyStore((state) => state.id) || 0
  const accountId = accounts.join(',')

  const _from = uncleared ? prevMonthStart(from) : from
  const { data, isLoading } = useTransactions({ companyId, accountId, from: _from, to, method })

  const filteredData = filterTree(data?.report || [], uncleared)

  const total = filteredData.reduce((acc, item) => {
    const amount = item.type === 'Section' ? item.header?.value || item.summary?.value : item['subt_nat_amount']
    return acc + parseFloat(amount || '0')
  }, 0)

  return (
    <Table
      size="small"
      virtual
      loading={isLoading}
      columns={columns}
      dataSource={filteredData}
      pagination={false}
      rowKey={(r) => r.key}
      expandable={{
        childrenColumnName: 'rows',
      }}
      scroll={{ y: 400 }}
      style={{ minHeight: 200 }}


















      footer={() => (
        <Flex align="center" justify="flex-end">
          <Text strong>Total: {toCurrency(total, currency)}</Text>
        </Flex>
      )}
    />
  )
}

const columns = [
  {
    title: 'Date',
    dataIndex: 'tx_date',
    width: 200,
  },
  {
    title: 'Description',
    dataIndex: 'memo',
    ellipsis: true,
    render: (text: string, record: any) => {
      if (record.type === 'Section') {
        return <strong>{record.header?.name || record.summary?.name || ''}</strong>
      } else {
        return text
      }
    },
  },
  {
    title: 'Amount',
    dataIndex: 'subt_nat_amount',
    width: 150,
    align: 'right' as any,
    render: (v: string, record: any) => {
      if (record.type === 'Section') {
        const total = record.header?.value || record.summary?.value || '0'
        return <ColorNumber amount={parseFloat(total)} />
      }

      const _v = v || record.subt_nat_home_amount || '0'
      const amount = _v === '.00' ? '0' : _v
      return <ColorNumber amount={parseFloat(amount)} />
    },
  },
  {
    title: 'Balance',
    dataIndex: 'rbal_nat_amount',
    width: 150,
    align: 'right' as any,
    render: (v: string, record: any) => {
      if (record.type === 'Section') {
        const total = record.header?.value || record.summary?.value || '0'
        return <ColorNumber amount={parseFloat(total)} />
      }

      const _v = v || record.rbal_nat_home_amount || record.rbal_nat_amount || record.rbal_nat_home_amount || '0'
      const amount = parseFloat(_v) === 0 ? '0' : _v;
      return <ColorNumber amount={amount} />
    },
  }
]

function filterTree(data: IReportRow[], uncleared = false): IReportRow[] {
  if (!data) return data
  const r: IReportRow[] = []

  data.forEach((item) => {
    if (item.type === 'Section') {
      const { rows, ...rest } = item
      // if (!rows?.length) return
      r.push({ ...rest, rows: filterTree(rows, uncleared) })
    } else {
      if (uncleared && item.is_cleared === 'R') return
      if (item.tx_date === 'Beginning Balance') return
      r.push(item)
    }
  })

  return r
}
