import { ICompany } from './company.types'

export interface IUser {
  id: number
  email: string
  firstName: string
  lastName: string
  photoUrl: string
  stripe_customer_id?: string
  subscribed_before?: boolean
  createdOn: string
  lastAccess: string
  firebaseUid: string
  accessLevel: number
  member?: IUserMember
  admin?: boolean
  googleSignIn?: boolean
  source?: string
  phoneNumber: string

  emailVerified?: boolean
  disabled?: boolean

  charge?: number
  isOwner?: boolean
}

export interface IUserMember {
  id: number
  role: RoleType
  team: IUserMemberTeam
}

export interface IUserMemberTeam {
  id: number
  name: string
  stripeSubscriptionId: null | number
  createdBy: number
  lastAccess?: string
  companies?: number
}

export interface IRole {
  id: RoleType
  name: string
  description: string
}

export enum RoleType {
  Owner = -1,
  Admin = 0,
  Member = 1000,
  ViewOnly = 2000,
}

export interface ITeamMember extends IUser {
  role: IRole
  assignedBy: IUser
  assignedOn: string
}

export interface IAcceptedMember {
  id: number
  role: RoleType
  user: IUser & { companies: ICompany[] }
}

export interface IInvite {
  id: string
  application: any
  acceptedOn: string | null
  invitedOn: string
  email: string
  role: number
}

export interface IPlaidLinkToken {
  linkToken: string
  expiration: Date
}

export interface PlaidLinkTokenUpdateParams {
  companyId: number;
  bankFeedId: string;
}