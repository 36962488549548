import { toSafeDecimal } from './numbers'

import { CsvBoxTx, ITx } from 'types/tx.types'
import { IReport } from 'types/report.type'
import { formatDate } from './dates'

export function toTransaction() {}

export function csvBoxToTxs(data: CsvBoxTx[], invertAmount?: boolean) {
  return data.map((v, _) => {
    const id = uid()
    const amount = toRegularNumber(v.Amount)
    const expense = toRegularNumber(v.Expense)
    const received = toRegularNumber(v.Received)
    const spent = v.Spent ? 0 - toRegularNumber(v.Spent) : 0
    const payment = v.Payment ? 0 - toRegularNumber(v.Payment) : 0

    const total = amount || expense || received || spent || payment

    return {
      _id: id,
      id: id,
      date: formatDate(v.Date, 'YYYY-MM-DD'),
      amount: toSafeDecimal(invertAmount ? 0 - total : total),
      description: v.Description,
      memo: v.Description,
      name: '',
      matchedTo: [],
    }
  })
}

function toRegularNumber(value?: string) {
  if (!value) return 0

  const v = value.replaceAll(/,/g, '') // remove commas

  if (v.includes('(')) {
    return 0 - parseFloat(v.replace(/\(|\)/g, ''))
  }
  if (v.includes('-')) {
    return parseFloat(v)
  }
  return parseFloat(v)
}

export function csvToTxs(data: string[][], bankFormat: any, invertAmount?: boolean): {
  date: string;
  amount: number;
  description: string;
  memo: string;
  _id: string;
  id: number;
  matchedTo: any[]
}[] {
  const [header, ...list] = data

  const dateIndex = header.indexOf(bankFormat.date)
  const amountIndex = header.indexOf(bankFormat.amount)
  const descriptionIndex = header.indexOf(bankFormat.description)

  return list.map((v, i) => {
    const amount = parseFloat(v[amountIndex])
    return {
      _id: i + v[dateIndex] + v[amountIndex],
      id: i,
      date: formatDate(v[dateIndex], 'YYYY-MM-DD'),
      amount: invertAmount ? 0 - amount : amount,
      description: v[descriptionIndex],
      memo: v[descriptionIndex],
      matchedTo: [],
    }
  })
}

// Updated to handle both old Plaid and new bank transaction format
export function pladToTxs(data: any, invertAmount?: boolean): ITx[] {
  if (!Array.isArray(data)) {
    console.warn('pladToTxs received non-array data:', data)
    return []
  }

  return data.map((v: any) => {
    // Handle new bank transaction format
    if (v.transactionId) {
      return {
        _id: v.transactionId,
        id: v.id,
        date: formatDate(v.date, 'YYYY-MM-DD'),
        amount: invertAmount ? 0 - v.amount : v.amount,
        description: v.originalDesc || v.name || '',
        memo: v.originalDesc || v.name || '',
        matchedTo: [],
        name: v.name,
        customer: v.category?.includes('Deposit') ? v.name : undefined,
        vendor: !v.category?.includes('Deposit') ? v.name : undefined,
      }
    }

    // Handle old Plaid format
    return {
      _id: v.transaction_id,
      id: v.transaction_id,
      date: formatDate(v.date, 'YYYY-MM-DD'),
      amount: invertAmount ? 0 - v.amount : v.amount,
      name: v.name,
      description: v.original_description || v.payment_meta?.reason || v.name || '',
      memo: v.original_description || v.payment_meta?.reason || v.name || '',
      matchedTo: [],
    }
  })
}

/**
 * Convert report data to transactions
 *
 * @param data - The report data (either IReport object or raw JSON)
 * @param accId - The account ID to filter transactions
 * @param unreconciled - Optional flag to filter for unreconciled transactions only
 * @returns An array of ITx objects
 */
export function reportToTsx(data: IReport | any, accId: string, unreconciled?: boolean): ITx[] {
  const exclude = ['Beginning Balance', 'Ending Balance', 'Total', '0-00-00'];

  // Handle empty data
  if (!data) return [];

  // Find the account in the report
  const findAccount = (report: any[]): any => {
    for (const item of report) {
      if (item.id === accId) return item;

      // Check in nested sections if present
      if (item.rows && Array.isArray(item.rows)) {
        const nestedAccount = findAccount(item.rows.filter((row: { type: string }) => row.type === 'Section'));
        if (nestedAccount) return nestedAccount;
      }
    }
    return null;
  };

  // Recursively flatten nested rows to extract all Data rows
  const flattenRows = (rows: any[]): any[] => {
    let result: any[] = [];

    for (const row of rows) {
      if (row.type === 'Data') {
        result.push(row);
      } else if (row.rows && Array.isArray(row.rows)) {
        result = result.concat(flattenRows(row.rows));
      }
    }

    return result;
  };

  try {
    // Handle data in either format
    const report = data.report || [];
    if (!Array.isArray(report) || report.length === 0) return [];

    // Find the target account
    const account = findAccount(report);
    if (!account || !account.rows) return [];

    // Get all Data rows
    const rawRows = flattenRows(account.rows);
    if (!rawRows.length) return [];

    // Apply unreconciled filter if needed
    const filteredRows = unreconciled ? rawRows.filter(v => v.is_cleared !== 'R') : rawRows;

    // Transform into ITx objects
    return filteredRows
        .filter(v => !!v.tx_date && !exclude.includes(v.tx_date))
        .map(v => {
          const amount = parseFloat(v.subt_nat_amount || v.subt_nat_home_amount || v.amount || v.subt_nat_amount_nt || v.subt_nat_amount_home_nt || '0');
          return {
            _id: uid(),
            id: v.id,
            matchedTo: [],
            date: formatDate(v.tx_date, 'YYYY-MM-DD'),
            memo: v.memo || '',
            amount: amount,
            is_cleared: v.is_cleared,
            type: v.txn_type,
            num: v.doc_num,
            account: v.split_acc,
            name: v.cust_name || v.vend_name || v.name || '',
            description: v.memo || '', // Adding description since it's in the interface
            temp: v.tx_date,
          };
        });
  } catch (error) {
    console.error('Error in reportToTsx:', error);
    return [];
  }
}

export function qboToTxs(data: []): ITx[] {
  return data
    .filter((v: any) => v.Amount)
    .map((v: any) => {
      return {
        _id: uid(),
        matchedTo: [],
        description: v.memo,
        ...v,
      }
    })
}

export function uid() {
  let a = new Uint32Array(3)
  window.crypto.getRandomValues(a)
  return (
    performance.now().toString(36) +
    Array.from(a)
      .map((A) => A.toString(36))
      .join('')
  ).replace(/\./g, '')
}
